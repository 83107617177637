import React, { useContext, useEffect, useState } from 'react'
import GuruMasterSideBar from '../../pages/menu/GuruMasterSideBar'
import { Container } from 'react-bootstrap'
import AnimateHeight from 'react-animate-height'
import Utils from '../../Global/Utils';
import '../../Styles/DarkTheme.css'
import GuruMenu from '../../Global/GuruMainMenu/GuruMenu';
import { GlobalContext } from '../../Global/GlobalContext';
import TestDelete from './TestDelete';


function GuruMasterHome() {
  const [height, setHeight] = useState(0);//this is for the units form
  const { globalState, setGlobalState } = useContext(GlobalContext);

  const changeName=()=>{
    setGlobalState({ value: 'Emmanuel' })
  }

  useEffect(()=>{
    Utils.PageLoadeDefaults()
    document.body.classList.add('darkBody')
  })
  return (
    <>
      <GuruMasterSideBar>
        <GuruMenu/>
        <p>My name is: {globalState.value}</p>
        <h1>Running Apps</h1>
        <a href="#" onClick={()=>changeName()}>Change the name </a>
        <TestDelete/>
      </GuruMasterSideBar>
      <Container>
        <AnimateHeight id="tuplesAnimForm" duration={300} animateOpacity={true} height={height}>
          <h1>Running Apps</h1>
          {/* <ContainerRowBtwn clearBtn={clearBtn} form='tuple' showLoader={showLoader}  >
            <ClearBtnSaveStatus height={height} showLoader={showLoader} showAlert={showAlert} />
            <div className={`box col-lg-12 col-md-12 ms-3 formBox mt-2`}  >
              <div className='form-row'>
                <form onSubmit={SubmitTosaveTuple} id="Form">
                  <InputRowTupleName ref={inputRef} name='Tuple Name' val={name} handle={(e) => setCaptionAndName(e.target.value)} label='tuple' />


                </form>
              </div>
            </div>
          </ContainerRowBtwn> */}
        </AnimateHeight>
      </Container>
    </>

  )
}

export default GuruMasterHome