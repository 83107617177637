import React, { useEffect } from 'react'

import Banner from './home/Banner'
import './Styles/Home/HomeStyles.css'
import Solutions from './home/Solutions'
import Highlights from './pages/HIlights/Highlights'
import CarouselItem from './home/CarouselItem'
import { Col, Container } from 'react-bootstrap'
import lookingTheo from './imgz/IMG_9768.JPG'
import Header from './home/Header'
import BizInterests from './home/BizInterests'
import DevInterests from './home/DevInterests'
import Footer from './Global/footer/Footer'
import './home/HomeStyles.css'
// import corporate from './imgz/home/corporate_lady-removebg.png'
import corporate from './imgz/home/ladylaughingshort_two.png'
import BizReports from './home/BizReports'
import Whatsapp from './pages/WhatsappLink/Whatsapp'
import { Helmet } from 'react-helmet'
import HappyCustomers from './pages/HappyCustomers/HappyCustomers'
import TimeComponent from './TimoComponent/TimeComponent'
import Utils from './Global/Utils'
import SystemInADay from './home/SystemInADay'
import Test from './Test/Test'

function Home() {

    useEffect(() => {
        document.body.classList.remove('darkBody')
        document.body.classList.remove('servicesBg')
        // window.scrollTo(0, 0)
        document.body.style.backgroundColor = Utils.skinBg1();
    })

    return (
        <>
            <Helmet>
                <title>Home - CODEGURU Systems</title>
                <meta name="description" content="Software Development Company" />
                <meta name="keywords" content="Software Development, Programming, Coding, Development Tools, 
                    Software Engineering, Application Development, Web Development,
                    Mobile Development, Software Solutions, Custom Software, 
                    Agile Development, Software Design, Development Process, 
                    Full Stack Development, Frontend Development, Backend Development,
                    DevOps, Software Testing, Technology Solutions, Software Projects,
                    Digital Transformation, Business Growth, Technology Solutions, Innovation,
                    Process Optimization, Customer Experience, Efficiency, Scalability, Automation,
                    Data Analytics, Digital Strategy, Cloud Services, Cybersecurity,
                    IT Consulting, Custom Solutions, Competitive Advantage, Market Expansion, 
                    Digital Marketing, Business Intelligence, Cost Reduction,"></meta>

            </Helmet>
            <Whatsapp />

            <Header />
            <BizInterests image={corporate} />


            <BizReports />
            <DevInterests />

            

            <Solutions />


            <HappyCustomers/>
            <SystemInADay/>

            <Footer />


{/* <Highlights /> */}
            {/* <Footer /> */}

        </>
    )
}

export default Home