import React from 'react'

import './Whatsapp.css'
import { whatsapp } from 'react-icons-kit/fa/whatsapp'
import Icon from 'react-icons-kit'
import { color } from 'framer-motion'
import { Slide } from 'react-reveal'


function Whatsapp() {

    const waStyles = {
        color: '#07e65c', fontWeight: 'bolder', margin : 'auto'
    }

    return (
        <Slide right>
            <div className='whatsappIcon'>
                <a href='https://wa.me/+250784113888' target="_blank"   title='Talk to us to get started' >
                    <Icon size={'80%'} style={waStyles} icon={whatsapp} />
                </a>
            </div>
        </Slide>
    )
}

export default Whatsapp