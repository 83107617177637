import React, { useState } from 'react'
import { Modal, Row } from 'react-bootstrap'

function FormDetails(props) {
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
      }
    return (
    <>
     <Modal show={props.switch} scrollable={true} fullscreen={fullscreen} onHide={ props.closeHandle}>
        <div className='modalStyles' style={{ orverflowY: 'scroll',   height: '100%' }}>
          <Modal.Header closeButton closeVariant="white" style={{ zIndex: '100' }}>
            <Modal.Title className='fw-bold heading  formDetailTitle '> {props.title} </Modal.Title>
          </Modal.Header>
          <Modal.Body  >
              <Row className='modallargeContent styledVHScrollBar p-2'>
                {props.children}
              </Row>
          </Modal.Body>
        </div>
      </Modal>
      
      </>
  )
}

export default FormDetails