import React, { useEffect, useState } from 'react'

import { ic_contact_page_outline as contact } from 'react-icons-kit/md/ic_contact_page_outline'
import { Icon } from 'react-icons-kit'
import './contactus.css'
import { ic_perm_phone_msg as phone } from 'react-icons-kit/md/ic_perm_phone_msg'
import { ic_email_outline as email } from 'react-icons-kit/md/ic_email_outline'
import Commons from '../../Services/Commons'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Form, Container } from 'react-bootstrap'
import { ErrorOutline, SetMeal, WindowSharp } from '@mui/icons-material'
import { Helmet } from 'react-helmet'
import Whatsapp from '../pages/WhatsappLink/Whatsapp'



function ContactusForm() {
    const [id, setId] = useState(null)
    const [date, setDate] = useState(null)
    const [sender_email, setSender_email] = useState()
    const [subject, setSubject] = useState()
    const [description, setdescription] = useState()
    const [status, setStatus] = useState('pending')
    const [telephone, setTelephone] = useState('00')
    const navigate = useNavigate()

    useEffect(() => {
        document.body.className = '';
        window.scrollTo(0, 0)
    },[])
    const submitcontactus = (e) => {
        try {
            e.preventDefault()
            var Mdl_contactus = {
                id: id, date: date, sender_email: sender_email, subject: subject, telephone: telephone, description: description, status: status
            }
            Commons.saveContatus(Mdl_contactus).then(res => {
                WindowSharp.location.replace('/datasucessfulysaved')
                // navigate("/datasucessfulysaved")
            }).catch((err) => {
                console.log('Error adding contact us details: ' + err)
                console.log(err )
            })
        } catch (err) {
            console.log('Error adding contact us details: ' + err)
        }

    }
    const iconStyle = {
        color: "rgb(255, 85, 0)",
        marginRight: "5px"

    };
    return (<>
        <Helmet>
            <title>Contactus - CODEGURU Systems</title>
            <meta name="description" content="Software Development Company in Rwanda" />
            <meta name="keywords" content="Software Development, Programming, Coding, Development Tools, 
            Software Engineering, Application Development, Web Development,
            Mobile Development, Software Solutions, Custom Software, 
            Agile Development, Software Design, Development Process, 
            Full Stack Development, Frontend Development, Backend Development,
            DevOps, Software Testing, Technology Solutions, Software Projects,
            Digital Transformation, Business Growth, Technology Solutions, Innovation,
            Process Optimization, Customer Experience, Efficiency, Scalability, Automation,
            Data Analytics, Digital Strategy, Cloud Services, Cybersecurity,
            IT Consulting, Custom Solutions, Competitive Advantage, Market Expansion, 
            Digital Marketing, Business Intelligence, Cost Reduction,"></meta>
        </Helmet>
        <Whatsapp />

        <Container fluid className='servicesprovidedBg'>
            <Row className='p-4 gx-4'>

                <p className='my-3'>
                    <h1 >Contact us</h1>
                </p>
                <p className='mb-3 mt-3 intro'>
                    Reach to us
                </p>


            </Row>

        </Container>

 

        <div className='container-fluid d-flex  contatuswrapper '>
            <div className=' row d-flex justify-content-center align-items-center '>
                <div className=' row borderborder-danger d-flex   formwrapper shadow mt-5    custom_login p-0 rounded rounded-2'>
                    <div className='col-sm-12 col-md-8 p-5'>
                        <h3>Lets understand how we may serve you</h3>
                        <Form>
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                <Form.Label column sm="3">
                                    Email
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control type="text" onChange={(e) => setSender_email(e.target.value)} placeholder="Email" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                <Form.Label column sm="3">
                                    Subject
                                </Form.Label>
                                <Col sm="9">
                                    <Form.Control onChange={(e) => setSubject(e.target.value)} type="text" placeholder="Subject" />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label column sm={3}>Message</Form.Label>
                                <Col sm="9">
                                    <textarea onChange={(e) => setdescription(e.target.value)} className='largeText'></textarea>
                                </Col>
                            </Form.Group>
                            <input onClick={(e) => submitcontactus(e)} type="submit" value="Send" className='btn btn-block' style={{ width: '100%' }} />
                        </Form>
                    </div>
                    <div className='col-md-4 p-4 pt-5 contactusbox  align-items-center  p2 text-light   rounded-end' >
                        <Icon style={iconStyle} className='icon' size={48} icon={contact} />
                        <h1 style={{ fontFamily: 'arial bold', color: '#fff', fontSize: '28px', fontWeight: 'bold' }}>Reach to us</h1>
                        <p className='lh-lg text-center my-2'>
                            Simply reach out to us using the contact details below, and our team will be delighted to assist . Or just tell us more by filling the form on the left
                        </p>
                        <p>
                            <Icon style={iconStyle} className='icon' size={32} icon={phone} />
                            (+250) 784 113 888 </p>
                        <p>
                            <Icon style={iconStyle} className='icon' size={32} icon={email} />
                            info@codeguru-pro.com </p>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default ContactusForm