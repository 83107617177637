import React, { useState } from 'react';
import { ic_view_week_outline as struc } from 'react-icons-kit/md/ic_view_week_outline'
import { ic_workspaces_outline as deploy } from 'react-icons-kit/md/ic_workspaces_outline'

import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList, FaAreaChart
} from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import MenuLogo from './MenuLogo';
import Icon from 'react-icons-kit';
import { ic_ac_unit_outline as dash } from 'react-icons-kit/md/ic_ac_unit_outline'
import { ic_add_business_outline as biz } from 'react-icons-kit/md/ic_add_business_outline'
import { compass as unit } from 'react-icons-kit/icomoon/compass'
import { cross as remove } from 'react-icons-kit/icomoon/cross'
import { Row } from 'react-bootstrap';
function GuruMasterSideBar({ children, switchStructurePane }) {
  const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const menuItem = [
        {
            path: "/runningapps",
            name: "Running Apps",
            icon: <Icon icon={unit} size={18} />, title: 'Running Apps'
        },
         {
            path: "/not-runningapps",
            name: "Running Apps",
            icon: <Icon icon={struc} size={16} />, title: 'Idle apps'
        }

    ]
    return (
        <div className="menuContainer">
           
            <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
                <MenuLogo isOpen={isOpen} toggle={toggle} switchStructurePane={switchStructurePane} />
                {
                    menuItem.map((item, index) => (
                        <NavLink to={item.path} key={index} className="link" title={item.title} activeclassName="active">
                            <div className="icon">{item.icon}</div>
                            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                        </NavLink>
                    ))
                }
            </div>
            <main>{children}</main>
        </div>

    );
}

export default GuruMasterSideBar