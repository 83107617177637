import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Icon } from 'react-icons-kit'
import { github } from 'react-icons-kit/fa/github'


import { shareSquareO as deploy } from 'react-icons-kit/fa/shareSquareO'
import { arrowsAlt as master } from 'react-icons-kit/fa/arrowsAlt'
import {ic_all_inbox_outline as basic_crud} from 'react-icons-kit/md/ic_all_inbox_outline'
import Utils from '../Utils'
function GuruMenu() {
    const iconStyles={
        marginRight: '5px',
        color: Utils.border
    }
    return (
       
            <div className='col-12'>
                <Row className='d-flex justify-content-center '>
                    <Col className="col-10 gap-3 d-flex justify-content-center  ">
                        <a href='/structure' className=' btn btn-block'>
                            <Icon size={'20'} style={iconStyles} icon={basic_crud} />
                            Basic CRUD </a>
                        <a href='/code-master' className=' btn btn-block'>
                            <Icon size={'20'} style={iconStyles} icon={master} />
                            GURU MASTER </a>
                        <a href='/code-deploy' className=' btn mx-3'>
                            <Icon size={'20'} style={iconStyles} icon={deploy} />
                            GURU DEPLOY</a>
                        <a href='/guru-githubread' className=' btn mx-3'>
                            <Icon size={'20'} style={iconStyles} icon={github} />

                            GURU GITHUB READ</a>
                    </Col>
                </Row>
            </div>
     
    )
}

export default GuruMenu