import React, { useEffect } from 'react'
import Highlights from '../pages/HIlights/Highlights'
import './Aboutus.css'



import robotsSitting from '../imgz/four robots  image.png'
import { Container, Row } from 'react-bootstrap'
import Solutions from '../home/Solutions'
import OurQualities from '../Global/OurQualities'
import Footer from '../Global/footer/Footer'
import Utils from '../Global/Utils'
import { Helmet } from 'react-helmet'
import Whatsapp from '../pages/WhatsappLink/Whatsapp'
function Aboutus() {
     
    return (
        <>
            <Helmet>
                <title>About us - CODEGURU Systems</title>
                <meta name="description" content="Comprehensive website services including design, UX, UI, responsive design, branding, front-end and back-end development, CMS, e-commerce solutions, SEO, content creation, multimedia production, localization, performance optimization, security, compliance, domain management, web hosting, maintenance, support, social media integration, email marketing, PPC advertising, digital marketing, custom web applications, API integration, user training, and documentation." />
                <meta name="keywords" content="Website Design, User Experience, UX, UI Design, Responsive Design, Branding, Front-End Development, Back-End Development, Content Management System, CMS, E-commerce Solutions, Copywriting, Multimedia Production, Localization, SEO, On-Page SEO, Technical SEO, Keyword Research, Analytics, Reporting, Performance Optimization, Security Measures, Compliance, Domain Management, Web Hosting, Maintenance, Support, Social Media Integration, Email Marketing, PPC Advertising, Digital Marketing, Custom Web Applications, API Integration, User Training, Documentation"></meta>

            </Helmet>
            <Whatsapp />

            <Container fluid className='servicesprovidedBg'>
                <Row className='p-4 gx-4'>

                    <p className='my-3'>
                        <h1 >About us</h1>
                    </p>
                    <p className='mb-3 mt-3 intro'>
                      Software development company
                    </p>


                </Row>
                <p className='p-4 mt-5'>
                    <b>About us:</b> our company provides innovative software tools designed to streamline business operations and enhance productivity across various sectors. With a focus on automation, our solutions simplify routine business tasks such as recording purchases and sales, allowing organizations to focus more on strategic activities. We empower businesses to harness the power of automation for improved accuracy and efficiency in their financial transactions and record-keeping processes.

                    Looking toward the future, we are set to expand our offerings to include advanced marketing tools, specifically tailored for effective advertising campaigns. This will enable businesses to engage with their customers more dynamically and drive growth through targeted marketing strategies

                    Additionally, we are committed to supporting the developer community by offering robust tools that significantly speed up application development. Our developer-friendly platform allows for rapid creation and deployment of applications, reducing development time and enabling innovation. Whether you are building complex enterprise systems or simple utility apps, our tools are designed to help you deliver high-quality software faster and more efficiently.
                </p>


            </Container>
            <Container fluid>
                <Solutions />
            </Container>
            <Container fluid className='  aboutus m-0'>
                <img src={robotsSitting} className='  img-fluid ' />
            </Container>
            <span className='text-dark  ' style={{ color: '#000' }}>
                {/* <OurQualities /> */}
            </span>
            <Footer />

        </>
    )
}

export default Aboutus