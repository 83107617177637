import React, { useEffect, useRef, useState } from 'react'
import Utils from '../../Global/Utils'
import PagesWapper from '../../Global/PagesWapper'
import Repository from '../../../Services/Repository'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'


/* #region  ------------------------------------   FORM ------------------------------------ */
import AnimateHeight from 'react-animate-height'
import ContainerRow, { ClearBtnSaveStatus, ContainerRowBtwn, FormInnerRightPane, SaveUpdateBtns } from '../../Global/ContainerRow'
import InputRow, { DropDownInput, TwoColInput } from '../../Global/Forms/InputRow'
/* #endregion */



import FormTools from '../../Global/Forms/PubFnx'
import ListToolBar, { SearchformAnimation } from '../../Global/ListToolBar'
import SearchBox from '../../Global/SearchBox'
import { useReactToPrint } from 'react-to-print'
import PrintCompanyInfo from '../../Global/PrintCompanyInfo'
import Icon from 'react-icons-kit'
import { packageIcon as app } from 'react-icons-kit/oct/packageIcon'
import { ListOptioncolWithDeactivate, OptionPart, OptionThreePart } from '../../Global/ListTable'


import { ic_person_pin_outline as usericon } from 'react-icons-kit/md/ic_person_pin_outline'
import { ic_email as emailicon } from 'react-icons-kit/md/ic_email'
import { ic_people_outline as groupIcon } from 'react-icons-kit/md/ic_people_outline'

import { ic_arrow_circle_up_outline as closeIcon } from 'react-icons-kit/md/ic_arrow_circle_up_outline'
import { ic_arrow_circle_down as showIcon } from 'react-icons-kit/md/ic_arrow_circle_down'
import { shareSquareO as deploy } from 'react-icons-kit/fa/shareSquareO'
import { arrowsAlt as master } from 'react-icons-kit/fa/arrowsAlt'
import { ic_all_inbox_outline as basic_crud } from 'react-icons-kit/md/ic_all_inbox_outline'
import { github } from 'react-icons-kit/fa/github'
import Commons from '../../../Services/Commons'
import { UsersList } from './UserReuasbleComponents'



function Users() {
    const [users, setUsers] = useState([])


    /* #region  ------------------------------------ FROM ------------------------------------ */
    // The form elements
    const [clearBtn, setClearBtn] = useState(false) //The cancel button
    const [showLoader, setShowLoader] = useState(false)
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [id, setId] = useState(null)
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [account_category_id, setAccount_category_id] = useState()
    const [categories, setCategories] = useState([]) //to be user on the form as a dropdown
    /* #endregion */


    const [height, setHeight] = useState(0);
    const [refresh, setRefresh] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [searchHeight, setSearchHeight] = useState(0);




    const [showuserDetails, setShowuserDetails] = useState(false)
    const [visibleIndex, setVisibleIndex] = useState(-1)

    const [profile, setProfile] = useState([])
    const [profileId, setProfileId] = useState()
    const [accountsAreEnabled, setAccountsAreEnabled] = useState([]) //Data List
    const [accountsAreDisabled, setAccountsAreDisabled] = useState([]) //Data List
    const [dataLoad, setDataLoad] = useState(false)

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'emp-data'
    });
    const [msg, setMsg] = useState('')
    const iconStyles = {
        marginRight: '5px',
        color: Utils.border
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        setShowLoader(true)
        var UsersDTO = {
            id: id, name: name, surname: surname, username: username, password: '123', account_category_id: account_category_id
        }
        if (!id && id !== undefined) {
            console.log('SAving the user: ' + id)
            Commons.saveAccount(UsersDTO).then((res) => {
                console.log('Added a single user successfully')
                setName('')
                setSurname('')
                setShowLoader(false)
                setHeight(0)
                setRefresh(!refresh)
            }).catch((err) => {
                setMsg(Utils.errorLogic(err))
                setShowLoader(false)
            })
        } else {
            console.log('Updating the user')
            console.log('The user id: ' + id + ' \n The profileId: ' + profileId + ' \n The account_categoryid: ' + account_category_id)
            Commons.UpdateAccount(id, profileId, account_category_id, UsersDTO).then((res) => {
                setName('')
                setSurname('')
                setShowAlert(true)
                setHeight(0)
                setRefresh(!refresh)
            }).catch((err) => {
                console.log('Error updating the user ' + err)
            })
        }
    }
    const getAllAccounts = () => {
        Repository.findAccount().then((res) => {
            setUsers(res.data)
        })
    }
    const getcategories = () => {
        Repository.findCategoriesdata().then((res) => {
            setCategories(res.data)
        })
    }
    const getAccountsAreEnabled = () => {
        Repository.findAccountByStatus('enabled').then((res) => {
            setAccountsAreEnabled(res.data);
            setDataLoad(true)
        });
    }
    const getAccountsAreDisenabled = () => {
        Repository.findAccountByStatus('disabled').then((res) => {
            setAccountsAreDisabled(res.data);
            setDataLoad(true)
        });
    }
    useEffect(() => {
        Utils.PageLoadeDefaults()
        document.body.classList.add('darkBody')
        getAllAccounts()
        getcategories()
        getAccountsAreEnabled()
        getAccountsAreDisenabled()
    }, [refresh])

    const clearHandle = () => {
        setName('')
        setUsername('')
        setEmail('')
        setId(null)
        setClearBtn(false)
        setHeight(0)
    }

    const getUserById = (id) => {
        Repository.findAccountById(id).then((res) => {

            setId(res.data.id)
            setName(res.data.mdl_profile.name)
            setSurname(res.data.mdl_profile.surname)
            setUsername(res.data.username)
            // setPassword(res.data.password)
            setAccount_category_id(res.data.mdl_account_category.id)
            setProfileId(res.data.mdl_profile.id)
            setEmail(res.data.mdl_profile.email)
            setClearBtn(true)
            showheight('auto')
        })
    }

    const showMore = (e, index) => {
        setVisibleIndex(index)
        setShowuserDetails(!showuserDetails)
    }


    function showheight(type) {
        setHeight(type)
    }
    const delAccountById = (id) => {
        Utils.Submit(() => {
            // Delete.deleteAccountById(id, () => { getAllAccounts() })
        }, () => { })
    }
    const DisableUserHandler = (id) => {
        Utils.Submit(() => {
            Repository.disableUser('disabled', id).then(res => {
                getAllAccounts()
                getAccountsAreEnabled()
                getAccountsAreDisenabled()
            })
        }, () => { })
    }
    const enabledHandler = (id) => {
        Utils.Submit(() => {
            Repository.disableUser('enabled', id).then(res => {
                getAllAccounts()
                getAccountsAreEnabled()
                getAccountsAreDisenabled()
            })
        }, () => { })
    }
    return (
        <PagesWapper>
            <Container>

                <AnimateHeight id="animForm" duration={300} animateOpacity={true} height={height}>

                    {msg &&
                        <Container>
                            <Row className='d-flex justify-content-center'>
                                <Col md={11}>
                                    <Alert className="alert alert-danger">{msg} </Alert>
                                </Col>
                            </Row>
                        </Container>
                    }
                    <ContainerRowBtwn clearBtn={clearBtn} form='users' showLoader={showLoader}  >
                        <ClearBtnSaveStatus height={height} showLoader={showLoader} showAlert={showAlert} />
                        <FormInnerRightPane onSubmitHandler={onSubmitHandler}>
                            <InputRow label1='name' name='Name' handle={(e) => setName(e.target.value)} val={name} />
                            <InputRow label1='surname' name='Surname' handle={(e) => setSurname(e.target.value)} val={surname} />

                            <DropDownInput label1="category" name="category" handle={(e) => setAccount_category_id(e.target.value)}>
                                {categories.map((category) => (
                                    <option selected={account_category_id === category.id} value={category.id} key={category.id} >{category.name}</option>
                                ))}
                            </DropDownInput>

                            <InputRow label1='username' name='Username' handle={(e) => setUsername(e.target.value)} val={username} />
                            <InputRow label1='email' name='Email' handle={(e) => setEmail(e.target.value)} val={email} />

                            <SaveUpdateBtns clearBtn={clearBtn} clearHandle={clearHandle} saveOrUpdate={FormTools.BtnTxt(clearBtn)} />
                        </FormInnerRightPane>
                    </ContainerRowBtwn>
                </AnimateHeight>


                <ContainerRow className="noBoldedHeaders" mt='3'>
                    <ListToolBar listTitle='Users List' height={height} entity='User' changeFormHeightClick={() => setHeight(height === 0 ? 'auto' : 0)} changeSearchheight={() => setSearchHeight(searchHeight === 0 ? 'auto' : 0)} handlePrint={handlePrint} searchHeight={searchHeight} />
                    <SearchformAnimation searchHeight={searchHeight}>
                        <SearchBox more='more' />
                    </SearchformAnimation>

                    <div ref={componentRef} className="dataTableBox">
                        <PrintCompanyInfo />
                        <Row>
                            {users.map((user, index) => {
                                return <UsersList user={user} index={index} getUserById={getUserById} DisableUserHandler={DisableUserHandler} />
                            })}
                        </Row>
                        <Row>
                            <h2>Active users</h2>
                        </Row>
                    </div>
                </ContainerRow>





            </Container>
        </PagesWapper>
    )
}

export default Users