import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import {
  MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput, MDBIcon
} from 'mdb-react-ui-kit';
import '../Styles/login/Login.css'

import { useSignIn, useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { ic_facebook } from 'react-icons-kit/md/ic_facebook'
import Icon from 'react-icons-kit';
import Repository from '../../Services/Repository';
import { Helmet } from 'react-helmet';
import Whatsapp from './WhatsappLink/Whatsapp';



function LoggedInPage() {

  const [userName, setUsername] = useState()
  const [password, setPassword] = useState()
  const [loginStatus, setLoginStatus] = useState(true)
  const [loginClick, setLoginClick] = useState(false)
  const signOut = useSignOut()
  const signIn = useSignIn()


  useEffect(() => {
    document.body.className = '';
    document.body.style.backgroundColor = '#d0eaf5'
    try {
      signOut()
    } catch (err) {
      console.log('Error while loggin out' + err)
    }
  }, [])

  const loginHandler = (e) => {
    e.preventDefault()
    const AuthRequest = {
      userName: userName,
      password: password
    }

    try {
      if (userName) {

        const response = Repository.Login(AuthRequest).then((res) => {
          console.log('Login status below')
          setLoginClick(true)
          if (res.data.stat !== 'fail') {
            console.log('---------------------user ---------------------')
            console.log(res.data.token)
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('userid', res.data.userDetails.id)
            localStorage.setItem('catname', res.data.userDetails.catname)
            localStorage.setItem('username', res.data.userDetails.username)

            setLoginStatus(true)
            signIn({
              token: res.data,
              expiresIn: 3600,
              tokenType: "Bearer",
              authState: { username: AuthRequest.userName }
            })
            const token = localStorage.getItem('token');
            if (token) {
              console.log('The use is logged in successfully')
              setLoginStatus(true)
              window.location.replace('/dashboard')
            }
          } else {
            setLoginStatus(false)

          }
        })
      } else {
        alert('You have to provide the username and password')
      }
    } catch (err) {

    }

  }
  const loginNg = {
    // background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)', 
    background: 'radial-gradient(circle, rgb(0, 23, 36) 0%, rgba(9,9,121,1) 25%, #1197d0 100%)',
    borderRadius: '1rem', maxWidth: '400px',
    boxShadow: '0px 0px 5px #000',
    border: '1px solid #fff'
  }
  const navigate = useNavigate();
  const goToUserRegistration = () => {
    navigate('/registration')
  }

const UsernameChangeHandler=(e)=>{
  setUsername(e.target.value)
  setLoginStatus(true)
}
const PasswordChangeHandler=(e)=>{
  setPassword(e.target.value)
  setLoginStatus(true)
}
  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="From Helmet" />
        <meta name="keywords" content="Software Development, Programming, Coding, Development Tools, 
            Software Engineering, Application Development, Web Development,
            Mobile Development, Software Solutions, Custom Software, 
            Agile Development, Software Design, Development Process, 
            Full Stack Development, Frontend Development, Backend Development,
            DevOps, Software Testing, Technology Solutions, Software Projects,
            Digital Transformation, Business Growth, Technology Solutions, Innovation,
            Process Optimization, Customer Experience, Efficiency, Scalability, Automation,
            Data Analytics, Digital Strategy, Cloud Services, Cybersecurity,
            IT Consulting, Custom Solutions, Competitive Advantage, Market Expansion, 
            Digital Marketing, Business Intelligence, Cost Reduction,"></meta>
      </Helmet>
      <Whatsapp />

      <Container fluid className=" min-vh-100">
        <Row className='d-flex justify-content-center custom_login custom_login  mt-5 '>
          <Col md={11} xs={12} sm={12} className='  shadow bg-light' >
            <LoginStatusContent loginStatus={loginStatus} />
            <Row className='p-md-2 p-lg-2 p-sm-0 p-xs-0'>
              <Col md={6} xs={12} sm={12} className='  custom_loginn p-5 rounded rounded-2 '>
                <h1>Login</h1>

                <Form onSubmit={loginHandler}>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="2">
                      Username
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control onChange={(e)=>UsernameChangeHandler(e)} placeholder="name@example.com" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="2">
                      Password
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control onChange={(e)=>PasswordChangeHandler(e)} type="password" placeholder="Your Password" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mt-3">
                    <Col sm={{ span: 10, offset: 2 }}>
                      <Button variant="primary btn-block d-block d-md-inline-block w-100 w-sm-auto" type="submit">
                        Login
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6} xs={12} sm={12} className="p-5 align-items-center p2  text-light rounded-end"  >
                <h1>Sign up</h1>
                <p className='lh-lg text-center my-2 small'>
                  Create an account with us to Stay tuned for import updates of  IT solutions
                </p>
                <div className='row d-flex my-5 justify-content-center'>
                  <button onClick={() => goToUserRegistration()} className='col-6 btn btn-primary d-block d-sm-inline-block w-100 w-sm-auto'>Register Now!</button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>


      </Container >

    </>
  );
}




export default LoggedInPage

export const LoginStatusContent = ({ loginStatus }) => {
  return <Row>
    <Col md={12}>

      {!loginStatus &&
        <Alert variant="danger">
          Username or Password Incorrect, try again !
        </Alert>
      }
    </Col>
  </Row>
}


