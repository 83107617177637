import React, { useState } from 'react'


// Icons
import { Icon } from 'react-icons-kit'
import { printer } from 'react-icons-kit/icomoon/printer'
import { floppyDisk as save } from 'react-icons-kit/icomoon/floppyDisk'
import { cancelCircle as cancel } from 'react-icons-kit/icomoon/cancelCircle'
import { plus as add } from 'react-icons-kit/icomoon/plus'
import { search } from 'react-icons-kit/icomoon/search'
import { pencil as edit } from 'react-icons-kit/icomoon/pencil'
import { cross as remove } from 'react-icons-kit/icomoon/cross'
import { forward as point } from 'react-icons-kit/icomoon/forward'
import { arrowRight as Mainpoint } from 'react-icons-kit/icomoon/arrowRight'

import { calendar } from 'react-icons-kit/icomoon/calendar'
import { Link } from 'react-router-dom'
import AnimateHeight from 'react-animate-height'


import { shareSquareO as deploy } from 'react-icons-kit/fa/shareSquareO'
import { arrowsAlt as master } from 'react-icons-kit/fa/arrowsAlt'
import { ic_all_inbox_outline as basic_crud } from 'react-icons-kit/md/ic_all_inbox_outline'
import { github } from 'react-icons-kit/fa/github'
import Utils from './Utils';
import { Col } from 'react-bootstrap';

function ListToolBar(props) {

    const [morebuttoms, setMoreButtons] = useState(false)

    const iconStyles = {
        marginRight: '5px',
        color: Utils.border
    }
    const showmore = () => {
        setMoreButtons(morebuttoms ? false : true)
    }

    return (
        <>
            <div className='col-12 '><h3 className='boldTitle'> {props.listTitle}  </h3></div>
            <div className='col-12'>
                <div className='row'>
                    <div className={`g-2 col-${props.ToggleexpandUnit}`}>
                        <button id='addREc' className='btn'
                            aria-expanded={props.height !== 0} aria-controls="animForm" onClick={props.changeFormHeightClick}
                            style={{ marginRight: "15px", backgroundColor: "#470343", fontSize: "12px", color: '#fff', fontWeight: "bold" }}>
                            <Icon size={11} style={{ marginRight: "8px", color: '#fff' }} icon={add} />
                            Add {props.entity} </button>
                        <button onClick={props.handlePrint} style={{ marginRight: "10px", fontSize: "12px" }} className='btn btn-dark ms-1'>
                            <Icon style={{ marginRight: "8px", color: '#fff' }} icon={printer} />
                            Print
                        </button>

                        <button className='btn btn-outline-success ms-1'
                            aria-expanded={props.searchHeight !== 0} aria-controls="animSearchBox" onClick={props.changeSearchheight}>
                            <Icon style={{ color: 'black' }} icon={search} />   </button>
                        {props.more === 'more' &&
                            <button className='btn btn-outline-success ms-1' onClick={(e) => showmore(e)}>...</button>
                        }
                    </div>

                    {props.more === 'more' && morebuttoms &&
                        <> <Col md={12} className='d-flex justify-content-start ps-0'>

                            {/* <Col className="col-10 gap-3   "> */}
                            <a href='/structure' className=' btn  m-0 btn-block'>
                                <Icon size={'20'} style={iconStyles} icon={basic_crud} />
                            </a>
                            <a href='/code-master' className=' btn mx-3 btn-block' title='master'>
                                <Icon size={'20'} style={iconStyles} icon={master} />
                            </a>
                            <a href='/code-deploy' className=' btn mx-3' title='deploy'>
                                <Icon size={'20'} style={iconStyles} icon={deploy} />
                            </a>
                            <a href='/guru-githubread' className=' btn mx-3' title='githubread'>
                                <Icon size={'20'} style={iconStyles} icon={github} />

                            </a>
                        </Col>
                        </>
                    }
                </div>
            </div>

        </>
    )
}
export function ListToolBarRenamed(props) {
    return (
        <>
            <div className='col-12 '><h3 className='boldTitle'> {props.listTitle}  </h3></div>
            <div className='col-12'>
                <div className='row'>
                    <div className='col-12 '>
                        <button id='addREc' className='btn'
                            aria-expanded={props.height !== 0} aria-controls='tuplesAnimForm' onClick={props.changeFormHeightClick}
                            style={{ marginRight: "15px", backgroundColor: "#470343", fontSize: "12px", color: '#fff', fontWeight: "bold" }}>
                            <Icon size={11} style={{ marginRight: "8px", color: '#fff' }} icon={add} />
                            Add {props.entity} </button>
                        <button onClick={props.handlePrint} style={{ marginRight: "10px", fontSize: "12px" }} className='btn btn-dark ms-1'>
                            <Icon style={{ marginRight: "8px", color: '#fff' }} icon={printer} />
                            Print
                        </button>
                        <button className='btn btn-outline-success ms-1'
                            aria-expanded={props.searchHeight !== 0} aria-controls="animSearchBox" onClick={props.changeSearchheight}>
                            <Icon style={{ color: 'black' }} icon={search} />   </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export const SearchformAnimation = (props) => {
    return (
        <div className='row '>
            <div className='col-12'>
                <AnimateHeight id="animSearchBox" // animating the search box
                    duration={250} animateOpacity={true}
                    height={props.searchHeight} >
                    {props.children}
                </AnimateHeight>
            </div>
        </div>
    )
}

export default ListToolBar