import React, { createContext, useState } from 'react';


export const GlobalContext = createContext();
export const GlobalStateProvider = ({ children }) => {
    const [globalState, setGlobalState] = useState({
      // initial state
      value: 'sangwa',
      singlePortisRunning:'8085',
      singlePortisRunningResponse:''
    });
  
    return (
      <GlobalContext.Provider value={{ globalState, setGlobalState }}>
        {children}
      </GlobalContext.Provider>
    );
  };