import axios from 'axios'
import React, { Component } from 'react'
import Conn from './Conn';
import Commons from './Commons';


class Repository {
    static page = (Repository.page < 1 || Repository.page == undefined) ? 1 : Repository.page;
    static size = (Repository.size < 1) ? 50 : Repository.size;
    static server = Conn.wholePath.name;
    // static url = "http://" + Repository.server + ":8089/guru/api"

    static headers = Conn.LoginToken
    static getHeaders = Conn.GetToken

    Login(authRequest) {
        return axios.post(Conn.server.name + Conn.port.val + "authenticate", authRequest, { headers: Repository.headers })
    }
    findAccountById(id) {
        return axios.get(Repository.server + "/account/" + id, { headers: Repository.headers })
    }
    disableUser(status, id) {
        return axios.get(Repository.server + "/account/disableOrEnable/users/byStatus/" + status + '/' + id, { headers: Repository.getHeaders })
    }
    findCategories() {
        return axios.get(Repository.server + "/", { headers: Repository.headers })
    }
    findCategoriesdata() {
        return axios.get(Repository.server + "/account_category/find/allCategories", { headers: Repository.headers })
    }
    findCategoriesCount() {
        return axios.get(Repository.server + "/count/", { headers: Repository.headers })
    }
    findProfile() {
        return axios.get(Repository.server + "/profile/", { headers: Repository.headers })
    }
    findAccount() {
        return axios.get(Repository.server + "/account/", { headers: Repository.headers }).catch(() => { Commons.RedirectToLogin() })
    }
    findAccountByStatus(status) {
        return axios.get(Repository.server + "/account/users/byStatus/" + status, { headers: Repository.getHeaders }).catch(() => { Commons.RedirectToLogin() })
    }
    findStrucuture() {
        console.log('--------------------SESSION ID STILL EXISTS')
        console.log(sessionStorage.getItem('token'))
        return axios.get(Repository.server + "/structure/", { headers: Repository.headers }).catch((err) =>
            Commons.RedirectToLogin()
        )
    }
    findApssDownloads() {
        return axios.get(Repository.server + "/codes/", { headers: Repository.headers })
    }
    findFrontEndApssDownloads() {
        return axios.get(Repository.server + "/codes/frontendApps", { headers: Repository.headers })
    }
    findApssDownloadSingleApp(appName) {/*Backend*/
        fetch(Repository.server + '/codes/download/' + appName, { headers: Repository.headers })
            .then(response => {

                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = appName + 'Backend.zip';
                    a.click();
                });
            });
    }
    findApssDownloadSingleFrontEndApp(appName) { /*FrontEnd*/
        fetch(Repository.server + '/codes/downloadfront/' + appName, { headers: Repository.headers })
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = appName + 'FrontEnd.zip';
                    a.click();
                });
            });
    }
    findStrucById(id) {
        return axios.get(Repository.server + "/structure/" + id, { headers: Repository.headers })
    }
    findUnit() {
        return axios.get(Repository.server + "/unit/", { headers: Repository.headers })
    }
    findUnitById(id) {
        return axios.get(Repository.server + "/unit/" + id, { headers: Repository.headers })
    }
    findUnitByStructureId(id) {
        return axios.get(Repository.server + "/unit/unitBystructure/" + id, { headers: Repository.headers })
    }
    findTuples() {
        return axios.get(Repository.server + "/tuple/", { headers: Repository.headers })
    }
    findTupleById(id) {
        return axios.get(Repository.server + "/tuple/singleTuple/" + id, { headers: Repository.headers })
    }
    findTuplesByUnitId(id) {
        return axios.get(Repository.server + "/tuple/tupleByunitid/" + id, { headers: Repository.headers })
    }

    updateUnitByUnitname(name, unitId) {
        return axios.get(Repository.server + "/unit/update/unitbyid/" + name + '/' + unitId, { headers: Repository.headers })

    }


}

export default new Repository()