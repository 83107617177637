import React from 'react'
import Conn from '../Conn';
import axios from 'axios';

class Repository {
    static page = (Repository.page < 1 || Repository.page == undefined) ? 1 : Repository.page;
    static size = (Repository.size < 1) ? 50 : Repository.size;
    static server = Conn.wholePath.name;
    // static url = "http://" + Repository.server + ":8089/guru/api"

    static headers = Conn.LoginToken
    static getHeaders = Conn.GetToken

    static responseFromBackend = 'default'

    findgithubProjects() {
        return axios.get(Conn.wholePath.githubread + "/projects/").catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log('Error Response Data:', error.response.data);
                console.log('Error Response Status:', error.response.status);
                console.log('Error Response Headers:', error.response.headers);
                Repository.responseFromBackend = error.status
            } else if (error.request) {
                // The request was made but no response was received
                console.log('Error Request:', error.request);
                Repository.responseFromBackend = error.request
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error Message:', error.message);
                Repository.responseFromBackend = error.message
            }
            console.log('Error from repository file: ' + error)

        })

    }
    findgithubRead() {
       
        return axios.get(Conn.wholePath.githubread + "/readlines/")
    }
    findAllDeployedApps(){
        return axios.get(Conn.wholePath.guru_deploy +"")
    }

        //REal time
    findguruDeployRunnning(){
        return axios.get(Conn.wholePath.guru_deploy +"/check/runStatus").then((res)=>{

        }).catch((err)=>{
            console.log('Error' )
            console.log(err )
        })
    }
    updateReRunApp(){
        return axios.get(Conn.wholePath.guru_deploy +"/git/clone").then((res)=>{

        }).catch((err)=>{
            console.log('Error' )
            console.log(err )
        })
    }
    updateReset(id){
        return axios.put(Conn.wholePath.guru_deploy +"/make/update/lastCommit/"+id).then((res)=>{

        }).catch((err)=>{
            console.log('Error' )
            console.log(err )
        })
    }



}

export default new Repository()