import React, { useEffect, useRef, useState } from 'react'
import Utils from '../../Global/Utils'
import Repository from '../../../Services/Repository'
import { Card, Col, Row } from 'react-bootstrap'
import Icon from 'react-icons-kit'
import { OptionThreePart } from '../../Global/ListTable'
import { ic_person_pin_outline as usericon } from 'react-icons-kit/md/ic_person_pin_outline'
import { ic_email as emailicon } from 'react-icons-kit/md/ic_email'
import { ic_people_outline as groupIcon } from 'react-icons-kit/md/ic_people_outline'

import { ic_arrow_circle_up_outline as closeIcon } from 'react-icons-kit/md/ic_arrow_circle_up_outline'
import { ic_arrow_circle_down as showIcon } from 'react-icons-kit/md/ic_arrow_circle_down'
import InputRow from '../../Global/Forms/InputRow'

function UserReuasbleComponents() {


}

export default UserReuasbleComponents

export const UsersList = ({ user, index, getUserById, DisableUserHandler }) => {

    /* #region  --------------------FIELDS DECLARATIONS ----------------------------- */
    // The form elements
    const [height, setHeight] = useState(0);
    const [showLoader, setShowLoader] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [clearBtn, setClearBtn] = useState(false) //The cancel button
    const [searchHeight, setSearchHeight] = useState(0);
    const [showuserDetails, setShowuserDetails] = useState(false)
    const [visibleIndex, setVisibleIndex] = useState(-1)
    const [id, setId] = useState(null)
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')

    const [account_category_id, setAccount_category_id] = useState()
    const [categories, setCategories] = useState([])
    const [profile, setProfile] = useState([])
    const [profileId, setProfileId] = useState()
    const [accountsAreEnabled, setAccountsAreEnabled] = useState([]) //Data List
    const [accountsAreDisabled, setAccountsAreDisabled] = useState([]) //Data List
    const [dataLoad, setDataLoad] = useState(false)


    /* #endregion */

    /* #region  --------------------FUNCTIONS           ---------------------------- */
    const iconStyles = {
        marginRight: '5px',
        color: Utils.border
    }


    const getcategories = () => {
        Repository.findCategoriesdata().then((res) => {
            setCategories(res.data)
        })
    }
    const getAccountsAreEnabled = () => {
        Repository.findAccountByStatus('enabled').then((res) => {
            setAccountsAreEnabled(res.data);
            setDataLoad(true)
        });
    }
    const getAccountsAreDisenabled = () => {
        Repository.findAccountByStatus('disabled').then((res) => {
            setAccountsAreDisabled(res.data);
            setDataLoad(true)
        });
    }

    const clearHandle = () => {
        setName('')
        setUsername('')
        setEmail('')
        setId(null)
        setClearBtn(false)
        setHeight(0)
    }

    const delUser = () => {

    }
    const showMore = (e, index) => {
        setVisibleIndex(index)
        setShowuserDetails(!showuserDetails)
    }


    function showheight(type) {
        setHeight(type)
    }
    const delAccountById = (id) => {
        Utils.Submit(() => {
            // Delete.deleteAccountById(id, () => { getAllAccounts() })
        }, () => { })
    }

    /* #endregion */


    return (<Col>
        <Card className='centeredcard cards d-block mx-auto slideDownUp' style={{ width: '16rem' }} >
            <Card.Body >

                <Row className="d-flex justify-content-center">
                    <Col md={12}>
                        <a href='#' className='mx-auto d-block m-auto' >
                            <Icon className='bpmx-auto d-block ball' size={Utils.cardSmallSize()} style={{ color: 'rgb(15, 179, 230)', marginRight: "10px" }} icon={usericon} />
                        </a>

                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card.Title className='devCarTitle' style={Utils.cardTitle()}>
                            <h3 title={user.username} className='fw-bold mt-5 heading OneLine noBoldedHeaders'> {user.mdl_profile.name}     {user.mdl_profile.surname} </h3>
                        </Card.Title>
                    </Col>
                    <p>
                        <a onClick={(e) => showMore(e, index)}>
                            {!showuserDetails ?
                                <Icon className='bpmx-auto d-block  ' size={30} style={{ color: '#0aa342', cursor: 'pointer', marginRight: "10px" }} icon={showIcon} />
                                : <Icon className='bpmx-auto d-block  ' size={30} style={{ color: '#0aa342', cursor: 'pointer', marginRight: "10px" }} icon={closeIcon} />
                            }

                        </a>
                    </p>
                </Row>
                {visibleIndex == index && showuserDetails && (<>
                    <Row>
                        <Col md={12}>
                            <Card.Text className='desc fewLines_6Lines'>
                                <Row>
                                    <Col className='col-auto'> <Icon className='bpmx-auto d-block  ' size={16} style={{ color: 'rgb(15, 179, 230)', marginRight: "10px" }} icon={groupIcon} /></Col>
                                    <Col> {user.mdl_account_category.name} </Col>
                                </Row>
                                <Row>
                                    <Col className='aol-auto'> <Icon className='bpmx-auto d-block  ' size={16} style={{ color: 'rgb(15, 179, 230)', marginRight: "10px" }} icon={emailicon} /></Col>
                                    <Col>{user.mdl_profile.email}</Col>
                                </Row>

                            </Card.Text>
                            <OptionThreePart getEntityById={() => getUserById(user.id)} disable={() => DisableUserHandler()} delEntityById={() => delUser(user.id)} />
                        </Col>
                    </Row></>)}
                {/* <Card.Link className='btn generalButtons' href="#">Update</Card.Link> */}
            </Card.Body>
        </Card>
    </Col>
    )

}

 
 