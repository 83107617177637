import React, { useState } from 'react'


function UpdatedComponent(OriginalComponent) {
    function NewComponent() {
        const [pubId, setPubId] = useState(2)

        const handleGetId = () => {
            setPubId(pubId => pubId * 2)
        }

        return <OriginalComponent
            handleGetId={handleGetId}
            pubId={pubId} />
    }
    return NewComponent
}
export default UpdatedComponent




export function CommonStrucId(OriginalComponent) {
    function NewComponent() {
        const [structureId, setStructureId] = useState()

        const handleGetId = () => {
            setStructureId(structureId => structureId)
        }

        return <OriginalComponent
            handleGetId={handleGetId}
            structureId={structureId} />
    }
    return NewComponent
}