import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'

import CAsh_coins_falling_donw_anim from '../imgz/home/devSection/CAsh_coins_falling_donw_anim.json'
import cash from '../imgz/home/devSection/cash.json'
import code_many_languages from '../imgz/home/devSection/code_many_languages.json'
import Developer from '../imgz/home/devSection/Developer.json'
import food from '../imgz/home/devSection/food.json'
import fork_spoon_food from '../imgz/home/devSection/fork_spoon_food.json'
import items_moving_around_pc_software from '../imgz/home/devSection/items_moving_around_pc_software.json'

import PC_animation from '../imgz/home/devSection/PC_animation.json'
import PC_animation2 from '../imgz/home/devSection/PC_animation2.json'
import pc_mobile_responsivecode from '../imgz/home/devSection/pc_mobile_responsivecode.json'
import PC_reports from '../imgz/home/devSection/PC_reports.json'
import cash_coin from '../imgz/home/devSection/Pc_processing.json'


import { Icon } from 'react-icons-kit'
import { u1F348 } from 'react-icons-kit/noto_emoji_regular/u1F348'
import { droplet } from 'react-icons-kit/icomoon/droplet'
import { freeCodeCamp } from 'react-icons-kit/fa/freeCodeCamp'
import { bandcamp } from 'react-icons-kit/fa/bandcamp'
import { meetup } from 'react-icons-kit/fa/meetup'
import { ic_chrome_reader_mode } from 'react-icons-kit/md/ic_chrome_reader_mode'

import { shoppingBag as packages_app } from 'react-icons-kit/typicons/shoppingBag'
import { ic_desktop_mac_outline as computer } from 'react-icons-kit/md/ic_desktop_mac_outline'
import { ic_cast_connected as templates } from 'react-icons-kit/md/ic_cast_connected'
import it_drawing from '../imgz/another drawing 3.webp'
import programmerskills from '../imgz/programmerskils.png'
import onlytheo from '../imgz/home/OnlyTheo.JPG'
import nocodedev from '../imgz/home/NoCodeDev.JPG'
import CodeEnhancement from '../imgz/home/CodeEnhancement.JPG'
import Lottie from "lottie-react";
import Utils from '../Global/Utils'

import { rocket as goals } from 'react-icons-kit/fa/rocket'
import { clockO as time } from 'react-icons-kit/fa/clockO'
import { shield as quality } from 'react-icons-kit/fa/shield'
import { cubes as efficiency } from 'react-icons-kit/fa/cubes'
import { useNavigate } from 'react-router-dom'
function DevInterests() {



  const mrgin = '20px'
  const icon_one_style = {
    color: '#0087ef',
    marginBottom: mrgin
  }
  const icon_two = {
    color: '#fd7a00',
    marginBottom: mrgin
  }
  const icon_three = {
    color: '#fd00e8',
    marginBottom: mrgin
  }

  const icon_four = {
    color: '#0087ef',
    marginBottom: mrgin
  }

  const icon_five = {
    color: '#b500fd',
    marginBottom: mrgin
  }

  const cardTitle = {
    margin: '0px',
    color: '#000',
    fontSize: '18px',
    // textShadow: '1px 1px '

  }
  const [animate, setAnimate] = useState(false);
  const navigate = useNavigate()
  const gotoServices = () => {
    navigate("/contactus");
  }
  const handleAnimate = () => {
    setAnimate(true);
    setTimeout(() => setAnimate(false), 1000); // Reset after 1 second (adjust as needed)
  };
  return (
    <>
      <Container fluid className="dev  bigTitle" style={{ backgroundColor: Utils.skinBg1() }}  >
        <Row className=" d-flex justify-content-center  " style={{ padding: '70px' }}>
          <Col md={10}  >
            <h1 className="  text-center " >Developers  </h1>
            <p className="  w-50 w-sm-100 mx-auto text-center my-4 ">With GIGAFLEX you streamline your workflow with our code generator that turns hours of coding into a few clicks</p>
          </Col>

        </Row>
      </Container>


      <Container fluid style={{ padding: '20px', backgroundColor: Utils.skinBg1() }} >
        <Row className="d-flex justify-content-between p-0" style={{ backgroundColor: '#fff' }}>
          <Col md={12} className="p-0 m-0" >
            <Card className={`centeredcard card p-0 m-0   animated-card ${animate ? 'animate' : ''}`} >
              <Card.Body>
                <Card.Text className="cardDesc my-3">
                  <img src={CodeEnhancement} className='img-fluid' width="100%" />

                </Card.Text>
                {/* <Card.Link href="#">Card Link</Card.Link> */}

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* -----------------------------------------CONTENT WRAPPER TEXT/ CONTENT PART TEXT----------------------------------------- */}


      <Container fluid className='contentPartWrapper' style={{ backgroundColor: '#d7eff4', position: 'relative' }}>
        <Row className='section mt-5 mb-5 contentPart'   >
          <h1  >Templates </h1>
          <p>
            Our platform lets you build powerful applications without writing code. Use our intuitive drag-and-drop interface to create complex workflows, design responsive UIs, and
            integrate data sources seamlessly, accelerating development and focusing on solving business problems.
          </p>
          <p>
            Moreover, Code Copilot is equipped with a library of pre-built applications. These applications come with standard features that are optimized for specific
            domains, offering developers a robust foundation upon which to build and customize their solutions. This not only speeds up the development process but also
            ensures that the applications adhere to industry standards, providing a high level of reliability and performance right from the start.
          </p>
          <p className='d-none'>
            <button className="darkButton" onClick={() => gotoServices()} href='#'>Read more </button>
          </p>
        </Row>
      </Container>




      {/* -----------------------------------------  CSS IMAGE BACKGROUND  ----------------------------------------- */}



      <Container fluid className=" m-0  cssImage_container " style={{ backgroundColor: Utils.skinBg1(), position: 'relative' }}>
        <Row className=" dev_inner_image " style={{ backgroundColor: '#fff' }}>

          <Col md={5} ms={3} className='p-5  m-3 '>
            <h1 className="leftTextOnImage">
              Have a project in mind? Contact us to discuss your software needs!
            </h1>
          </Col>
          <div className="overlay"></div>

          {/* <img src={onlytheo} /> */}
        </Row>
      </Container>


      <Container fluid className="dev    boldedHeader  " style={{ padding: '20px', backgroundColor: Utils.skinBg1() }}  >
 
        <Row>


          {/* <Col className="col-3  text-center">
            <h4 className="ms-3 ">Building tomorrow's tech, today.</h4>
            <Button variant="primary" onClick={() => console.log("Primary")}>
              Check how
            </Button>
            <div style={{ maxHeight: '300px' }} className="col-6 m-auto ">
              <Lottie style={{ width: '250px' }} animationData={PC_reports} />
              </div>
          </Col> */}
          {/*     <Col className="col-3 text-center">
           
            <Lottie style={{ width: '250px' }} animationData={CAsh_coins_falling_donw_anim} />
            <Button variant="primary" onClick={() => console.log("Primary")}>
              Check how
            </Button>

          </Col>
          <Col className="col-3 text-center">
            <Lottie style={{ width: '250px' }} animationData={cash} />
            <Button variant="primary" onClick={() => console.log("Primary")}>
              Check how
            </Button>

          </Col>
          <Col className="col-3 text-center">
            <Lottie style={{ width: '250px' }} animationData={code_many_languages} />
            <Button variant="primary" onClick={() => console.log("Primary")}>
              Check how
            </Button>

          </Col>
          <Col className="col-3 text-center">
            <Lottie style={{ width: '250px' }} animationData={Developer} />
          </Col>
          <Col className="col-3 text-center">
            <Lottie style={{ width: '250px' }} animationData={food} />
          </Col>
          <Col className="col-3 text-center">
            <Lottie style={{ width: '250px' }} animationData={fork_spoon_food} />
          </Col>
          <Col className="col-3 text-center">
            <Lottie style={{ width: '250px' }} animationData={items_moving_around_pc_software} />
          </Col>
          <Col className="col-3 text-center">
            <Lottie style={{ width: '250px' }} animationData={PC_animation} />
            
          </Col>
          <Col className="col-3 text-center">
            <Lottie style={{ width: '250px' }} animationData={PC_animation2} />
          </Col>
          <Col className="col-3 text-center">
            <Lottie style={{ width: '250px' }} animationData={pc_mobile_responsivecode} />
          </Col>
          <Col className="col-3 text-center">
            <Lottie style={{ width: '250px' }} animationData={cash_coin} />
          </Col> */}
        </Row>


      </Container >

    </>
  )
}

export default DevInterests