import React, { useEffect, useState } from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'

import './GithubREad.css'
import '../../Dashboard/Dashboard.css'

import Conn from '../../../Services/Conn'
import axios from 'axios'
import { warning } from 'react-icons-kit/icomoon/warning'
import Icon from 'react-icons-kit'
import AllPorjects from './AllPorjects'
import AllProjectsBylanguage from './AllProjectsBylanguage'
import Pagination from '../../Global/Pagination'
import GuruMasterSideBar from '../../pages/menu/GuruMasterSideBar'
import Utils from '../../Global/Utils'

function GithubRead() {
    const [projectsData, setProjectsData] = useState([])
    const [projectsDataGroupByllanguage, setProjectsDataGroupByllanguage] = useState([])
    const [projectError, setProjectError] = useState(null);
    const [LinesError, setLinesError] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);

    /* #region  Pagination for Project */
    const [currentPage, setCurrentPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [activeButton, setActiveButton] = useState(null);

    const [uiStart, setUiStart] = useState(0)
    const [uiEnd, setUiEnd] = useState(10)

    const paginate = (pageNumber, e) => {

        setCurrentPage(pageNumber)
        setDataLoading(true)
        setActiveButton(pageNumber);
        e.preventDefault()
    }
    const pageSize = 2;
    const handleNextPage = () => {
        if (hasNextPage) {
            setCurrentPage(prevPage => prevPage + 1);
            setUiEnd(u => u + 1)
            setUiStart(u => u + 1)
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(prevPage => prevPage - 1);
            setUiEnd(u => u - 1)
            setUiStart(u => u - 1)
        }
    };
    /* #endregion */

    const errorLogic = (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('Error Response Data:', error.response.data);
            console.log('Error Response Status:', error.response.status);
            console.log('Error Response Headers:', error.response.headers);
            setProjectError('Server returned error with status: ' + error.response.status)
            setLinesError('Server returned error with status: ' + error.response.status)
        } else if (error.request) {
            // The request was made but no response was received
            console.log('Error Request:', error.message);
            setProjectError(error.message === 'Error Request' || error.message === 'Network Error' ? 'No connection to backend' : error.message)
            setLinesError(error.message === 'Error Request' || error.message === 'Network Error' ? 'No connection to backend' : error.message)
        }
        else {
            // Something happened in setting up the request that triggered an Error
            console.log('Something happend in request setting, Error Message:', error.message);
            setProjectError('Something happend in request setting, Error Message:', error.message)
            setLinesError('Something happend in request setting, Error Message:', error.message)
        }

    }

    const getProjectsData = async (page, size) => {
        try {
            var pageConf = {
                // headers: Repository.getHeaders,
                params: { page: currentPage, size: pageSize },
            }
            const response = await axios.get(Conn.wholePath.githubread + "/projects/", pageConf);
            setProjectError(null)
            setLinesError(null)
            const { data, totalItems, hasNextPage } = response;
            setTotalItems(totalItems);
            setHasNextPage(data.hasNextPage);
            setTotalItems(data.totalItems)
            setProjectsData(data.items)

            return data
        } catch (error) {
            errorLogic(error)
        }
    }
    const getLinessData = async (page, size) => {
        // try {
        //     const response = await axios.get(Conn.wholePath.githubread + "/readlines/");
        //     setProjectError(null)
        //     setLinesError(null)
        //     const { data, status, statusText, headers, config } = response;
        //     setLinesData(data)
        //     // logError(response)
        //     return data
        // } catch (error) {
        //     errorLogic(error);
        // }
        // try {
        //     const response = await axios.get(Conn.wholePath.githubread + "/projects/bylanguage/groupBy");
        //     setProjectError(null)
        //     setLinesError(null)
        //     const { data, status, statusText, headers, config } = response;
        //     setProjectsDataGroupByllanguage(data)
        //     // logError(response)

        // } catch (error) {
        //     errorLogic(error);
        // }
        // try {
        //     const response = await axios.get(Conn.wholePath.githubread + "/projects/bylanguage/groupBy");
        //     projectsDataGroupByllanguage.map((proj) => {
        //         const counts = axios.get(Conn.wholePath.githubread + "/projects/bylanguage/count/" + proj.language);
        //     })

        //     setProjectError(null)
        //     setLinesError(null)
        //     const { data, status, statusText, headers, config } = response;
        //     setProjectsDataGroupByllanguage(data)
        //     // logError(response)

        // } catch (error) {
        //     errorLogic(error);
        // }


    }

    useEffect(() => {
        Utils.PageLoadeDefaults()
        document.body.classList.add('darkBody')
        getProjectsData(currentPage, pageSize).then((res) => {
            setDataLoading(false)
        })

        const interval = setInterval(getProjectsData, 10000);
        // Cleanup the interval on component unmount
        return () => clearInterval(interval);
        /* #endregion */

    }, [currentPage])

    return (
        <>
            <GuruMasterSideBar>
                {/* When on error */}
                {(projectError || LinesError) && (
                    <Container style={{ height: '80vh' }} >
                        <Row className='  h-100 d-flex align-items-center justify-content-center'>
                            <p className='text-center'>
                                <h1 >
                                    <Icon size={50} icon={warning} /> {projectError}
                                </h1>
                            </p>
                        </Row>
                    </Container>
                )}
                {/* When no error */}
                {(!projectError && !LinesError) && (<Container >
                    <br />
                    <h1>Project by Programming Language  {dataLoading ? 'loading...' : ''}</h1>
                    <hr />
                    <AllPorjects projectsData={projectsData} />
                    <Row className='mt-3'>
                        <Pagination uiStart={uiStart} uiLimit={uiEnd} itemsPerPage={pageSize} totalitems={totalItems} paginate={paginate}
                            prevEvent={handlePreviousPage} nextEvent={handleNextPage} activeButton={activeButton} />
                    </Row>
                    <AllProjectsBylanguage projectsData={projectsDataGroupByllanguage} />
                    <h1>Files Store</h1>

                </Container >)}
            </GuruMasterSideBar>
        </>
    )
}

export default GithubRead