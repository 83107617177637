import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Slide } from 'react-reveal'

import '../Styles/DarkTheme.css'
import './Dashboard.css'
import bigLogo from '../imgz/logo/Logo_400.png'
import { color, motion, useDragControls } from "framer-motion"
import Utils from '../Global/Utils'
import GuruMaster from '../Global/GuruMaster/GuruMaster'
import { Icon } from 'react-icons-kit'
import { shareSquareO as deploy } from 'react-icons-kit/fa/shareSquareO'
import { github } from 'react-icons-kit/fa/github'
import { arrowsAlt as master } from 'react-icons-kit/fa/arrowsAlt'
import GuruMenu from '../Global/GuruMainMenu/GuruMenu'
import { GlobalContext } from '../Global/GlobalContext'
import PostRequests from '../../Services/GithubRead/PostRequests'
import Repository from '../../Services/GithubRead/Repository'
function Dashboard() {
    const { globalState, setGlobalState } = useContext(GlobalContext);
    const controls = useDragControls()
    const cardTitle = {
        margin: '0px',
        color: '#000',
        fontSize: '18px',
        // textShadow: '1px 1px '


    }
    const iconStyles = {
        marginRight: '5px',
        color: Utils.border

    }





    const [response, setResponse] = useState()
    const [count, setCount] = useState()
    const getRunningServices = (myjar) => {
        setCount(prevCount => prevCount + 1);
        console.log('........ running every 5 seconds')
        PostRequests.checkRunningAps(myjar).then((res) => {
            setResponse(res.data)
            setGlobalState({ singlePortisRunningResponse: res.data })
        }).catch((err)=>{
            
        })
        Repository.findguruDeployRunnning().then((res) => {
            
        }).catch((err)=>{
            
        })

    }
    useEffect(() => {
        Utils.PageLoadeDefaults()
        document.body.classList.add('darkBody')
        document.body.classList.add('gurumasterbody')

        //Send the post request to check which apps are running
        const myjar = "8091"

        const interval = setInterval(() => getRunningServices(myjar), 10000);
        setCount(pcount => pcount += 1)
        // Cleanup the interval on component unmount
        return () => clearInterval(interval);

    }, [])





    return (
        <>
       
            <Container>
                <Row className=' d-flex justify-content-center align-items-center ' style={{ height: '75vh' }}>
                    <Slide top>
                        <Col className='p-4 '>
                            <h1 className="text-center specialHeading">
                                {/* <Icon style={iconStyle} className='icon mb-1' size={98} icon={confirmed} /> */}
                                GIGAFLEX {count}
                            </h1>
                            <p>
                                <img src={bigLogo} width="100px" className=' d-block mx-auto mt-5' />
                            </p>
                        </Col>
                    </Slide>
                    <GuruMenu />
                </Row>
            </Container>

            <GuruMaster />




        </>

    )
}

export default Dashboard