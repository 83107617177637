import React, { useState } from 'react';
import { ic_view_week_outline as struc } from 'react-icons-kit/md/ic_view_week_outline'
import { ic_workspaces_outline as deploy } from 'react-icons-kit/md/ic_workspaces_outline'

import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList, FaAreaChart
} from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import MenuLogo from './MenuLogo';
import Icon from 'react-icons-kit';
import { ic_ac_unit_outline as dash } from 'react-icons-kit/md/ic_ac_unit_outline'
import { ic_add_business_outline as biz } from 'react-icons-kit/md/ic_add_business_outline'
import { compass as unit } from 'react-icons-kit/icomoon/compass'
import { cross as remove } from 'react-icons-kit/icomoon/cross'
import { Row } from 'react-bootstrap';

export const MainSideBar = ({ children, switchStructurePane }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const menuItem = [
        {
            path: "/structure",
            name: "Structure",
            icon: <Icon icon={struc} size={16} />, title: 'Stucture'
        },
        {
            path: "/unit",
            name: "Unit",
            icon: <Icon icon={unit} size={18} />, title: 'Unit'
        },

        {
            path: "/deploy",
            name: "Deploy",
            icon: <Icon icon={deploy} size={18} />, title: 'Deploy'
        },
        {
            path: "/pomconf",
            name: "Run",
            icon: <Icon icon={dash} size={18} />,
            title: 'Pom'
        },

    ]
    return (
        <div className="menuContainer">
           
            <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
                <MenuLogo isOpen={isOpen} toggle={toggle} switchStructurePane={switchStructurePane} />
                {
                    menuItem.map((item, index) => (
                        <NavLink to={item.path} key={index} className="link" title={item.title} activeclassName="active">
                            <div className="icon">{item.icon}</div>
                            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                        </NavLink>
                    ))
                }
            </div>
            <main>{children}</main>
        </div>

    );
};


export const DeploySubMenu = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const menuItem = [
        {
            path: "/",
            name: "Home",
            icon: <FaTh size='1em' />
        },
        {
            path: "/about",
            name: "About",
            icon: <FaUserAlt size='1em' />
        },
        {
            path: "/analytics",
            name: "Analytics",
            icon: <FaRegChartBar size='1em' />
        }
    ]

    const menuItemStyles = {
        fontSize: '13px'
    }
    return (
        <div className="menuContainer ">
            <div style={{ width: isOpen ? "200px" : "50px" }} className="sidebar">
                <div className="top_section">
                    <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">Logo</h1>
                    <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
                        <FaBars onClick={toggle} />
                    </div>
                </div>
                {
                    menuItem.map((item, index) => (
                        <NavLink to={item.path} key={index} className="link" activeclassName="active">
                            <div className="icon">{item.icon}</div>
                            <div style={{ display: isOpen ? "block" : "none", fontSize: '13px' }} className="link_text ">{item.name}</div>
                        </NavLink>
                    ))
                }
            </div>

            <main>{children}</main>
        </div>

    );
}

export const Switchbtn = ({ switchStructurePane }) => {
    return <>
        <div className='paneSwitchswichToggle'>
            <Icon className='remove' size={13} onClick={switchStructurePane} style={{ color: '#ff0000', marginRight: "10px" }} icon={remove} />
        </div>
    </>
}