import React, { useEffect, useRef, useState } from 'react'
import Utils from '../../Global/Utils'
import PagesWapper from '../../Global/PagesWapper'
import Repository from '../../../Services/Repository'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'


import '../../Contactus/contactus.css'
import './Registration.css'
/* #region  ------------------------------------   FORM ------------------------------------ */
import AnimateHeight from 'react-animate-height'
import ContainerRow, { ClearBtnSaveStatus, ContainerRowBtwn, ContainerRowWelcome, FormInnerRightPane, SaveUpdateBtns } from '../../Global/ContainerRow'
import InputRow, { DropDownInput, TwoColInput } from '../../Global/Forms/InputRow'
/* #endregion */



import FormTools from '../../Global/Forms/PubFnx'
import ListToolBar, { SearchformAnimation } from '../../Global/ListToolBar'
import SearchBox from '../../Global/SearchBox'
import { useReactToPrint } from 'react-to-print'
import Commons from '../../../Services/Commons'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Global/footer/Footer'



function PubUserRegistration() {
    const [users, setUsers] = useState([])


    /* #region  ------------------------------------ FROM ------------------------------------ */
    // The form elements
    const [clearBtn, setClearBtn] = useState(false) //The cancel button
    const [showLoader, setShowLoader] = useState(false)
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [id, setId] = useState(null)
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [account_category_id, setAccount_category_id] = useState(0)
    const [devUser, setDevUser] = useState('') //the user aor developer
    const [categories, setCategories] = useState([]) //to be user on the form as a dropdown

    /* #endregion */


    const [height, setHeight] = useState('auto');
    const [refresh, setRefresh] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [searchHeight, setSearchHeight] = useState(0);

    const [showuserDetails, setShowuserDetails] = useState(false)
    const [visibleIndex, setVisibleIndex] = useState(-1)

    const [profile, setProfile] = useState([])
    const [profileId, setProfileId] = useState()
    const [accountsAreEnabled, setAccountsAreEnabled] = useState([]) //Data List
    const [accountsAreDisabled, setAccountsAreDisabled] = useState([]) //Data List
    const [dataLoad, setDataLoad] = useState(false)
    const navigate = useNavigate()

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'emp-data'
    });

    const iconStyles = {
        marginRight: '5px',
        color: Utils.border
    }
    const [msg, setMsg] = useState('')
    const onSubmitHandler = (e) => {
        e.preventDefault()
        setShowLoader(true)
        var UsersDTO = {
            id: id, name: name, surname: surname, username: username, password: '123', account_category_id: account_category_id, devuser: devUser
        }
        if (!id && id !== undefined) {
            console.log('SAving the user: ' + id)
            Commons.saveAccount(UsersDTO).then((res) => {
                console.log('Added a single user successfully')
                setName('')
                setSurname('')
                setShowLoader(false)
                setHeight(0)
                setRefresh(!refresh)
                navigate('/datasucessfulysaved')
            }).catch((err) => {
                setMsg(Utils.errorLogic(err))
                setShowLoader(false)
            })
        } else {
            console.log('Updating the user')
            console.log('The user id: ' + id + ' \n The profileId: ' + profileId + ' \n The account_categoryid: ' + account_category_id)
            Commons.UpdateAccount(id, profileId, account_category_id, UsersDTO).then((res) => {
                setName('')
                setSurname('')
                setShowAlert(true)
                setHeight(0)
                setRefresh(!refresh)
            }).catch((err) => {
                setMsg(Utils.errorLogic(err))
                setShowLoader(false)
            })
        }
    }
    const getAllAccounts = () => {
        Repository.findAccount().then((res) => {
            setUsers(res.data)
        })
    }
    const getcategories = () => {
        Repository.findCategoriesdata().then((res) => {
            setCategories(res.data)
        })
    }
    const getAccountsAreEnabled = () => {
        Repository.findAccountByStatus('enabled').then((res) => {
            setAccountsAreEnabled(res.data);
            setDataLoad(true)
        });
    }
    const getAccountsAreDisenabled = () => {
        Repository.findAccountByStatus('disabled').then((res) => {
            setAccountsAreDisabled(res.data);
            setDataLoad(true)
        });
    }
    useEffect(() => {
        Utils.PageLoadeDefaults()
        document.body.classList.add('darkBody')
        document.body.classList.add('user-registration')

    }, [refresh])

    const clearHandle = () => {
        setName('')
        setUsername('')
        setEmail('')
        setId(null)
        setClearBtn(false)
        setHeight(0)
    }

    const getUserById = (id) => {
        Repository.findAccountById(id).then((res) => {

            setId(res.data.id)
            setName(res.data.mdl_profile.name)
            setSurname(res.data.mdl_profile.surname)
            setUsername(res.data.username)
            // setPassword(res.data.password)
            setAccount_category_id(res.data.mdl_account_category.id)
            setProfileId(res.data.mdl_profile.id)
            setEmail(res.data.mdl_profile.email)
            setClearBtn(true)
            showheight('auto')
        })
    }

    const showMore = (e, index) => {
        setVisibleIndex(index)
        setShowuserDetails(!showuserDetails)
    }


    function showheight(type) {
        setHeight(type)
    }
    const delAccountById = (id) => {
        Utils.Submit(() => {
            // Delete.deleteAccountById(id, () => { getAllAccounts() })
        }, () => { })
    }
    const DisableUserHandler = (id) => {
        Utils.Submit(() => {
            Repository.disableUser('disabled', id).then(res => {
                getAllAccounts()
                getAccountsAreEnabled()
                getAccountsAreDisenabled()
            })
        }, () => { })
    }
    const enabledHandler = (id) => {
        Utils.Submit(() => {
            Repository.disableUser('enabled', id).then(res => {
                getAllAccounts()
                getAccountsAreEnabled()
                getAccountsAreDisenabled()
            })
        }, () => { })
    }

    const nameTextChange = (e) => {
        setMsg('')
        setName(e.target.value)
    }
    const usernameTextChange = (e) => {
        setMsg('')
        setUsername(e.target.value)
    }
    return (
        <>
            <Container fluid className='servicesprovidedBg'>
                <Row className='p-4 gx-4'>

                    <p className='my-3 '>
                        <h1 className='intoHeader'>Registration</h1>
                    </p>
                    <p className='mb-3 mt-3 intro'>
                        Fill the form below to get more udpates from out IT Solutions
                    </p>
                </Row>

            </Container>
            <Container fluid className='userRegistrationWrapper'>

                <Row>
                    <Col md={6} className='imagePart'>
                         

                    </Col>
                    <Col md={6} className='formWrapper'>
                        <AnimateHeight id="animForm" duration={300} animateOpacity={true} height={height}>
                            {msg && <Container>
                                <Row className='d-flex justify-content-center'>
                                    <Col md={11}>
                                        <Alert className="alert alert-danger">{msg} </Alert>
                                    </Col>
                                </Row>
                            </Container>
                            }
                            <ContainerRowWelcome clearBtn={clearBtn} form='Get More of our Services' showLoader={showLoader}  >
                                <ClearBtnSaveStatus height={height} showLoader={showLoader} showAlert={showAlert} />

                                <FormInnerRightPane onSubmitHandler={onSubmitHandler}>
                                    <DropDownInput label1="category" name="User Type" handle={(e) => setDevUser(e.target.value)}>
                                        <option selected={devUser === 'dev'} value="dev"  >I am a developer</option>
                                        <option selected={devUser === 'user'} value='user'  >I am not a developer</option>
                                    </DropDownInput>


                                    <InputRow label1='name' name='Name' placeholder='Your First Name' handle={(e) => nameTextChange(e)} val={name} />
                                    <InputRow label1='surname' name='Surname' placeholder='Your Second Name' handle={(e) => setSurname(e.target.value)} val={surname} />


                                    <InputRow label1='username' name='Username' placeholder='Pick a username' handle={(e) => usernameTextChange(e)} val={username} />
                                    <InputRow label1='email' name='Email' placeholder='Enter your Email' handle={(e) => setEmail(e.target.value)} val={email} />

                                    <SaveUpdateBtns blueColor='true' clearBtn={clearBtn} clearHandle={clearHandle} saveOrUpdate={FormTools.BtnTxt(clearBtn)} />
                                </FormInnerRightPane>
                            </ContainerRowWelcome>
                        </AnimateHeight>

                    </Col>
                </Row>




            </Container>
<Footer/>
        </>
    )
}

export default PubUserRegistration