import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import systemInOneDay from '../imgz/home/System_in_a_day.jpg'
import Utils from '../Global/Utils'
import { useNavigate } from 'react-router-dom'
import { Slide } from 'react-reveal'
import { motion } from "framer-motion"
function SystemInADay() {

    const navigate = useNavigate()
    const gotContactus = () => {
        navigate("/contactus");
    }
    return (

        <Container className='systemInADay' fluid  >
            <Row className='d-flex justify-content-between' style={{ backgroundColor: Utils.border, padding: '90px' }}>
               

                <Col md={8} xs={12} id='title'  >
                    <Slide left>   Let's provide a solution to your business </Slide>
                </Col>

                <Col md={3} xs={12} className='d-flex justify-content-end' >
                    <Slide top>
                        <button onClick={() => gotContactus()} className='darkButton '>Contact us</button>
                    </Slide>
                </Col>
            </Row>
        </Container>
    )
}

export default SystemInADay