import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Slide } from 'react-reveal'
import Icon, { withBaseIcon } from 'react-icons-kit'
import { ic_verified_outline as confirmed } from 'react-icons-kit/md/ic_verified_outline'
import { color } from 'framer-motion'
import { Margin } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import BizInterests from '../home/BizInterests'
import '../Styles/Gothic.css'

import bizladyLaugh from '../../../src/components/imgz/home/ladylaughingshort_two.png'
function DataSucessfulySaved() {

    useEffect(()=>{
        document.body.className = '';
        document.body.style.backgroundColor = 'white';
        window.scrollTo(0, 0)
    },[])
    const navigate=useNavigate()
    const iconStyle = {
        color: 'green',
        margin: 'auto 2rem'
    }

    const gotoHome=()=>{
        navigate("/")
    }
    return (
        <>
            <Container>
                <Row className=' d-flex justify-content-center align-items-center' style={{ height: '40vh' }}>
                    <Slide top>
                        <Col className=' p-4 shadow-lg savedmsg'>

                            <h2 className="text-center">
                                <Icon style={iconStyle} className='icon mb-1' size={98} icon={confirmed} />
                                Your information have been received</h2>

                        </Col>
                    </Slide>
                </Row>
               

            </Container>
            <Container fluid>
                <BizInterests image={bizladyLaugh} />
            </Container>
        </>
    )
}

export default DataSucessfulySaved