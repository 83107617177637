import React, { useState } from 'react'
import Icon from 'react-icons-kit'


import { statsBars as stock } from 'react-icons-kit/icomoon/statsBars'
import { manWoman as clients } from 'react-icons-kit/icomoon/manWoman'
import { heart as wedding } from 'react-icons-kit/icomoon/heart'
import { Fade, Slide } from 'react-reveal'
import ModalPopup from 'react-modal-popup'
import { Col, Container, Row } from 'react-bootstrap'
import Utils from '../Global/Utils'




function Solutions() {/*Icon styles*/
    const ic_s = {
        color: "#2ccdf5", display: "block", textAlign: "center", marginBottom: "30px"
    }
    const [isOpen, setIsOpen] = useState(false);

    const handleShowModal = () => setIsOpen(!isOpen);

    return (
        <>
            {/* <ModalPopup   isOpen={isOpen} onCloseModal={handleShowModal}>
                <h1>STOCK MANAGEMENT SOLUTION</h1>
                <div>
                    You can manage
                </div>
            </ModalPopup> */}




            {/* -----------------------------------------BiG Title ----------------------------------------- */}




            <Container fluid className="dev pt-5   pb-5 border   bigTitle  " style={{ backgroundColor: Utils.skinBg1() }}  >
                <Row className="mt-4 px-5   partneranim_bg d-flex justify-content-between  ">
                    <Col md={10} className='mx-auto '>
                        <h1 style={{ fontSize: '50px' }} className="mb-0 pb-0 text-center " >Featured Solutions  </h1>
                        <p className="p-0 w-50 w-sm-100 mx-auto text-center my-4 ps-5">Our Solutions</p>
                    </Col>
                </Row>
            </Container>


            {/* -----------------------------------------  3 HORIZONTAL CARDS  ----------------------------------------- */}

            <div className='horizontalCards container-fluid   ' style={{ backgroundColor: Utils.skinBg1() }}>
                <div className='row mx-sm-auto' style={{ backgroundColor: '#fff' }}>

                    <div className='col-12 cardWrapper  ' style={{ padding: '70px' }}>
                        <div className='row mx-sm-auto    d-flex justify-content-between '>
                            <Slide bottom >
                                <div className='col-md-3 col-sm-12 shadow card   IconTitle' onClick={handleShowModal} style={{ border: '1px solid #94dfff' }}  >
                                    <div className='row m-0 p-2 item  '>
                                        <Icon size={62} style={ic_s} icon={stock} />
                                        <span>Inventory Management system </span>
                                        <p>Find a solution for your assets and equipments</p>
                                    </div>
                                </div>
                            </Slide>
                            <Slide bottom >
                                <div className='col-md-3 shadow card   IconTitle' style={{ border: '1px solid #94dfff' }}>
                                    <div className='row mx-sm-auto m-0 p-2 item  '>
                                        <Icon size={62} style={ic_s} icon={clients} />
                                        <span>Client Booster </span>
                                        <p>Boost your cientele just in a minute</p>
                                    </div>
                                </div>

                            </Slide>
                            <Slide bottom>
                                <div className='col-md-3 shadow  card  IconTitle' style={{ border: '1px solid #94dfff' }}>
                                    <div className='row mx-sm-auto m-0 p-2 item'>
                                        <Icon size={62} style={ic_s} icon={wedding} /> <span>
                                            Wedding planner </span>
                                        <p>Do you need to budget, find wedding gears and more? find a nice solution</p>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}

export default Solutions