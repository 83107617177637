import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Container, ListGroup, Nav, Row, Tab } from 'react-bootstrap';
// import { Helmet } from 'react-helmet';

import './docker.css'
// import Footer from '../../footer/Footer';
import { Icon } from 'react-icons-kit'
import { ic_code as code } from 'react-icons-kit/md/ic_code'

function DockerContent() {
   
    /* #region  REferences on click */
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);
    const textRef3 = useRef(null);
    const textRef4 = useRef(null);
    const textRef5 = useRef(null);

    const textRef6 = useRef(null);
    const textRef7 = useRef(null);
    const textRef8 = useRef(null);
    const textRef9 = useRef(null);
    const textRef10 = useRef(null);
    const textRef11 = useRef(null);
    const textRef12 = useRef(null);
    const textRef13 = useRef(null);
    const textRef14 = useRef(null);
    const textRef15 = useRef(null);
    
    /* #endregion */
    
    const [isVisible, setIsVisible] = useState(false);
    const [copyText, setCopyText] = useState('Copy')
    const copyToClipboard = (ref) => {
        if (ref.current) {
            const text = ref.current.innerText;
            navigator.clipboard.writeText(text).then(() => {
                setIsVisible(true);
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        }

    }
    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                setIsVisible(false);
                setCopyText('Copied')
            }, 5000);

            // Clean up the timer when the component unmounts or when isVisible changes
            return () => clearTimeout(timer);
        } else {
            setCopyText('Copy')
        }
    }, [isVisible])


    return (
        <>
            <h1>Installing Docker On Linux 20.04</h1>
            <h2>What is docker</h2>
            <p>
                Docker is a platform for developing, shipping, and running applications. It uses containerization technology to package an application and its dependencies into a standardized unit called a container. These containers are lightweight, portable, and isolated environments that ensure consistent behavior across different computing environments, such as development, testing, and production.
            </p>
            <p>Docker allows developers to build, ship, and run applications quickly and efficiently, regardless of the underlying infrastructure. It provides tools and services for creating, managing, and deploying containers, making it easier to develop, test, and deploy software in a fast and reproducible manner.</p>
            <p>In summary, Docker is a software platform that enables developers to package and deploy applications in containers, providing a consistent and efficient way to build, ship, and run software across different environments.</p>

            <h2>Step 1 — Installing Docker</h2>


            <p>The Docker installation package available in the official Ubuntu repository may not be the latest version. To ensure we get the latest version, we’ll install Docker from the official Docker repository. To do that, we’ll add a new package source, add the GPG key from Docker to ensure the downloads are valid, and then install the package.
                First, update your existing list of packages:</p>

            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef1}>
                    <span className='color1'>sudo apt </span>
                    <span className='colorWhite'>update</span>
                </span>
                <button onClick={() => copyToClipboard(textRef1)}>{copyText}</button>
            </p>
            <p>Next, install a few prerequisite packages which let apt use packages over HTTPS:</p>

            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef2}>
                    <span className='color1'>sudo apt install </span>
                    <span className='colorWhite'>apt-transport-https ca-certificates</span>
                    <span className='color1'>curl</span>
                    <span className='colorWhite'>software-properties-common</span>
                </span>
                <button onClick={() => copyToClipboard(textRef2)}>Copy</button>
            </p>
            <p>Then add the GPG key for the official Docker repository to your system:</p>
            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef3}>
                    <span className='color1'>curl </span>
                    <span className='colorWhite'>-fsSL https://download.docker.com/linux/ubuntu/gpg |</span>
                    <span className='color1'>sudo </span>
                    <span className='colorWhite'>apt-key</span>
                    <span className='colorWhite'>|</span>
                    <span className='color1'>add -|</span>
                </span>
                <button onClick={() => copyToClipboard(textRef3)}>Copy</button>
            </p>
            <p>Add the Docker repository to APT sources:</p>
            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef4}>   <span className='color1'>sudo </span>
                    <span className='colorWhite'>add-apt-repository</span>
                    <span className='color2'>"deb [arch=amd64] https://download.docker.com/linux/ubuntu focal stable" </span>
                    <span className='colorWhite'>apt-key</span>
                    <span className='colorWhite'>|</span>
                    <span className='color1'>add -|</span>
                </span>
                <button onClick={() => copyToClipboard(textRef4)}>Copy</button>
            </p>

            <p>This will also update our package database with the Docker packages from the newly added repo.

                Make sure you are about to install from the Docker repo instead of the default Ubuntu repo:</p>

            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef5}>
                    <span className='color1'>apt-cache  </span>
                    <span className='colorWhite'>policy docker-ce</span>
                </span>
                <button onClick={() => copyToClipboard(textRef5)}>Copy</button>

            </p>
            <p>You’ll see output like this, although the version number for Docker may be different:</p>


            <Card className='codeCard'>
                <Card.Header className='codeTitle'>Output of apt-cache policy docker-ce</Card.Header>
                <Card.Body>
                    <Card.Text className='codeRes'>
                        <span className='levl1'> docker-ce:</span>
                        <span className='levl2'> Installed: (none)</span>
                        <span className='levl2'> Candidate: 5:19.03.9~3-0~ubuntu-focal</span>
                        <span className='levl2'>Version table:</span>
                        <span className='levl3'> 5:19.03.9~3-0~ubuntu-focal 500</span>
                        <span className='levl4'> 500 https://download.docker.com/linux/ubuntu focal/stable amd64 Packages</span>
                    </Card.Text>

                </Card.Body>
            </Card>
            <p>
                Notice that docker-ce is not installed, but the candidate for installation is from the Docker repository for Ubuntu 20.04 (focal).

            </p>
            <p>
                Finally, install Docker:
            </p>


            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef6}>
                    <span className='color1'>sudo apt install  </span>
                    <span className='colorWhite'>docker-ce</span>
                </span>
                <button onClick={() => copyToClipboard(textRef6)}>Copy</button>

            </p>
            <p>Docker should now be installed, the daemon started, and the process enabled to start on boot. Check that it’s running:</p>

 
            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef7}>
                    <span className='color1'>sudo  </span>
                    <span className='colorWhite'>systemctl status</span>
                    <span className='color1'>  docker  </span>
                </span>
                <button onClick={() => copyToClipboard(textRef7)}>Copy</button>

            </p>
            <p>The output should be similar to the following, showing that the service is active and running:</p>


            <Card className='codeCard'>
                <Card.Header className='codeTitle'>Output</Card.Header>
                <Card.Body>
                    <Card.Text className='codeRes'>
                        <span className='levl1'> docker.service - Docker Application Container Engine</span>
                        <span className='levl3'> Loaded: loaded (/lib/systemd/system/docker.service; enabled; vendor preset: enabled)</span>
                        <span className='levl3'>  Active: active (running) since Tue 2020-05-19 17:00:41 UTC; 17s ago</span>
                        <span className='levl1'>TriggeredBy: ● docker.socket</span>
                        <span className='levl4'>  Docs: https://docs.docker.com</span>
                        <span className='levl3'>  Main PID: 24321 (dockerd)</span>
                        <span className='levl2'> Tasks: 8</span>
                        <span className='levl2'>   Memory: 46.4M</span>
                        <span className='levl5'> CGroup: /system.slice/docker.service
                            <br />
                            24321 /usr/bin/dockerd -H fd:// --containerd=/run/containerd/containerd.sock

                        </span>
                    </Card.Text>

                </Card.Body>
            </Card>


            <p>
                Installing Docker now gives you not just the Docker service (daemon) but also the docker command line utility, or the Docker client. We’ll explore how to use the docker command later in this tutorial.
            </p>
            <h1>
                Step 2 — Executing the Docker Command Without Sudo (Optional)
            </h1>
            <p>
                By default, the docker command can only be run the root user or by a user in the docker group, which is automatically created during Docker’s installation process. If you attempt to run the docker command without prefixing it with sudo or without being in the docker group, you’ll get an output like this:
            </p>

            <Card className='codeCard'>
                <Card.Header className='codeTitle'>Output</Card.Header>
                <Card.Body>
                    <Card.Text className='codeRes'>
                        <span className='levl1'>
                            docker: Cannot connect to the Docker daemon. Is the docker daemon running on this host?.
                            See 'docker run --help'.
                        </span>

                    </Card.Text>

                </Card.Body>
            </Card>

            <p>
                If you want to avoid typing sudo whenever you run the docker command, add your username to the docker group:
            </p>
            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef8}>
                    <span className='color2'>sudo usermod  </span>
                    <span className='colorWhite'>-aG</span>
                    <span className='color2'>docker   </span>
                    <span className='colorWhite'>  $"{"USER"}"  </span>
                </span>
                <button onClick={() => copyToClipboard(textRef8)}>Copy</button>

            </p>
            <p>
                To apply the new group membership, log out of the server and back in, or type the following:
            </p>

            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef9}>
                    <span className='color2'>su  </span>
                    <span className='colorWhite'>-</span>
                    <span className='colorWhite'>  $"{"USER"}"  </span>
                </span>
                <button onClick={() => copyToClipboard(textRef9)}>Copy</button>

            </p>
            <p>
                You will be prompted to enter your user’s password to continue
            </p>
            <p>
                Confirm that your user is now added to the docker group by typing:
            </p>
            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef10}>
                    <span className='color2'>groups  </span>
                </span>
                <button onClick={() => copyToClipboard(textRef10)}>Copy</button>
            </p>
            <Card className='codeCard'>
                <Card.Header className='codeTitle'>Output</Card.Header>
                <Card.Body>
                    <Card.Text className='codeRes'>
                        <span className='levl1'>
                            sammy sudo docker
                        </span>

                    </Card.Text>

                </Card.Body>
            </Card>
            <p>
                If you need to add a user to the docker group that you’re not logged in as, declare that username explicitly using:
            </p>

            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef11}>
                    <span className='color2'>sudo usermod  </span>
                    <span className='colorWhite'>-aG</span>
                    <span className='color2'>docker   </span>
                    <span className='color2'>username   </span>

                </span>
                <button onClick={() => copyToClipboard(textRef12)}>Copy</button>
            </p>
            <p>
                The rest of this article assumes you are running the docker command as a user in the docker group. If you choose not to, please prepend the commands with sudo.
            </p>
            <p>
                Let’s explore the docker command next.
            </p>
            <h1>Step 3 — Using the Docker Command</h1>
            <p>
                Using docker consists of passing it a chain of options and commands followed by arguments. The syntax takes this form:
            </p>

            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef12}>
                    <span className='color2'>docker  </span>
                    <span className='colorWhite'>[option] </span>
                    <span className='colorWhite'> [command]   </span>
                    <span className='colorWhite'>[arguments]   </span>

                </span>
                <button onClick={() => copyToClipboard(textRef13)}>Copy</button>
            </p>
            <p>
                To view all available subcommands, type:
            </p>


            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef5}>
                    <span className='color2'>docker  </span>
                </span>
                <button onClick={() => copyToClipboard(textRef5)}>Copy</button>
            </p>
            <p>As of Docker 19, the complete list of available subcommands includes:</p>
            <Card className='codeCard'>
                <Card.Header className='codeTitle'>Output</Card.Header>
                <Card.Body>
                    <Card.Text className='codeRes'>
                        <table className='tablea'>
                            <tr>
                                <td className="secondTd">attach</td>  <td className="secondTd">Attach local standard input, output, and error streams to a running container</td>
                            </tr>
                            <tr>
                                <td className="secondTd">build       </td>  <td className="secondTd">Build an image from a Dockerfile</td>
                            </tr>
                            <tr>
                                <td className="secondTd">commit</td>  <td className="secondTd">Create a new image from a container's changes</td>
                            </tr>
                            <tr>
                                <td className="secondTd">cp </td>  <td className="secondTd">Copy files/folders between a container and the local filesystem</td>
                            </tr>
                            <tr>
                                <td className="secondTd">create </td>  <td className="secondTd">Copy files/folders between a container and the local filesystem</td>
                            </tr>
                            <tr>
                                <td className="secondTd">create </td>  <td className="secondTd">Create a new container</td>
                            </tr>
                            <tr>
                                <td className="secondTd">diff </td>  <td className="secondTd">Inspect changes to files or directories on a container's filesystem</td>
                            </tr>
                            <tr>
                                <td className="secondTd">event </td>  <td className="secondTd">Get real time events from the server</td>
                            </tr>
                            <tr>
                                <td className="secondTd">exec </td>  <td className="secondTd">Run a command in a running container</td>
                            </tr>
                            <tr>
                                <td className="secondTd">export </td>  <td className="secondTd">Export a container's filesystem as a tar archive</td>
                            </tr>
                            <tr>
                                <td className="secondTd">history </td>  <td className="secondTd">Show the history of an image</td>
                            </tr>
                            <tr>
                                <td className="secondTd">images </td>  <td className="secondTd">List images</td>
                            </tr>
                            <tr>
                                <td className="secondTd">import </td>  <td className="secondTd">Import the contents from a tarball to create a filesystem image</td>
                            </tr>
                            <tr>
                                <td className="secondTd">info </td>  <td className="secondTd">Display system-wide information</td>
                            </tr>
                            <tr>
                                <td className="secondTd">inspect </td>  <td className="secondTd">Return low-level information on Docker objects</td>
                            </tr>
                            <tr>
                                <td className="secondTd">kill </td>  <td className="secondTd">Kill one or more running containers</td>
                            </tr>
                            <tr>
                                <td className="secondTd">load </td>  <td className="secondTd">Load an image from a tar archive or STDIN</td>
                            </tr>
                            <tr>
                                <td className="secondTd">login </td>  <td className="secondTd">Log in to a Docker registry</td>
                            </tr>
                            <tr>
                                <td className="secondTd">logout </td>  <td className="secondTd">Log out from a Docker registry</td>
                            </tr>
                            <tr>
                                <td className="secondTd">logs</td>  <td className="secondTd">Fetch the logs of a container</td>
                            </tr>
                            <tr>
                                <td className="secondTd">pause</td>  <td className="secondTd">Pause all processes within one or more containers</td>
                            </tr>
                            <tr>
                                <td className="secondTd">port</td>  <td className="secondTd">List port mappings or a specific mapping for the container</td>
                            </tr>
                            <tr>
                                <td className="secondTd">ps</td>  <td className="secondTd">List containers</td>
                            </tr>
                            <tr>
                                <td className="secondTd">pull</td>  <td className="secondTd">Pull an image or a repository from a registry</td>
                            </tr>
                            <tr>
                                <td className="secondTd">push</td>  <td className="secondTd">Push an image or a repository to a registry</td>
                            </tr>
                            <tr>
                                <td className="secondTd">rename</td>  <td className="secondTd">Rename a container</td>
                            </tr>
                            <tr>
                                <td className="secondTd">restart</td>  <td className="secondTd">Restart one or more containers</td>
                            </tr>
                            <tr>
                                <td className="secondTd">rm</td>  <td className="secondTd">Remove one or more containers</td>
                            </tr>
                            <tr>
                                <td className="secondTd">rmi</td>  <td className="secondTd">Remove one or more images</td>
                            </tr>
                            <tr>
                                <td className="secondTd">run</td>  <td className="secondTd">Run a command in a new container</td>
                            </tr>
                            <tr>
                                <td className="secondTd">save</td>  <td className="secondTd">Save one or more images to a tar archive (streamed to STDOUT by default)</td>
                            </tr>
                            <tr>
                                <td className="secondTd">search</td>  <td className="secondTd">Search the Docker Hub for images</td>
                            </tr>
                            <tr>
                                <td className="secondTd">start</td>  <td className="secondTd">Display a live stream of container(s) resource usage statistics</td>
                            </tr>
                            <tr>
                                <td className="secondTd">stop</td>  <td className="secondTd">Stop one or more running containers</td>
                            </tr>
                            <tr>
                                <td className="secondTd">tag</td>  <td className="secondTd">Create a tag TARGET_IMAGE that refers to SOURCE_IMAGE  </td>
                            </tr>
                            <tr>
                                <td className="secondTd">top</td>  <td className="secondTd">Display the running processes of a container </td>
                            </tr>
                            <tr>
                                <td className="secondTd">unpause</td>  <td className="secondTd">Unpause all processes within one or more containers </td>
                            </tr>
                            <tr>
                                <td className="secondTd">update</td>  <td className="secondTd">Update configuration of one or more containers </td>
                            </tr>
                            <tr>
                                <td className="secondTd">version</td>  <td className="secondTd">Show the Docker version information</td>
                            </tr>
                            <tr>
                                <td className="secondTd">wait</td>  <td className="secondTd">Block until one or more containers stop, then print their exit codes</td>
                            </tr>

                        </table>

                    </Card.Text>

                </Card.Body>
            </Card>

            <p>
                To view the options available to a specific command, type:
            </p>
            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef14}>
                    <span className='color1'>docker  </span>
                    <span className='colorWhite'>docker-subcommand  </span>
                    <span className='color2'>--help  </span>
                </span>
                <button onClick={() => copyToClipboard(textRef14)}>Copy</button>
            </p>

            <p>
                To view system-wide information about Docker, use:
            </p>

            <p className='code'>
                <span className='notSelect'>$</span>
                <span ref={textRef15}>
                    <span className='color1'>docker  </span>
                    <span className='colorWhite'>info  </span>

                </span>
                <button onClick={() => copyToClipboard(textRef15)}>Copy</button>
            </p>

        </>
    )
}

export default DockerContent