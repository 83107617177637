import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Gurumaster.css'
import Utils from '../Utils'
import Repository from '../../../Services/GithubRead/Repository'
import PostRequests from '../../../Services/GithubRead/PostRequests'
import { GlobalContext } from '../GlobalContext'
function GuruMaster() {
    const { globalState, setGlobalState } = useContext(GlobalContext);
   
    return (
        <Container className=' gurumasterBox' style={{ border: ` 1px solid  #00b7ff ` }}>
            <Row className='d-flex justify-content-center' >
                <Col>Are all apps running</Col>
            </Row>
            <Row className='d-flex justify-content-start p-1'>
                <Col md={4}>
                   Deloyment
                </Col>
                <Col md={4}>
                   Master
                </Col>
                <Col md={4}>
                   GithubRead
                </Col>
                <Col className='border col-auto' >  {globalState.singlePortisRunningResponse} </Col>
            </Row>
        </Container>
    )
}

export default GuruMaster