import React, { useEffect } from 'react'
import Utils from '../Global/Utils'
import { Col, Container, Row } from 'react-bootstrap'
import './Services.css'
import Footer from '../Global/footer/Footer'
import { Helmet } from 'react-helmet'

import servicePartOne from '../imgz/servicePartOne.png'

import javaimg from '../imgz/services.PNG'
import SystemInADay from '../home/SystemInADay'
function Services() {

    useEffect(() => {
        Utils.PageLoadeDefaults()

    }, [])
    return (
        <>
            <Helmet>
                <title>Technology - CODEGURU Systems</title>
                <meta name="description" content="Software Development Company in Rwanda" />
                <meta name="description" content="Comprehensive website services including design, UX, UI, responsive design, branding, front-end and back-end development, CMS, e-commerce solutions, SEO, content creation, multimedia production, localization, performance optimization, security, compliance, domain management, web hosting, maintenance, support, social media integration, email marketing, PPC advertising, digital marketing, custom web applications, API integration, user training, and documentation." />
                <meta name="keywords" content="Website Design, User Experience, UX, UI Design, Responsive Design, Branding, Front-End Development, Back-End Development, Content Management System, CMS, E-commerce Solutions, Copywriting, Multimedia Production, Localization, SEO, On-Page SEO, Technical SEO, Keyword Research, Analytics, Reporting, Performance Optimization, Security Measures, Compliance, Domain Management, Web Hosting, Maintenance, Support, Social Media Integration, Email Marketing, PPC Advertising, Digital Marketing, Custom Web Applications, API Integration, User Training, Documentation" />

            </Helmet>
            <Container fluid className='servicesprovidedBg'>
                <Row className='p-4 gx-4'>
                    <p className='my-3'>
                        <h1 >IT Services we provide</h1>
                    </p>
                    <p className='mb-3 mt-3 intro'>
                        We offer various services from small  to large businesses
                    </p>
                    <Col md={4}><div className="gutter">
                        <h4>Webisite & System Development</h4>
                        <p>
                            <ul>
                                <li><b>Building the Website:</b> We'll build the website using the latest technology.</li>
                                <li><b>Easy Updates:</b> We'll set up a system so you can easily update the content on your website.</li>
                                <li><b>Online Store:</b> If you need an online store, we'll set that up for you too.</li>
                            </ul>
                        </p>
                    </div> </Col>
                    <Col md={4}><div className="gutter">
                        <h4>Consultation and Planning</h4>
                        <p>
                            <ul>
                                <li><b>Understanding Needs:</b> We'll talk to you to understand your business, your goals, and what you want from your website.</li>
                                <li><b>Planning:</b> We'll create a detailed plan with timelines and milestones to keep everything on track.</li>
                                <li><b>Budgeting: </b>We'll provide a clear estimate of the costs involved.</li>
                            </ul>
                        </p>
                    </div> </Col>
                    <Col md={4}><div className="gutter">
                        <h4>Design and User Experience (UX)</h4>
                        <p>
                            <ul>
                                <li><b>UI/UX Design:</b>Crafting visually appealing and user-friendly designs.</li>
                                <li><b>Wireframing and Prototyping:</b> Creating wireframes and interactive prototypes to visualize the site structure and user flow.</li>
                                <li><b>Responsive Design: </b>Ensuring the website is fully responsive and works well on all devices (desktops, tablets, smartphones).</li>
                                <li><b>Branding: </b>Incorporating the company’s branding elements (logos, colors, fonts) into the design.</li>
                            </ul>
                        </p>
                    </div> </Col>


                    <img src={servicePartOne} />
                    <Col md={4}><div className="gutter">
                        <h4>Content Creation</h4>
                        <p>
                            <ul>
                                <li><b>Copywriting:</b>Crafting compelling and SEO-friendly content for the website.</li>
                                <li><b>Wireframing and Prototyping:</b>  Creating or sourcing high-quality images, videos, and other multimedia content.</li>
                                <li><b>Localization:   </b>Translating and adapting content for different languages and regions if necessary.</li>
                            </ul>
                        </p>
                    </div> </Col>
                    <Col md={4}><div className="gutter">
                        <h4> Search Engine Optimization (SEO)</h4>
                        <p>
                            <ul>
                                <li><b>On-Page SEO:</b> Optimizing content, meta tags, images, and internal linking for search engines.</li>
                                <li><b>Technical SEO:</b>  Ensuring the website has a clean code structure, fast loading times, and is mobile-friendly.</li>
                                <li><b>Keyword Research:   </b> Identifying the best keywords to target for improved search engine rankings.</li>
                                <li><b>Analytics and Reporting:    </b>Setting up tools like Google Analytics to monitor and report on website performance.</li>
                            </ul>
                        </p>
                    </div> </Col>
                    <Col md={4}><div className="gutter">
                        <h4> Performance and Security</h4>
                        <p>
                            <ul>
                                <li><b>Performance Optimization:</b> We'll ensure your website loads quickly.</li>
                                <li><b>Security Measures:</b>   We'll implement security measures to protect your website from threats.</li>
                                <li><b>Legal Compliance:   </b> We'll ensure your website complies with relevant laws and regulations.</li>
                            </ul>
                        </p>
                    </div> </Col>
                </Row>

                <Row className='interruptor p-5 d-none'  >
                    <Col md={4}>
                        <img src={javaimg} width="340" />
                    </Col>
                    <Col md={8} >
                        <h2 className='title'>
                            Get Your micro services deployed fast and easily
                        </h2>
                        <p>We offer easy Java deployment services through our proprietary tool, JavaDeployEase, designed to simplify and automate the entire deployment process</p>
                    </Col>
                </Row>





                <Row className='p-4 gx-4'>
                    <Col md={4}><div className="gutter">
                        <h4> Hosting and Domain Management</h4>
                        <p>
                            <ul>
                                <li><b>Domain Management:</b> We'll help you register and manage your domain name.</li>
                                <li><b>Reliable Hosting:</b>   We'll provide reliable hosting solutions to keep your website running smoothly.</li>
                                <li><b>Ongoing Maintenance: </b> We'll offer ongoing support, including regular backups and updates.</li>
                            </ul>
                        </p>
                    </div> </Col>
                    <Col md={4}><div className="gutter">
                        <h4> Digital Marketing and Social Media Integration</h4>
                        <p>
                            <ul>
                                <li><b>Social Media Links: </b> We'll integrate your social media accounts to promote engagement.</li>
                                <li><b>Email Campaigns: </b>  We'll set up and manage email marketing campaigns.</li>
                                <li><b>Online Advertising:  </b> We'll manage online advertising campaigns to drive traffic to your website.</li>
                            </ul>
                        </p>
                    </div> </Col>
                    <Col md={4}><div className="gutter">
                        <h4> Analytics and Reporting</h4>
                        <p>
                            <ul>
                                <li><b>Performance Monitoring:  </b> We'll set up tools to track your website’s performance.</li>
                                <li><b>Regular Reports:  </b>  We'll provide regular reports on how your website is doing.</li>
                                <li><b>Actionable Insights:  </b> We'll use data to provide insights and recommendations for improvement.</li>
                            </ul>
                        </p>
                    </div> </Col>
                    <Col md={4}><div className="gutter">
                        <h4> Training and Documentation</h4>
                        <p>
                            <ul>
                                <li><b>User Training: </b> We'll train your staff on how to use and update the website.</li>
                                <li><b>Documentation:   </b>  We'll provide comprehensive guides and manuals.</li>
                            </ul>
                        </p>
                    </div> </Col>
                    <Col md={4}><div className="gutter">
                        <h4> Custom Solutions</h4>
                        <p>
                            <ul>
                                <li><b>Tailored Web Applications: </b> We'll develop custom applications to meet your specific needs.</li>
                                <li><b>Third-Party Integrations:   </b>  We'll integrate your website with other services and tools you use.</li>
                            </ul>
                        </p>
                    </div> </Col>
                </Row>
            </Container >
            <SystemInADay />
            <Footer />
        </>
    )
}

export default Services