import React, { useEffect } from 'react'
// import cash from '../../images/home/devSection/cash.json'
import cash from '../imgz/home/devSection/cash.json'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Lottie from 'lottie-react'
import { useNavigate } from 'react-router-dom'
import { Slide } from 'react-reveal'
import Utils from '../Global/Utils'
import Icon from 'react-icons-kit'
import { institution as business } from 'react-icons-kit/fa/institution'

function BizInterests({ image }) {

    const navigate = useNavigate()
    const gotoServices = () => {
        navigate("/contactus");
    }
    return (
        <>

        
            {/* -----------------------------------------BiG Title ----------------------------------------- */}




            <Container fluid className="dev pt-5   pb-5 border   bigTitle  " style={{ backgroundColor: Utils.skinBg1() }}  >
                <Row className="mt-4 px-5   partneranim_bg d-flex justify-content-between  ">
                    <Col md={10} className='mx-auto '>
                        <h1 style={{ fontSize: '50px' }} className="mb-0 pb-0 text-center " >Revolutionize Your BUsiness  </h1>
                        <p className="p-0 w-50 w-sm-100 mx-auto text-center my-4 ps-5">With GIGAFLEX you streamline your workflow with our code generator that turns hours of coding into a few clicks</p>
                    </Col>

                </Row>
            </Container>


            {/* -----------------------------------------CONTENT WRAPPER TEXT/ CONTENT PART TEXT----------------------------------------- */}


            <Container fluid  className='bizinterest_wrapper section boldedHeader '>
                <Container fluid className=" bizinterest  " >

                    <Row className=' d-flex g-5   justify-content-between  '  >
                        <Col lg={6} md={5} sm={12} className="  contentPart  left_part">
                            <h1 style={{ marginTop: '40px' }} className=' text-center   '> business</h1>
                            <p  style={{ marginTop: '30px' }}  >  Our state-of-the-art reporting system streamlines business processes by automating data collection and analysis, enabling you to focus on growth strategies rather than data management </p>
                            <Lottie style={{ width: '200px' }} animationData={cash} />
                            <p>Our intuitive user interface and customizable dashboards provide real-time insights at your fingertips, allowing for quick decision-making and efficient resource allocation. By minimizing manual input and maximizing data accuracy, our reporting system empowers your team to drive innovation and achieve sustainable growth.</p>
                            <Button className="  mx-auto d-block btn-block" style={{ width: '70%' }} onClick={() => gotoServices()}>
                                Let start now
                            </Button>

                        </Col>
                        <Col className="  right_part pt-5" lg={5} md={5} sm={12} style={{ backgroundColor: '#fff' }} >

                            <Slide right>
                                <img width='98%' className='img-fluid mx-auto  d-block' src={image} />
                            </Slide>
                        </Col>
                    </Row>
                </Container>
            </Container>

            
            
            {/* -----------------------------------------CONTENT WRAPPER TEXT/ CONTENT PART TEXT----------------------------------------- */}


            <Container fluid className='contentPartWrapper' style={{ backgroundColor: '#d7eff4',  position: 'relative' }}>
                <Row className='section mt-5 mb-5 contentPart'   >
                    <h1  >What we Offer  </h1>
                    <p  >
                        As a dynamic software development company, we specialize in creating versatile websites and systems tailored to enhance business operations.
                        Our offerings include professionally designed websites that effectively showcase company profiles, highlighting key offerings and facilitating easy contact.
                    </p>
                    <p className='d-none'>
                        Our applications, while compact compared to full-scale Enterprise Resource Planning (ERP) systems, are robust enough to automate various business processes.
                        Although we are still expanding our toolkit to deliver ERPs more efficiently, we provide a wide array of other solutions that are adaptable to both online and
                        on-premise environments. This includes e-commerce platforms, inventory management systems, and more, catering to diverse sectors such as restaurants, hospitals, and car dealerships.
                    </p>
                    <p>
                        <button className="darkButton" onClick={() => gotoServices()} href='#'>Read more </button>
                    </p>
                </Row>
            </Container>

            {/* -----------------------------------------  CSS IMAGE BACKGROUND  ----------------------------------------- */}

            <Container fluid className=' cssImage_container ' style={{ backgroundColor: Utils.skinBg1(),  fontWeight: 'normal' }}>
                <Row className='imgSellOnline' style={{backgroundColor:'#fff'}}>
                    
                </Row>
            </Container>


        </>
    )
}

export default BizInterests