/* #region  Imports */

import React, { useState, useRef, useEffect, useContext } from 'react'
import PagesWapper from '../../Global/PagesWapper'
import { useReactToPrint } from "react-to-print"
import SessionTime from '../../../Services/SessionTime'
import axios from 'axios'
import Commons from '../../../Services/Commons'
import Repository from "../../../Services/Repository"
import VertNavBar from '../../Navbar/VertNavBar'
import AnimateHeight from 'react-animate-height'
import UpdatedComponent, { CommonStrucId } from '../../Global/HOCForm'
import { Link, Route, Routes, useParams } from 'react-router-dom';
import PrintCompanyInfo from '../../Global/PrintCompanyInfo'
import Loader, { DataListLoading } from '../../Global/Loader';
import TableHead from '../../Global/TableHead'
import SearchBox from '../../Global/SearchBox'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

import SideBar from '../../Navbar/SideBar'

// import About from '../About'

import ContainerRow, { ClearBtnSaveStatus, ContainerRowBtwb, ContainerRowBtwn, ContainerRowHalf, FormInnerRightPane, FormSidePane, SaveUpdateBtns, UnitSaveUpdateBtns } from '../../Global/ContainerRow'
import InputRow, { BtnInputRow, DropDownInput, DropDownInputTwoCols, EmptyInputRow, InputRowTupleName, LoadSub, TwoColDropdownInput, UnitsForParentFields } from '../../Global/Forms/InputRow'
import FormTools from '../../Global/Forms/PubFnx'
import ListToolBar, { ListToolBarRenamed, SearchformAnimation } from '../../Global/ListToolBar'
import ListOptioncol, { TableOpen } from '../../Global/ListTable'
import Utils from '../../Global/Utils'
import Delete from '../../../Services/Delete'
import { createContext } from 'react'
import { StructureContext } from '../../Global/DataContext'
import VerticalStructures from '../VerticalMenu/VerticalStructures'
import { Icon } from 'react-icons-kit'
import { arrowRight as arrow } from 'react-icons-kit/icomoon/arrowRight'
import { bin as delte } from 'react-icons-kit/icomoon/bin'
import { compass } from 'react-icons-kit/icomoon/compass'
import Tuple from '../Tuple'
import $ from "jquery"
import "jquery-ui-pack/jquery-ui"
import { TroubleshootRounded } from '@mui/icons-material'
import { pencil as edit } from 'react-icons-kit/icomoon/pencil'
import OtherStyles from '../../Styles/OtherStyles'
import { ic_done_outline_twotone as ok } from 'react-icons-kit/md/ic_done_outline_twotone'
import { MainSideBar } from '../menu/MainSideBar'
import './Units.css'
import { Container, Row } from 'react-bootstrap'
import PageModel from '../PageModel/PageModel'
/* #endregion */


function Unit({ children }) {
  const [id, setId] = useState(null)

  /*#region ---------- ENTITY FIELDS DECLARATIONS ---------------------------*/
  const { structureId, setStructureId } = useContext(StructureContext)
  const [name, setName] = useState()
  const [number_children, setNumber_children] = useState(0)
  const [number_parent, setNumber_parent] = useState(0)

  //The below are for the tuple
  const [unit_id, setUnit_id] = useState(0)
  const [data_type, setData_type] = useState('')
  const [category, setCategory] = useState(0)
  const [display_caption, setDisplay_caption] = useState('x')
  const [curr_date, setCurr_date] = useState('No')

  const [darkMode, setDarkMode] = useState(false)
  /*#endregion Listing data*/


  /*#region ---------- OTHER FIELDS ---------------------------*/
  const [showLoader, setShowLoader] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [structures, setStructures] = useState([]) //Data Lis tof the structures to be loaded in the dropdown list
  const [units, setUnits] = useState([]) //Data List
  const [clearBtn, setClearBtn] = useState(false) //The cancel button
  const [dataLoad, setDataLoad] = useState(false)
  const [height, setHeight] = useState(0);//this is for the units form
  const [tuplesheight, setTuplesheight] = useState(0);//this is for the tuples form, on the far right
  const [searchHeight, setSearchHeight] = useState(0);
  const [db_name, setDb_name] = useState() //this is set after selecting a db_name from the sidebar
  const [unitsBox, setUnitsBox] = useState('mt-2 col-md-7')
  const [tuplesBox, setTuplesBox] = useState('mt-2 col-md-3')
  const [formChangeableWidth, setFormChangeableWidth] = useState(11)

  const [showTuplesBox, setShowTuplesBox] = useState(false)
  const [tuples, setTuples] = useState([])
  const [clickedUnitName, setClickedUnitName] = useState([])


  const [unitNames, setUnitnames] = useState([])

  const [showmoreload, setShowmoreload] = useState(false)
  const [showParentField, setShowParentField] = useState(false)
  const [parentField_Id, setParentField_Id] = useState(0)

  const [unitIdRetainer, setUnitidRetainer] = useState(0)
  const [ToggleexpandUnit, setToggleexpandUnit] = useState(12)
  const [changable_width, setChangable_width] = useState(6)
  const [ToggleEnableUnitField, setToggleEnableUnitField] = useState(false) //this enables the name of the unit that is displayed once a user clicks on the unit. while looking for its fields


  const [finishEdit, setFinishEdit] = useState(false)
  var unitsPack = []
  var unitsPackUnique = [];

  const inputRef = useRef(null);
  /*#endregion OTHER FIELDS*/

  /*#region ---------- SAVING DATA TO DB--------------------------------------*/

  /*#region ---------------------------------- SAVING TUPLE --------------------------------------*/
  const SubmitTosaveTuple = (e) => {
    e.preventDefault()
    setShowLoader(true)
    var tuple = {
      id: id, name: name,
      data_type: data_type,
      category: category,
      display_caption: display_caption,
      curr_date: curr_date
    }
    if (id) {//update
      Commons.updateTuple(tuple, id).then((res) => {
        resetAfterSave()
        getTuplessByUnitid(unitIdRetainer)// this is the id that was kept while searching for the tuple id, now it helps to refresh
        setTuplesheight(0)
      })
    } else {//save a new 
      if (name.includes('-')) {
        alert('The name should not contain hiphen ' - ' you can use underscore \'_\' ')
      } else if (name.includes(' ')) {
        alert('You have to replace space with underscore \'_\' or hiphen \'-\' ')
      } else if (name == '') {
        alert('You have to enter the tuple name')
      } if (data_type == '') {
        alert('You have to enter the data_type')
      } if (category == 0) {
        alert('You have to enter the category')
      } else {
        setShowLoader(true)
        Commons.saveTuple(tuple, unit_id).then((res) => {// save tule
          console.log('------------  Data below   ----------------')
          console.log(res.data)
          if (res.data.status == 'tuple already exists') {
            alert('Tuple with the same name of \' ' + name + ' already exists. use another name')
          } else {
            console.log(res.data)
            getTuplessByUnitid(unit_id)// this is the child unit
            setShowLoader(false)
            // setTuplesheight(0)
            setShowAlert(true)
            $('.selectedBigtext').html('done')
            setName('')
          }
        }).catch((error) => {
          console.log('-----------')
          alert('Error Occured: ' + error)
        })
        if (category == 'FK') {
          let ParentChildFields = {
            unit_id: unit_id,
            parentField_Id: parentField_Id
          }
          Commons.saveParentAndChild(ParentChildFields).then((res) => {
            console.log('Save the parent and the child')
          })
        }
        // setParentField_Id



      }
    }
  }

  /*#endregion SAVING TUPLE*/


  /*#region ---------------------------------- SAVING UNIT --------------------------------------*/
  const onSubmitHandler = (e) => {
    e.preventDefault()
    setShowLoader(true)
    var unit = {
      id: id, name: name, number_children: number_children, number_parent: number_parent
    }
    if (id) {
      Commons.updateUnit(unit, id).then((res) => {
        resetAfterSave()
      })
    } else {
      Commons.saveUnit(unit, structureId).then((res) => {
        if (structureId != null && structureId != undefined && structureId != 0) {

          console.log('after saving')
          console.log(res.data)
          if (res.data.status == 'unit already exists') {
            alert('The unit of the structure already exists')
          } else {
            console.log(res.data)
            if (res.data != null) {
              resetAfterSave()
            }
          }
        } else {
          alert('You have to select a structure')
        }
      }).catch((error) => {
        console.log('-----------')
        alert('Error Occured')
      })
    }
  }
  /*#endregion SAVING UNIT*/
  /*#endregion SAVING data*/

  /*#region ------------All Records, Deleting and By Id------------------------*/
  const getAllStructures = () => {
    Repository.findStrucuture().then((res) => {
      setStructures(res.data);
      setDataLoad(true)
    });
  }

  const structureSelectedHandle = (id) => {
    setStructureId(id)
    //set context

  }
  const getUnits = () => {
    Repository.findUnit().then((res) => {
      setUnits(res.data);
      setName(res.data.name)
      setId(res.data.id)
      setDataLoad(true)
      console.log('The unit name is:' + res.data.name)
    });
  }

  useEffect(() => {
    getAllStructures()
    // alert(darkMode)
    if (darkMode) {
      document.body.classList.add('darkBody')
    } else {
      document.body.classList.remove('darkBody')
    }

  }, [darkMode]);


  const getUnitById = (id) => {
    Repository.findUnitById(id).then((res) => {
      setStructureId(res.data.mdl_structure.id)
      setId(res.data.id)
      setName(res.data.name)
      console.log('the unit id about to update: ' + res.data.id)
      setClearBtn(true)
      showheight('auto')
    })
  }
  const delUnitById = (id) => {
    Utils.Submit(() => {
      Delete.deleteUnitById(id, () => { refreshUnitByStructure() })
    }, () => { })
  }
  const refreshUnitByStructure = () => {
    Repository.findUnitByStructureId(structureId).then((res) => {
      setUnits([])
      setUnits(res.data.otherUnits)
      console.log(res.data)
    })
  }
  const updateUnit_name = (name) => {
    var res_done = false;
    Repository.updateUnitByUnitname(name, unit_id).then(res => {
      res_done = true;
    })
    if (res_done) {
      Repository.findUnitByStructureId(structureId).then((res) => {
        setUnits(res.data.otherUnits)
        setUnitnames(res.data.name)
      })
    }
  }

  const delTupleById = (id) => {

    Utils.Submit(() => {
      Delete.delTupleById(id, () => { refreshUnitsTuples() })
    }, () => { })

  }
  const refreshUnitsTuples = () => {
    console.log('-------refreshing -----------')
    console.log('unit id: ' + unit_id)
    console.log('structureId id: ' + structureId)
    getTuplessByUnitid(unit_id)
    Repository.findUnitByStructureId(structureId).then((res) => {
      setUnits(res.data)
    })
  }
  /*#endregion Listing data*/

  /*#region ---------Show Height, reset all and clear Button   ------------*/
  function showheight(type) {
    setHeight(type)
  }
  const resetAfterSave = () => {
    document.getElementById("Form").reset();
    // getUnits()

    setShowLoader(false)
    setShowAlert(true)
    setHeight(0)
    setId(null)
    Repository.findUnitByStructureId(structureId).then((res) => {
      setUnits(res.data.otherUnits)
    })
  }
  const clearHandle = () => {
    setName('')
    setStructureId(0)
    setId(null)
    setClearBtn(false)
    setHeight(0)
  }
  const TupleClearHandle = () => {
    resetTuplesForm()

    setClearBtn(false)
    setTuplesheight(0)
  }

  const resetTuplesForm = () => {
    setId(null)
    setName('')
    setData_type('')
    setCategory('')
    setDisplay_caption('')
  }

  /*#endregion Listing data*/



  /*#region ---------- GET UNITS BY UNIT STRUCTURE_ID, GET TUPLES BY UNIT ID TOGGLE LAYOUTS, enable disable the unit name field--------------------------------------*/
  const getUnitsByStructure = (id, db_name) => {
    //the below 23 are for hiding the ok icon 
    setFinishEdit(false)

    setShowLoader(true)
    setStructureId(id)
    setDb_name(db_name)
    enlargeUnitsBox()
    setClickedUnitName('')
    setToggleexpandUnit(6)
    setChangable_width(6)// change the button box width by toggling while clicked the structure or unit items
    Repository.findUnitByStructureId(id).then((res) => {
      setUnits(res.data.otherUnits)
      setUnitnames(res.data.name)
      console.log('----------all the names-----------')
      console.log(unitNames)
      setShowLoader(false)
    })

  }

  const enlargeUnitsBox = () => {
    setUnitsBox('mt-2 col-md-7')
    setTuplesBox('mt-2 col-md-3')
  }
  const enlargeTuplessBox = () => {
    setUnitsBox('mt-2 col-md-3')
    setTuplesBox('mt-2 col-md-7')
  }
  const clickedUnitForTuples = (id, clickedUnitName) => {
    setShowmoreload(true)
    getTuplessByUnitid(id)
    setShowTuplesBox(true)
    setClickedUnitName(clickedUnitName)
    resetTuplesForm()
    setUnit_id(id)
    if (showTuplesBox) {
      enlargeTuplessBox()
    } else {
      enlargeUnitsBox()
    }


    // hide the ok button
    setToggleEnableUnitField(false)

  }
  const getTuplessByUnitid = (id) => {
    Repository.findTuplesByUnitId(id).then((res) => {
      setTuples(res.data)

      console.log('--------Data after update---------')
      console.log(res.data)
      setShowmoreload(false)
      setUnitidRetainer(id)

      setToggleexpandUnit(12)//set the toolbar large
      setChangable_width(12)// this is on the save and cancel buttons box to change as clicked on the structure and unit items
      setFormChangeableWidth(12)
      setTuplesheight(0)
      // showheight('auto')


      setFinishEdit(false) // hide the ok button and show the edit button

    })
  }
  const iconStyle = {
    color: "#470343",
    marginRight: "5px"
  }
  const getTupleById = (id) => {
    Repository.findTupleById(id).then((res) => {
      setId(res.data.id)
      setName(res.data.name)
      setData_type(res.data.data_type)
      setCategory(res.data.category)
      setDisplay_caption(res.data.display_caption)
      setClearBtn(true)
      setTuplesheight('auto')

      console.log(`The data type ${res.data.data_type}`)
    })
  }
  const setCurr_dateHandle = (e) => {
    e.preventDefault()
    setCurr_date(curr_date == 'Yes' ? 'No' : 'Yes')
  }
  const setCaptionAndName = (e) => {
    setName(e)
    // setDisplay_caption(name.toUpperCase())

  }

  const ToggleEnableUnitFieldHanlde = () => {
    setToggleEnableUnitField(true)
    setFinishEdit(true)


  }
  const EnterKey = (event) => {
    if (event.key == 'Enter') {
      setToggleEnableUnitField(false)
    }
  }

  const changedCategory = (e) => {
    setCategory(e.target.value)

    //also check iof the category is set to 'FK' as value
    if (e.target.value == 'FK') {
      setShowParentField(true)
    } else {
      setShowParentField(false)
    }


  }


  var ctrl = false;
  var entr = false;
  document.addEventListener('keydown', function (event) {
    console.log(`Key: ${event.key} with keycode ${event.keyCode} has been pressed`);

    if (event.key == 17) {
      ctrl = true

      if (ctrl && entr) {
        if (tuplesheight > 0) {
          setTuplesheight(0)
        } else {
          setTuplesheight('auto')
        }
      }
    }
    if (event.keyCode == 13) {
      entr = true
      if (ctrl && entr) {

        if (tuplesheight > 0) {
          setTuplesheight(0)
        } else {
          setTuplesheight('auto')
        }

      }

    }

  }
  )


  /*#endregion  GET UNITS BY UNIT*/

  /*#region Printing */
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'emp-data'
  });
  /*#endregion Listing data*/

  const changeBG = (e) => {
    e.preventDefault()
    setDarkMode(!darkMode)
  }



  // new way to use async
  return (
    <PagesWapper>

      <MainSideBar >
        <Routes>
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/about" element={<About />} /> */}
        </Routes>
      </MainSideBar>

      <PageModel
        sizeModel="two"
        leftPartContent={<VerticalStructures getUnitsByStructure={getUnitsByStructure} />}
        contentTitle="Units"
        contentPart={
          <Container>
            <div className='col-12   fw-bold border-bottom ' style={{ backgroundColor: 'white', position: 'sticky', top: '0' }}>
              {(db_name !== null && db_name !== undefined) &&
                <Icon style={iconStyle} size={22} color='#470343' icon={arrow} />}
              <span className='selectedBigtext text-uppercase' style={{ color: '#ec5300f8' }}>  {db_name} </span>
            </div>
            <AnimateHeight id="animForm" duration={300} animateOpacity={true} height={height}>
              <ContainerRowBtwn clearBtn={clearBtn} form='Unit' showLoader={showLoader}  >
                <ClearBtnSaveStatus height={height} showLoader={showLoader} showAlert={showAlert} />
                <FormInnerRightPane formChangeableWidth={formChangeableWidth} onSubmitHandler={onSubmitHandler}>
                  <InputRow name='Unit Name' val={name} handle={(e) => setName(e.target.value)} label='dbname' />
                  <UnitSaveUpdateBtns changable_width={changable_width} clearBtn={clearBtn} clearHandle={clearHandle} saveOrUpdate={FormTools.BtnTxt(clearBtn)} />

                </FormInnerRightPane>
              </ContainerRowBtwn>
            </AnimateHeight>
            <ContainerRow mt='3'>
              <ListToolBar ToggleexpandUnit={ToggleexpandUnit} listTitle='Units List' height={height} entity='Unit' changeFormHeightClick={() => setHeight(height === 0 ? 'auto' : 0)} changeSearchheight={() => setSearchHeight(searchHeight === 0 ? 'auto' : 0)} handlePrint={handlePrint} searchHeight={searchHeight} more="more"/>
              <SearchformAnimation searchHeight={searchHeight}>
                <SearchBox />
              </SearchformAnimation>
              <LoadSub showmoreload={showLoader} />
              <div className='row '>
                <PrintCompanyInfo />
                {units.map((unit) => {

                  return (
                    <div className='col-auto m-2 unitItem' onClick={() => clickedUnitForTuples(unit.id, unit.name)} >
                      <div classNames='header'>
                        <Icon style={{ color: "#470343", marginRight: "8px", }} size={16} icon={compass} />
                        {unit.name}
                      </div>
                      <div className='fields'>
                        <ul>

                          {unit.o_tuples.map((tuple) => (
                            <li>
                              <div className='row'>
                                <div className='col-7'>{tuple.name} </div>
                                <div className='col-4 ps-3 ' style={{ textAlign: 'right', fontSize: '10px', color: 'green' }}>
                                  {tuple.data_type == 'String' ? 'Abc' :
                                    (tuple.data_type == 'Integer' ? '123' :
                                      (tuple.data_type == 'Picture' ? '🖼️' : 'no')
                                    )}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )
                })

                }

              </div>
            </ContainerRow>
            {!dataLoad && <DataListLoading />}

          </Container>}
        rightPart={<>
          <Container>
            <div className='row d-flex justify-content-between' style={{ backgroundColor: 'white', position: 'sticky', top: '0' }}>
              <div className='col-md-11 ' >
                {(clickedUnitName !== null && clickedUnitName != '' && clickedUnitName !== undefined) &&
                  <span className='top_left selectedSubtext   mb-2 fw-bold  ' style={{ position: 'sticky', top: '0', color: '#ec5300f8' }}>
                    <Icon style={iconStyle} size={22} color='#ec5300f8' icon={arrow} />
                    {!ToggleEnableUnitField &&
                      <span  > {clickedUnitName}  </span>}

                    {ToggleEnableUnitField &&
                      <input type='text'
                        onKeyDown={(e) => EnterKey(e)} onChange={(e) => setClickedUnitName(e.target.value)}
                        className='fw-bold' style={{ color: '#ec5300f8' }} value={clickedUnitName} />
                    }
                  </span>}
              </div>
              <div className='col-1    '>
                {(clickedUnitName.length > 0 && !finishEdit) &&
                  <><Icon size={18} title={`Delete the unit: ${clickedUnitName} ?`}
                    onClick={() => ToggleEnableUnitFieldHanlde()} style={{ color: '#0fd120', cursor: 'pointer' }} color='#0fd120' icon={edit} />
                  </>}

                {clickedUnitName.length > 0 && finishEdit &&
                  <Icon size={20} title={`Delete the unit: ${clickedUnitName} ?`}
                    onClick={() => updateUnit_name(clickedUnitName)}
                    style={{ marginRight: '70px', color: '#0fd120', cursor: 'pointer' }} color='#f40000e8' icon={ok} />
                }
              </div>

              <div className='col-1    '>
                {clickedUnitName !== null && clickedUnitName != '' &&
                  <Icon size={18} title={`Delete the unit: ${clickedUnitName} ?`}
                    onClick={() => delUnitById(unit_id)} style={{ marginRight: '70px', color: '#f40000e8', cursor: 'pointer' }} color='#f40000e8' icon={delte} />
                }
              </div>

            </div>
          </Container>
 
          <div className='col-md-12 border-bottom'>
            <AnimateHeight id="tuplesAnimForm" duration={300} animateOpacity={true} height={tuplesheight}>
              <ContainerRowBtwn clearBtn={clearBtn} form='tuple' showLoader={showLoader}  >
                <ClearBtnSaveStatus height={height} showLoader={showLoader} showAlert={showAlert} />
                <div className={`box col-lg-12 col-md-12 ms-3 formBox mt-2`}  >
                  <div className='form-row'>
                    <form onSubmit={SubmitTosaveTuple} id="Form">
                      <InputRowTupleName ref={inputRef} name='Tuple Name' val={name} handle={(e) => setCaptionAndName(e.target.value)} label='tuple' />

                      <DropDownInput handle={(e) => setCurr_dateHandle(e)} name='Current Date' label='currdate' >
                        <option selected={curr_date === 'Yes'} value='Yes'>Yes</option>
                        <option selected={curr_date === 'No'} value='No'>No</option>
                      </DropDownInput>

                      <TwoColDropdownInput handle1={(e) => changedCategory(e)} name1='Category' label1='category'
                        handle2={(e) => setData_type(e.target.value)} name2='Data Type' label2='datatype'
                        txt1=
                        {<>
                          <option selected={category === 'Normal'} value='normal'>Normal</option>
                          <option selected={category === 'Primary Key'} value='PK'>Primary Key</option>
                          <option selected={category === 'Foreign Key'} value='FK'>Foreign Key</option></>}
                        txt2={
                          <>
                            <option key={1} selected={data_type === 'String'} value='String'>String </option>
                            <option key={2} selected={data_type === 'Integer'} value='Integer'> Integer </option>
                            <option key={3} selected={data_type === 'Double'} value='Double'> Double </option>
                            <option key={4} selected={data_type === 'Picture'} value='Picture'> Picture </option>
                          </>}
                      />

                      {/* selecting the foreign key on condition that the selected tuple is the foreign key */}
                      {showParentField &&
                        <DropDownInput handle={(e) => setParentField_Id(e.target.value)} name='Parent Field' label='currdate' >
                          {units.map(unit => {
                            return <option selected={parentField_Id == unit.id} value={unit.id} key={unit.id}>{unit.name}{$`.id`}  </option>
                          })}
                        </DropDownInput>
                      }
                      <SaveUpdateBtns clearBtn={clearBtn} clearHandle={TupleClearHandle} saveOrUpdate={FormTools.BtnTxt(clearBtn)} />
                    </form>
                  </div>
                </div>
              </ContainerRowBtwn>
            </AnimateHeight>
          </div>
          <ListToolBarRenamed animName='tuplesAnimForm' listTitle='Tuple List' height={tuplesheight} entity='Tuple' changeFormHeightClick={() => setTuplesheight(tuplesheight === 0 ? 'auto' : 0)} changeSearchheight={() => setSearchHeight(searchHeight === 0 ? 'auto' : 0)} handlePrint={handlePrint} searchHeight={searchHeight} />
          <SearchformAnimation searchHeight={searchHeight}>
            <SearchBox />
          </SearchformAnimation>
          <PrintCompanyInfo />
          {showmoreload &&
            <div className='row'>
              <div className='unitsLoading' style={{ backgroundColor: '#fff', position: 'relative', overflow: 'hidden', width: '380px', height: '40px' }}>
              </div>
            </div>}
          <TableOpen>
            <TableHead>
              <td>Tuple Name</td>
              <td>Data Type</td>
              <td>Displayed Caption</td>
              <td>Category</td>
              <td className='delButton'>Option</td>
            </TableHead>
            <tbody>
              {tuples.map((tuple) => (
                <tr key={tuple.id}>
                  <td>{tuple.name}   </td>
                  <td>{tuple.data_type}   </td>
                  <td>{tuple.display_caption}   </td>
                  <td>{tuple.category}   </td>
                  <ListOptioncol WhatToAnimate="tuplesAnimForm" height={tuplesheight} getEntityById={() => getTupleById(tuple.id)} delEntityById={() => delTupleById(tuple.id)} />
                </tr>
              ))}
            </tbody>
          </TableOpen>
          {/* </Tuple> */}
        </>}>
 
      </PageModel>
      
    </PagesWapper>


  )


}

export default Unit