import React, { useContext, useEffect, useState } from 'react'
import { Link, Route, Routes, useNavigate } from 'react-router-dom'
import ContainerRow, { ContainerRowBtwn, SaveUpdateBtns, ContainerRowFull, FormInnerRightPane, SaveUpdateBtnsBtnNear } from '../../Global/ContainerRow'
import InputRow, { LoadSub } from '../../Global/Forms/InputRow'
import ListToolBar from '../../Global/ListToolBar'
import PagesWapper from '../../Global/PagesWapper'
import SideBar, { DeploySubMenu } from '../../Navbar/SideBar'


import Conn from '../../../Services/Conn'
import axios from 'axios'
import Repository from '../../../Services/Repository'
import FormTools from '../../Global/Forms/PubFnx'
import { Icon } from 'react-icons-kit'

import { ic_warning as attention } from 'react-icons-kit/md/ic_warning'
import { checkmark as ticked } from 'react-icons-kit/icomoon/checkmark'
import { ic_error_twotone as error } from 'react-icons-kit/md/ic_error_twotone'
import { arrowRight as arrow } from 'react-icons-kit/icomoon/arrowRight'

import { compass } from 'react-icons-kit/icomoon/compass'
import { folderMinus as folder } from 'react-icons-kit/icomoon/folderMinus'
import VerticalStructures from '../VerticalMenu/VerticalStructures'
import { StructureContext, unitsByStrucListContext } from '../../Global/DataContext'
import { MainSideBar } from '../menu/MainSideBar'
import './Deploy.css'
import VertMenuSwitchable from '../VerticalMenu/VertMenuSwitchable'
import PageModel from '../PageModel/PageModel'
import { Row } from 'react-bootstrap'

function Deploy() {

    /* #region ------------------------------- Declarations -------------------------------------------------- */
    const [db_name, setDb_name] = useState()

    const [appName, setAppName] = useState('')
    const [packageName, setPackageName] = useState('codeguru')
    const [structures, setStructures] = useState([]) //Data List
    const [appDownloads, setAppDownloads] = useState([]) //Backend apps downloads
    const [frontEndAppDownloads, setfrontEndAppDownloads] = useState([]) //Frontend apps downloads

    const [showmoreload, setShowmoreload] = useState(false)//more as units when selected the structure

    const [deployFinished, setDeployFinished] = useState(false)//more as units when clicked the 'deploy' button
    const [alertAFterDeploy, setAlertAFterDeploy] = useState(false)// notify the finish of the 

    const [startedSearching, setStartedSearching] = useState(false)
    const [warningUnitHaveNoTuples, setWarningUnitHaveNoTuples] = useState(false)
    const [attentionUnitHaveNoTuples, setAttentionUnitHaveNoTuples] = useState(false)

    const [backend, setBackend] = useState(true) //Switch panes of downloads panes
    const [backfrontSwitch, setBackfrontSwitch] = useState('Back End') //Switch panes of downloads panes

    const navigate = useNavigate();

    const { unitsByStructure, setUnitsByStructure } = useContext(unitsByStrucListContext) //Data Lis tof the structures to be loaded in the dropdown list
    const [units, setUnits] = useState([]) //Data Lis tof the structures to be loaded in the dropdown list
    const { structureId, setStructureId } = useContext(StructureContext)
    const [unitsBox, setUnitsBox] = useState('mt-2 col-md-7')

    const [accessPort, setAccessPort] = useState()
    const [accessName, setAccessName] = useState()
    const [darkMode, setDarkMode] = useState(false)

    const [switchPane, setSwitchPane] = useState(true)
    const [size, setSize] = useState(2)

    /* #endregion */
    const deployHandle = () => {
        if (!warningUnitHaveNoTuples) {
            setAttentionUnitHaveNoTuples(false)
        }
        if (warningUnitHaveNoTuples) {
            setAttentionUnitHaveNoTuples(true)
        } else if (structureId != null && appName != null) {

            console.log('structure: ' + structureId + ' appName: ' + appName)
            setDeployFinished(true)
            setAlertAFterDeploy(false)
            const mdl_SpaceParams = {
                structureId: structureId,
                appName: appName
            }

            axios.post(Conn.wholePath.name + '/codes/', mdl_SpaceParams).then((res) => {
                setDeployFinished(false)
                setAlertAFterDeploy(true)
                setStructureId(null)
                setAppName('')

                getAppDownloads()
                setAccessPort(res.data[6])
                setAccessName(res.data[5])
            })
        } else {
            alert('You have to select a structure')
        }

    }

    const getAllStructures = () => {
        Repository.findStrucuture().then((res) => {
            setStructures(res.data);
            console.log('Structures')
            console.log(res.data)
        });
    }

    const getAppDownloads = () => {
        Repository.findApssDownloads().then((res) => {
            setAppDownloads(res.data);
            console.log('downloads')
            console.log(res.data)
        });
        Repository.findFrontEndApssDownloads().then((res) => {
            setfrontEndAppDownloads(res.data)
        })
    }

    const downloadSingleFileHandler = (appName) => {
        Repository.findApssDownloadSingleApp(appName)

    }
    const downloadSingleFileHandlerFrontEnd = (appName) => {
        Repository.findApssDownloadSingleFrontEndApp(appName)

    }
    const getUnitsByStructure = (id, db_name) => {//once selected a structure, illustrate the units
        setDb_name(db_name)
        setAppName(db_name)
        setStructureId(id)
        console.log('id below')
        console.log(structureId)
        setShowmoreload(true)
        Repository.findUnitByStructureId(id).then((res) => {
            setUnits(res.data.otherUnits)
            setWarningUnitHaveNoTuples(res.data.res_status == 'hasUnitsWithNoTuples')
            setAttentionUnitHaveNoTuples(false)
            setShowmoreload(false)
            console.log('data below')
            console.log(res.data)
            console.log(unitsByStructure)
        })

    }
    useEffect(() => {
        // document.body.className=''
        if (darkMode) {
            document.body.classList.add('darkBody')
        } else {
            document.body.classList.remove('darkBody')
        }
        getAllStructures()
        getAppDownloads()
    }, []);

    const startedSearchingHandle = () => {
        setStartedSearching(true)
    }
    const iconStyle = {
        color: "#470343",
        marginRight: "5px"
    }
    const switchStructurePane = (e) => {
        e.preventDefault()
        setSwitchPane(!switchPane)

    }
    return (
        <div>
            <PagesWapper>
                <MainSideBar switchStructurePane={(e) => { switchStructurePane(e) }}>
                    <Routes>
                        {/* <Route path="/dashboard" element={<Dashboard />} /> */}

                    </Routes>
                </MainSideBar>
                {/* <ContainerRow> */}

                {/* Pom conf */}

                <PageModel
                    sizeModel="one"
                    leftPartContent={<>
                        <VerticalStructures getUnitsByStructure={getUnitsByStructure} />
                    </>}
                    contentTitle="done"
                    contentPart={<>
                        <div className='row'>
                            <div className='col-auto'>
                                <button className='btn btn-info p-1 fw-bold p-l-3' onClick={deployHandle}> Depoly</button>
                            </div>
                            <div className='col-auto'>
                                <LoadSub showmoreload={deployFinished} />
                            </div>
                            <div className='col-auto'>
                                {alertAFterDeploy && <div class="alert alert-info fw-bold p-1" role="alert">
                                    <Icon style={{ iconStyle }} size={22} color='#000' icon={ticked} />  The structure is deployed
                                </div>}
                            </div>
                            {/* deployFinished */}
                        </div>
                        <LoadSub showmoreload={showmoreload} />

                        {alertAFterDeploy &&
                            <>
                                <div className='row d-flex justify-items-center'>
                                    <h2> The Application endpoints can be accessed on </h2>
                                    <a target='_blank' href={`http://localhost:${accessPort}/${accessName}/api/swagger-ui/index.html`}>  {db_name} </a>
                                </div>

                                <div className=' col-12  mt-5 mb-5 applicationmade_success'>

                                </div>
                            </>}
                        {units.map((unit) => (
                            <div className='col-auto m-2 unitItem'>
                                <div classNames='header'>
                                    <Icon style={{ color: "#470343", marginRight: "8px", }} size={16} icon={compass} />
                                    {unit.name}
                                </div>
                                <div className='fields'>
                                    <ul>
                                        {unit.o_tuples.map((tuple) => (
                                            <li>
                                                <div className='row'>
                                                    <div className='col-7'>{tuple.name} </div>
                                                    <div className='col-4 ps-3 ' style={{ textAlign: 'right', fontSize: '10px', color: 'green' }}>
                                                        {tuple.data_type == 'String' ? 'Abc' :
                                                            (tuple.data_type == 'Integer' ? '123' :
                                                                (tuple.data_type == 'Picture' ? '🖼️' : 'no')
                                                            )}
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}



                    </>}

                    // Tuples list 
                    rightPart={

                        <>
                            <div className={` styledVHScrollBar  `} style={{ height: '100%', overflowY: 'scroll' }}>
                                <div className='boldTitle '>Downloads ▶ {backend ? 'Back End' : 'Front End'} </div>
                                <div className='row   mb-2' style={{ fontSize: '11px' }}>
                                    <div className='col-6 fw-bold'  >
                                        <Link style={{ color: '#000' }} onClick={() => setBackend(false)}>
                                            {!backend && <span>✅</span>}  Front End  </Link>  </div>
                                    <div className='col-6 fw-bold'  >
                                        <Link style={{ color: '#000' }} onClick={() => setBackend(true)}>
                                            {backend && <span>✅ </span>}   Back End  </Link>  </div>
                                </div>

                                {backend &&
                                    <div className='row appNames fw-bold' style={{ fontSize: '12px', backgroundColor: '#c7def3f8' }}>
                                        <ul style={{ listStyle: 'none' }}>
                                            {appDownloads.map((app) => (
                                                <li>
                                                    {/* <a href={app + '.zip'} onClick={() => downloadSingleFileHandler(app.split('\\')[app.split('\\').length - 1])} > */}
                                                    <a href="#" onClick={() => downloadSingleFileHandler(app.split('\\')[app.split('\\').length - 1])} >
                                                        <Icon style={{ marginRight: "7px", color: '#b5a409f8' }} size={22} icon={folder} />
                                                        {app.split('\\')[app.split('\\').length - 1]}
                                                    </a>

                                                </li>
                                            ))}
                                        </ul>
                                    </div>}

                                {!backend &&
                                    <div className='row appNames fw-bold' style={{ fontSize: '12px', backgroundColor: '#e9c7f3f8' }}>
                                        <ul style={{ listStyle: 'none' }}>
                                            {frontEndAppDownloads.map((app) => (
                                                <li>
                                                    <a href="#" onClick={() => downloadSingleFileHandlerFrontEnd(app.split('\\')[app.split('\\').length - 1])} >
                                                        <Icon style={{ marginRight: "7px", color: '#b5a409f8' }} size={22} icon={folder} />
                                                        {app.split('\\')[app.split('\\').length - 1]}
                                                    </a>

                                                </li>
                                            ))}
                                        </ul>
                                    </div>}



                            </div>
                        </>

                    }
                    switchPane={switchPane}

                />
            </PagesWapper >
        </div>
    )
}

export default Deploy

export const Democontent = ({ backend }) => {
    return (<>

    </>
    )
}