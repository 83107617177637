import React, { useEffect, useState } from 'react'
import '../../Styles/DarkTheme.css'
import Utils from '../../Global/Utils'
import GuruDeploySideBar from '../../pages/menu/GuruDeploySideBar'
import GuruMenu from '../../Global/GuruMainMenu/GuruMenu'
import { Col, Container, Row } from 'react-bootstrap'
import { TableOpen, TableOpenNoBootstrap } from '../../Global/ListTable'
import TableHead from '../../Global/TableHead'
import Repository from '../../../Services/GithubRead/Repository'
import ListingModel_Deploy from '../ListingModel'
import Icon from 'react-icons-kit'
import { warning } from 'react-icons-kit/icomoon/warning'
function GuruDeployHome() {

  const [deployedApps, setDeployedApps] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [errorOccured, seterrorOccured] = useState(false)


  const resetCommit = (e, id) => {
    e.preventDefault()
    console.log('Reseting ...')
    Repository.updateReset(id).then((res) => {
      console.log('........REset Last time comimit')
      setRefresh(!refresh)

    }).catch((err) => [
      seterrorOccured(true)
    ])
  }
  const rerunHandler = () => {
    Repository.updateReRunApp().then((res) => {
      console.log('........RE clonning')
      setRefresh(!refresh)

    }).catch((err) => [
      seterrorOccured(true)
    ])
  }
  useEffect(() => {
    Repository.findAllDeployedApps().then((res) => {
      console.log('........Githubread Data')
      console.log(res.data)
      setDeployedApps(res.data)
    }).catch((err) => [
      seterrorOccured(true)
    ])
    Utils.PageLoadeDefaults()
    document.body.classList.add('darkBody')
  }, [refresh])
  return (
    <>
      {(errorOccured) && (
        <Container style={{ height: '80vh' }} >
          <Row className='  h-100 d-flex align-items-center justify-content-center'>
            <p className='text-center'>
              <h1 >
                <Icon size={50} icon={warning} /> Cant reach the backend
              </h1>
            </p>
          </Row>
        </Container>
      )}
      {!errorOccured && (<>
        <GuruDeploySideBar>
          <GuruMenu />
        </GuruDeploySideBar>
       
        <Container>
          <h2>GURU DEPLOYMENT PROCESSES</h2>
          <ul className='dataList'>
            {deployedApps.map((app) => (
              <ListingModel_Deploy key={app.id}
                morevalues={<>
                  <Col md={2}>
                    {(app.date_time_last_commit === '' || !app.date_time_last_commit) ? 'Not cloned' : app.date_time_last_commit}
                  </Col>
                </>}
                repo_name={app.repo_name} port={app.port} status={app.status}
                rerunHandler={() => rerunHandler(app.id)}
                resetApp={(e) => resetCommit(e, app.id)} />
            ))
            }
          </ul>


        </Container>
      </>)

      }
    </>
  )
}
export default GuruDeployHome