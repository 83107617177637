import React, { useEffect, useState } from 'react'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'

import '../../Dashboard/Dashboard.css'
import './GithubREad.css'
 

function AllPorjects({ projectsData }) {
    return (
        <Row className='d-flex'>
            {projectsData.map((project) => (
                <Col key={project.name}  md={6}>
                    <Card className='centeredcard cards d-block mx-auto ' style={{ width: '100%' }} >
                        <Card.Body>
                            <Card.Title className='devCarTitle'>
                                <h3 title={project.name} className='fw-bold mt-5 heading OneLine noBoldedHeaders'>
                                    {project.name}
                                </h3>
                            </Card.Title>

                            <Card.Subtitle className="mb-2 cardSubTitle"   >Card Subtitle</Card.Subtitle>
                            <Card.Text className='desc fewLines' >

                                <Row>
                                    <Col className='col-3'>Date created:</Col>
                                    <Col className='col-9'>{project.created_date_time}</Col>
                                </Row>
                                <Row>
                                    <Col className='col-3'>Created By:</Col>
                                    <Col className='col-9'>{project.create_by}</Col>
                                </Row>
                                <Row>
                                    <Col className='col-3'>clone Url:</Col>
                                    <Col className='col-9'>{project.clone_url}</Col>
                                </Row>

                            </Card.Text>
                            <Card.Link className='cardlink' href="#">Card Link</Card.Link>
                            <Card.Link className='cardlink' href="#">Another Link</Card.Link>
                        </Card.Body>
                    </Card>
                </Col>
            ))
            }
        </Row>
    )
}

export default AllPorjects