import React, { useState, useRef, useEffect, useContext } from 'react'
import PagesWapper from '../Global/PagesWapper'
import { useReactToPrint } from "react-to-print"
import SessionTime from '../../Services/SessionTime'
import axios from 'axios'
import Commons from '../../Services/Commons'
import Repository from "../../Services/Repository"
import VertNavBar from '../Navbar/VertNavBar'
import AnimateHeight from 'react-animate-height'



import UpdatedComponent from '../Global/HOCForm'
import { Link, Route, Routes, useParams } from 'react-router-dom';



import PrintCompanyInfo from '../Global/PrintCompanyInfo'
import Title, { EditTitle } from '../Global/Title';
import OtherStyles, { LoadingGif } from '../Styles/OtherStyles';
import Loader, { DataListLoading } from '../Global/Loader';
import TableHead from '../Global/TableHead'
import SearchBox from '../Global/SearchBox'

import 'react-datepicker/dist/react-datepicker.css'


import SideBar from '../Navbar/SideBar'

// import About from './About'

import ContainerRow, { ClearBtnSaveStatus, ContainerRowBtwb, ContainerRowBtwn, ContainerRowHalf, FormInnerRightPane, FormSidePane, SaveUpdateBtns } from '../Global/ContainerRow'
import InputRow, { BtnInputRow, DropDownInput, DropDownInputWithLoader, EmptyInputRow } from '../Global/Forms/InputRow'
import FormTools from '../Global/Forms/PubFnx'
import ListToolBar, { SearchformAnimation } from '../Global/ListToolBar'
import ListOptioncol, { TableOpen } from '../Global/ListTable'
import Utils from '../Global/Utils'
import Delete from '../../Services/Delete'
import VerticalStructures from './VerticalMenu/VerticalStructures'


import { Icon } from 'react-icons-kit'
import { arrowRight as arrow } from 'react-icons-kit/icomoon/arrowRight'
import { compass } from 'react-icons-kit/icomoon/compass'
import { StructureContext } from '../Global/DataContext'
import { MainSideBar } from './menu/MainSideBar'



function Tuple({ tuples }) {
    const [id, setId] = useState(null)

    /*#region ---------- ENTITY FIELDS DECLARATIONS ---------------------------*/
    const [structures, setStructures] = useState([])

    const { structureId, setStructureId } = useContext(StructureContext)
    const [db_name, setDb_name] = useState()
    const [unit_id, setUnit_id] = useState()
    const [name, setName] = useState('')
    const [data_type, setData_type] = useState()
    const [category, setCategory] = useState(0)
    const [display_caption, setDisplay_caption] = useState('x')
    const [curr_date, setCurr_date] = useState('No')
    const [showmoreload, setShowmoreload] = useState(false)

    /*#endregion Listing data*/

    const [showLoader, setShowLoader] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [units, setUnits] = useState([]) //Data Lis tof the structures to be loaded in the dropdown list

    const [clearBtn, setClearBtn] = useState(false) //The cancel button

    const [dataLoad, setDataLoad] = useState(false)
    const [height, setHeight] = useState(0);
    const [searchHeight, setSearchHeight] = useState(0);
    // const [tuples, setTuples] = useState([]) //Data List

    /*#region ---------- SAVING DATA TO DB--------------------------------------*/
    const onSubmitHandler = (e) => {
        e.preventDefault()
        setShowLoader(true)

        var tuple = {
            id: id, name: name,
            data_type: data_type,
            category: category,
            display_caption: display_caption,
            curr_date: curr_date
        }

        if (id) {
            Commons.updateTuple(tuple, id).then((res) => {
                resetAfterSave()
            })
        } else {
            if (name.includes(' ')) {
                alert('You have to replace space with underscore \'_\' or hiphen \'-\' ')
            } else {
                setShowLoader(true)
                Commons.saveTuple(tuple, unit_id).then((res) => {
                    console.log('------------  Data below   ----------------')
                    console.log(res.data)
                    if (res.data.status == 'tuple already exists') {
                        alert('Tuple with the same name of \' ' + name + ' already exists. use another name')
                    } else {
                        resetAfterSave()
                    }
                }).catch((error) => {
                    console.log('-----------')
                    alert('Error Occured')
                })
            }
        }
    }
    /*#endregion Listing data*/

    /*#region ------------All Records, Deleting and By Id------------------------*/
    const getAllStructures = () => {
        Repository.findStrucuture().then((res) => {
            setStructures(res.data);
            setDataLoad(true)
        });
    }

    const getAllUnits = () => {
        Repository.findUnit().then((res) => {
            setUnits(res.data);
            setDataLoad(true)
        });
    }
    const getTuples = () => {
        Repository.findTuples().then((res) => {
            setName(res.data.name)
            setId(res.data.id)
            setDataLoad(true)
            console.log('The unit name is:' + res.data.name)
        });
    }

    useEffect(() => {
        getAllStructures()
        document.body.classList.remove('darkBody')
    }, []);

    const getTupleById = (id) => {
        Repository.findUnitById(id).then((res) => {
            setId(res.data.id)
            setName(res.data.name)
            setData_type(res.data.data_type)
            setCategory(res.data.category)
            setDisplay_caption(res.data.display_caption)
            setClearBtn(true)
            showheight('auto')
        })
    }

    const delUnitById = (id) => {
        Utils.Submit(() => {
            Delete.deleteUnitById(id, () => { getTuples() })
        }, () => { })
    }

    const getUnitsByStructureid = (id) => {
        setShowmoreload(true)
        Repository.findUnitByStructureId(id).then((res) => {
            setUnits(res.data)
            showheight('auto')
            setShowmoreload(false)
        })
    }
    const getTuplessByUnitid = (id) => {
        Repository.findTuplesByUnitId(id).then((res) => {

            setClearBtn(true)
            showheight('auto')
        })
    }


    /*#endregion Listing data*/

    /*#region ---------Show Height, reset all and clear Button   ------------*/
    function showheight(type) {
        setHeight(type)
    }
    const resetAfterSave = () => {
        document.getElementById("Form").reset();
        setShowLoader(false)
        setShowAlert(true)
        setHeight(0)
        setName("")
        setId(null)
    }
    const clearHandle = () => {
        setName('')
        setUnit_id(0)
        setId(null)
        setClearBtn(false)
    }

    const setCurr_dateHandle = (e) => {
        e.preventDefault()
        setCurr_date(curr_date == 'Yes' ? 'No' : 'Yes')
    }
    const setCaptionAndName = (e) => {
        setName(e)
        // setDisplay_caption(name.toUpperCase())

    }
    /*#endregion Listing data*/


    /*#region Printing */
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'emp-data'
    });
    /*#endregion Listing data*/

    const iconStyle = {
        color: "#470343",
        marginRight: "5px"
    }
    const getUnitsByStructure = (id, db_name) => {
        setStructureId(id)
        setDb_name(db_name)
        setStructureId(id)
        setDb_name(db_name)
        setStructureId(id)
        console.log('id below')
        console.log(structureId)
        Repository.findUnitByStructureId(id).then((res) => {
            setUnits(res.data)
            console.log('data below')
            console.log(res.data)

        })

    }

    return (
        <PagesWapper>
            
              <MainSideBar >
                <Routes>
                    {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                    {/* <Route path="/about" element={<About />} /> */}
                </Routes>
            </MainSideBar>

            <div className='col-md-12'>
                <div className='col-auto mt-2 fw-bold '>
                    {(db_name !== null && db_name !== undefined) &&
                        <Icon style={iconStyle} size={22} color='#470343' icon={arrow} />}
                    {db_name}
                </div>
                <AnimateHeight id="animForm" duration={300} animateOpacity={true} height={height}>
                    <ContainerRowBtwn clearBtn={clearBtn} form='tuple' showLoader={showLoader}  >
                        <ClearBtnSaveStatus height={height} showLoader={showLoader} showAlert={showAlert} />
                        <div className={`box col-lg-12 col-md-12 ms-3 formBox mt-2`}  >
                            <div className='form-row'>
                                <form onSubmit={onSubmitHandler} id="Form">
                                    <DropDownInput showmoreload={showmoreload} handle={(e) => setUnit_id(e.target.value)} name='Units' label='units' >
                                        {units.map((unit) => (
                                            <option selected={unit.id == unit_id} value={unit.id} val={unit_id} key={unit.id}> {unit.name} </option>
                                        ))}
                                    </DropDownInput>
                                    <DropDownInput handle={(e) => setData_type(e.target.value)} name='Data Type' label='datatype' >
                                        <option value='String'>String </option>
                                        <option value='Integer'> Integer </option>
                                        <option value='Integer'> Double </option>
                                        <option value='Picture'> Picture </option>
                                    </DropDownInput>
                                    <BtnInputRow name='Add Current Date' handle={setCurr_dateHandle} status={curr_date} />
                                    <DropDownInput handle={(e) => setCategory(e.target.value)} name='Category' label='category' >
                                        <option value='normal'>Normal</option>
                                        <option value='PK'>Primary Key</option>
                                        <option value='FK'>Foreign Key</option>
                                    </DropDownInput>

                                    <InputRow name='Tuple Name' val={name} handle={(e) => setCaptionAndName(e.target.value)} label='tuple' />
                                    <SaveUpdateBtns clearBtn={clearBtn} clearHandle={clearHandle} saveOrUpdate={FormTools.BtnTxt(clearBtn)} />
                                </form>
                            </div>
                        </div>
                    </ContainerRowBtwn>
                </AnimateHeight>
                
                <ContainerRow mt='3'>
                    <ListToolBar listTitle='Tuple List' height={height} entity='Tuple' changeFormHeightClick={() => setHeight(height === 0 ? 'auto' : 0)} changeSearchheight={() => setSearchHeight(searchHeight === 0 ? 'auto' : 0)} handlePrint={handlePrint} searchHeight={searchHeight} />
                    <SearchformAnimation searchHeight={searchHeight}>
                        <SearchBox />
                    </SearchformAnimation>
                    <div ref={componentRef} className="dataTableBox">
                        <PrintCompanyInfo />
                        <TableOpen>
                            <TableHead>
                                <td>Structure</td>
                                <td>Unit</td>
                                <td>Tuple Name</td>
                                <td>Displayed Caption</td>
                                <td className='delButton'>Option</td>
                            </TableHead>
                          {tuples}
                        </TableOpen>
                    </div>
                </ContainerRow>
                {!dataLoad && <DataListLoading />
                }
            </div>
        </PagesWapper>


    )
}

export default Tuple