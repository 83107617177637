import React from 'react'
import { useState } from 'react';
import Repository from '../../../Services/Repository';
import { Icon } from 'react-icons-kit'
import { search } from 'react-icons-kit/icomoon/search'
import { ic_view_week_outline as strucIcon } from 'react-icons-kit/md/ic_view_week_outline'

import { useEffect } from 'react';

import './VerticalMenu.css'
import { Col, Row } from 'react-bootstrap';

export default function VerticalStructures({ getUnitsByStructure, dataLoad }) {

    const [structures, setStructures] = useState([]) //Data List

    const [startedSearching, setStartedSearching] = useState(false)
    const getAllStructures = () => {
        Repository.findStrucuture().then((res) => {
            setStructures(res.data);
        });
    }

    useEffect(() => {
        getAllStructures()
    }, []);
    return (
        <>
            <Row className="d-flex justify-content-around">
                <Col className='col-2   '>
                    <div className='container-fluid '>  {startedSearching &&
                        <input className='fw-bold' style={{ margin: 'auto' }} type='text' placeholder='search' />}
                    </div>
                </Col>
                <Col className='col-2 '>
                    <Icon style={{ float: "right", color: '#ccc' }} onClick={() => setStartedSearching(!startedSearching)}
                        className='hand_cursor' size={16} icon={search} />
                </Col>
            </Row>
            {structures.map((struc) => (
                <div className='col-11 m-0  structureItem parenthasTop_right' onClick={(e) => getUnitsByStructure(struc.id, struc.db_name)}>
                    <Icon style={{ marginRight: "7px", color: '#00e5ffda' }} size={22} icon={strucIcon} />
                    {struc.db_name}
                    <div className='childTop_right'>70</div>
                </div>
            ))}
        </>

    )
}

