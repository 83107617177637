import axios from 'axios'
import Conn from './Conn';

class Delete {
      static server = Conn.server.name + Conn.port.val + Conn.basicPath.val;

      deleteAccountCatById(id, callback) {
            axios.delete(Delete.server + "/account_category/" + id)
      }
      deleteUnitById(id, callback) {
            axios.delete(Delete.server + "/unit/" + id)
            callback()
      }
      delTupleById(id, callback) {
            axios.delete(Delete.server + "/tuple/" + id).then(() => {
                  callback()
            })
      }

      delStructureyId(id,callback) {
            axios.delete(Delete.server + "/structure/" + id).then(() => {
                  callback()
            })

      }

      // axios.delete(Commons.getAccount_categoryUrl() + id)
      //   .then((res) => {
      //     getAllCategories()
      //   }).catch((err) => {
      //     console.log('Error while deleting ..' + err.message)
      //   })

}
export default new Delete()