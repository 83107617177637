import React from 'react'
// Icons
import { Icon } from 'react-icons-kit'
import { printer } from 'react-icons-kit/icomoon/printer'
import { floppyDisk as save } from 'react-icons-kit/icomoon/floppyDisk'
import { cancelCircle as cancel } from 'react-icons-kit/icomoon/cancelCircle'
import { plus as add } from 'react-icons-kit/icomoon/plus'
import { search } from 'react-icons-kit/icomoon/search'
import { pencil as edit } from 'react-icons-kit/icomoon/pencil'
import { cross as remove } from 'react-icons-kit/icomoon/cross'
import { forward as point } from 'react-icons-kit/icomoon/forward'
import { arrowRight as Mainpoint } from 'react-icons-kit/icomoon/arrowRight'
import { Col } from 'react-bootstrap'

import { ic_not_interested as disabled } from 'react-icons-kit/md/ic_not_interested'
import { ic_play_arrow_outline as enabled } from 'react-icons-kit/md/ic_play_arrow_outline'


export const GeneralListOptioncol_ForDelUpdate = (props) => {/*This is is to animate for updaste and delete buttons only*/
    return (
        <>
            <td className='delButton optCol'>
                <div className='row d-flex justify-content-center'>
                    <button
                        aria-expanded={props.height !== 0} aria-controls={props.WhatToAnimate}
                        onClick={props.getEntityById} style={{ width: "20px" }} title="Update Record" className='mr-0 p-0 btn round-circle'>
                        <Icon size={16} style={{ color: '#0fd120', marginRight: "10px" }} icon={edit} />
                    </button>
                    <button onClick={props.delEntityById} style={{ width: "20px", marginLeft: "20px" }} title="Delete Record" className=' ml-0 p-0 btn'>
                        <Icon size={10} style={{ color: '#ff0000', marginRight: "10px" }} icon={remove} />
                    </button>
                </div>
            </td>
        </>
    )
}



export const OptionPart = (props) => {/*This has two buttons*/
    return (
        <>
            <div className='row optionPart d-flex justify-content-center editBtns'>
                <Col className='col-md-3  '>
                    <a href='#'> <Icon size={16} style={{ color: '#0fd120', marginRight: "10px" }} icon={edit}
                        aria-expanded={props.height !== 0} aria-controls={props.WhatToAnimate}
                        onClick={props.getEntityById} title="Update Record" className=' m-0 p-0 ' /></a>
                </Col>
                <Col className='col-md-3  '>
                    <a href='#'>
                        <Icon size={10} style={{ color: '#ff0000', marginRight: "10px" }} icon={remove}
                            onClick={props.delEntityById} title="Delete Record" className=' m-0 p-0  '
                        /></a>
                </Col>
            </div>
        </>
    )
}
export const OptionThreePart = (props) => {/*This has three buttons*/
    return (
        <>
            <div className='row optionPart d-flex justify-content-center editBtns'>
                <Col className='col-md-3 col-sm-3 '>
                    <a href='#'> <Icon size={16} style={{ color: '#0fd120', marginRight: "10px" }} icon={edit}
                        aria-expanded={props.height !== 0} aria-controls={props.WhatToAnimate}
                        onClick={props.getEntityById} title="Update Record" className=' m-0 p-0 ' /></a>
                </Col>
                <Col className='col-md-3 col-sm-3 '>
                    <a href='#'>
                        <Icon size={10} style={{ color: '#ff0000', marginRight: "10px" }} icon={remove}
                            onClick={props.delEntityById} title="Delete Record" className=' m-0 p-0  ' />
                    </a>
                </Col>
                <Col className='col-md-3 col-sm-3 '>
                    <a href='#'>
                        <Icon size={22} title="Disable" style={{ color: '#b17c00', marginRight: "10px" }} icon={disabled}
                            onClick={props.disable} /></a>
                </Col>
            </div>
        </>
    )
}
function ListOptioncol(props) {
    return <GeneralListOptioncol_ForDelUpdate
        WhatToAnimate={props.WhatToAnimate}
        delEntityById={props.delEntityById}
        getEntityById={props.getEntityById} />
}
export default ListOptioncol

export const ListOptioncolWithDeactivate = (props) => {
    return (
        <>
            <td className='delButton optCol' >
                <div className='row d-flex justify-content-center'>
                    <button onClick={props.getEntityById} style={{ width: "20px" }} title="Update Record" className='mr-0 p-0 btn round-circle'>
                        <Icon size={16} style={{ color: '#0fd120', marginRight: "10px" }} icon={edit} />
                    </button>
                    <button onClick={props.delEntityById} style={{ width: "20px", marginLeft: "20px" }} title="Delete " className=' ml-0 p-0 btn'>
                        <Icon size={10} style={{ color: '#ff0000', marginRight: "10px" }} icon={remove} />
                    </button>
                    <button onClick={props.delDisable} style={{ width: "20px", marginLeft: "20px" }} title="Disable" className=' ml-0 p-0 btn'>
                        <Icon size={15} style={{ color: '#ff0000', marginRight: "10px" }} icon={disabled} />
                    </button>
                </div>
            </td>
        </>
    )
}


export const ListOptionCol_ForTuples = (props) => {
    return (
        <>
            <td className='delButton optCol'>
                <div className='row d-flex justify-content-center'>
                    <button
                        aria-expanded={props.height !== 0} aria-controls="animForm"
                        onClick={props.getEntityById} style={{ width: "20px" }} title="Update Record" className='mr-0 p-0 btn round-circle'>
                        <Icon size={16} style={{ color: '#0fd120', marginRight: "10px" }} icon={edit} />
                    </button>
                    <button onClick={props.delEntityById} style={{ width: "20px", marginLeft: "20px" }} title="Update Record" className=' ml-0 p-0 btn'>
                        <Icon size={10} style={{ color: '#ff0000', marginRight: "10px" }} icon={remove} />
                    </button>
                </div>
            </td>
        </>
    )

}
export const TableOpen = (props) => {
    return (
        <div className='DataTableBox'>
            <table className='table  table-responsive table-striped dataTable table-bordered'>
                {props.children}

            </table>
        </div>
    )
}
export const TableOpenNoBootstrap = (props) => {
    return (
        <div className='DataTableBox'>
            <table className=' table-responsive table-striped dataTable table-bordered'>
                {props.children}

            </table>
        </div>
    )
}
export const TableOpenNOStrip = (props) => {
    return (
        <div className='DataTableBox'>
            <table className='table  table-responsive   dataTable table-bordered'>
                {props.children}

            </table>
        </div>
    )
}