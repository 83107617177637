import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import './HomeStyles.css'

import giantic from '../imgz/giantic-removebg.png'
import gianticwithbg from '../imgz/giantic.png'
import { Icon } from 'react-icons-kit'
import { ic_public_outline as web } from 'react-icons-kit/md/ic_public_outline'
import { cog as automated } from 'react-icons-kit/icomoon/cog'
import { ic_devices_other_outline as dev } from 'react-icons-kit/md/ic_devices_other_outline'
import { ic_business_center_outline as business } from 'react-icons-kit/md/ic_business_center_outline'
import { colors } from '@mui/material'
import Footer from '../Global/footer/Footer'
import { Slide } from 'react-reveal'
import happyCoder from '../imgz/happycoder-removebg.png'
import bossesyinoffice from '../imgz/bossesLaughingInOffice.jpg'
import { Helmet } from 'react-helmet'
import Utils from '../Global/Utils'
import Whatsapp from '../pages/WhatsappLink/Whatsapp'
import '../Styles/Technologies.css'
import { ic_library_add_check as tick } from 'react-icons-kit/md/ic_library_add_check'
import { suitcase as mission } from 'react-icons-kit/fa/suitcase'

import { rocket as goals } from 'react-icons-kit/fa/rocket'
import { clockO as time } from 'react-icons-kit/fa/clockO'
import { shield as quality } from 'react-icons-kit/fa/shield'
import { cubes as efficiency } from 'react-icons-kit/fa/cubes'
import { color } from 'framer-motion'

import SystemInADay from './SystemInADay'
function Mission() {
    const [imagesLoaded, setImagesLoaded] = useState(false);
    useEffect(() => {
        Utils.PageLoadeDefaults()
        document.body.classList.add('servicesBg')

        setImagesLoaded(true)

        // // Clean up the onload event when the component unmounts

    }, [])


    return (
        <>
            <Helmet>
                <title>Technology - CODEGURU Systems</title>
                <meta name="description" content="Software Development Company in Rwanda" />
                <meta name="keywords" content="Software Development, Programming, Coding, Development Tools, 
                    Software Engineering, Application Development, Web Development,
                    Mobile Development, Software Solutions, Custom Software, 
                    Agile Development, Software Design, Development Process, 
                    Full Stack Development, Frontend Development, Backend Development,
                    DevOps, Software Testing, Technology Solutions, Software Projects,
                    Digital Transformation, Business Growth, Technology Solutions, Innovation,
                    Process Optimization, Customer Experience, Efficiency, Scalability, Automation,
                    Data Analytics, Digital Strategy, Cloud Services, Cybersecurity,
                    IT Consulting, Custom Solutions, Competitive Advantage, Market Expansion, 
                    Digital Marketing, Business Intelligence, Cost Reduction,"></meta>
            </Helmet>
            <Whatsapp />


            <Container fluid className='bg-white serviceWrapper'  >
                <Row>
                    <Col md={12}>
                        <h1 className=''>
                            <Icon style={Utils.services} size={Utils.iconSmallSize} icon={mission} /> Mission {imagesLoaded}
                        </h1>
                    </Col>
                    <Col>
                        <p>
                            <Icon style={Utils.services} size={Utils.bulletSize} icon={tick} />
                            To empower developers and organizations by providing a comprehensive
                            suite of code and UI templates that streamline application development,
                            enhance productivity, and ensure consistency and quality across all projects.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <h1>
                            <Icon style={Utils.services} size={Utils.iconSmallSize} icon={goals} />
                            Goals
                        </h1>
                    </Col>
                    <Col md={4} >
                        <Card className=' goalsCard' style={{ border: `1px solid  ${Utils.border}` }}>
                            <Card.Body>
                                <Card.Title>
                                    <Icon style={Utils.services} size={Utils.bulletSize} icon={efficiency} />
                                    Efficiency</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Get excellent service</Card.Subtitle>
                                <Card.Text className='  mt-4 fewLines'>
                                    <div className='spacer mb-4' style={{ borderTop: `3px solid ${Utils.border}` }}></div>
                                    <p>
                                        Reduce development time by offering ready-to-use, customizable templates that cover a wide range of use cases.
                                    </p>
                                </Card.Text>
                                {/* <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link> */}
                            </Card.Body>
                        </Card>
                    </Col>


                    <Col md={4}>
                        <Card className='card goalsCard' style={{ border: `1px solid  ${Utils.border}` }}>
                            <Card.Body>
                                <Card.Title>
                                    <Icon style={Utils.services} size={Utils.bulletSize} icon={quality} />
                                    Quality</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Quality is our aim</Card.Subtitle>
                                <Card.Text className='  mt-4 fewLines'>
                                    <div className='spacer mb-4' style={{ borderTop: `3px solid ${Utils.border}` }}></div>
                                    <p>
                                        Ensure high-quality code and UI components that adhere to best practices and industry standards.
                                    </p>
                                </Card.Text>
                                {/* <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link> */}
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col md={4}>
                        <Card className='card goalsCard' style={{ border: `1px solid  ${Utils.border}` }}>
                            <Card.Body>
                                <Card.Title>

                                    <Icon style={Utils.services} size={Utils.bulletSize} icon={time} />
                                    Consistency</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Reliable service</Card.Subtitle>
                                <Card.Text className='  mt-4 fewLines'>
                                    <div className='spacer mb-4' style={{ borderTop: `3px solid ${Utils.border}` }}></div>
                                    <p>
                                        Promote uniformity in code and design, making it easier for teams to collaborate and maintain projects.
                                    </p>
                                </Card.Text>
                                {/* <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {/* vission */}

                <Row>
                    <Col>
                        <h1>
                            <Icon style={Utils.services} size={Utils.iconSize} icon={web} />
                            Vision
                        </h1>
                        <p className='smaller'  >
                            Vision Statement: To revolutionize the software development landscape by becoming the leading provider of code and UI templates,
                            fostering a community where developers can innovate, share, and build world-class applications effortlessly.
                        </p>
                        <h1 className='mb-4 mt-4'> <b>Long-Term Objectives:</b></h1>

                        <p className='list_paragraph'>
                            <span className='list_numbering'>1</span>
                            <b>Comprehensive Template Library: </b>Develop an extensive collection of templates for various programming languages, frameworks, and design systems, catering to diverse project needs.
                            <p style={{ marginLeft: '30px' }}>

                                <Icon style={Utils.services} size={Utils.bulletSize} icon={tick} />
                                <b> Code Templates:</b> Covering backend, frontend, microservices, serverless architectures, and more.
                                <br />
                                <Icon style={Utils.services} size={Utils.bulletSize} icon={tick} />
                                <b> UI Templates:</b> Including responsive design, accessibility, and modern UI/UX patterns.
                            </p>
                        </p>
                        <p className='list_paragraph' style={{ marginTop: '60px' }}>
                            <span className='list_numbering'>2</span>
                            <b>User-Friendly Platform:</b> Create an intuitive platform where developers can easily search, customize, and integrate templates into their projects.
                            <p style={{ marginLeft: '30px' }}>
                                <Icon style={Utils.services} size={Utils.bulletSize} icon={tick} />
                                <b>Template Marketplace:</b> Allowing developers to upload, share, and monetize their custom templates.
                                <br />
                                <Icon style={Utils.services} size={Utils.bulletSize} icon={tick} />
                                <b> Customization Tools:</b> Providing tools to personalize templates to fit specific project requirements.
                            </p>
                        </p>
                    </Col>
                </Row>

            </Container>

            <SystemInADay />
            <Footer />

        </>
    )
}

export default Mission