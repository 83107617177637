import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";

import "./components/Styles/App.css"
// import "./components/Styles/commons.css"
import "bootstrap/dist/css/bootstrap.min.css";
import { ic_view_week_outline as struc } from 'react-icons-kit/md/ic_view_week_outline'

import Unit from "./components/pages/Units/Unit";
import Tuple from "./components/pages/Tuple";
import "./components/Styles/SideBar.css"
import { useEffect, useMemo, useState } from "react";
import { CatNameContext, StructureContext } from "./components/Global/DataContext";

import Deploy from "./components/pages/Deploy/Deploy";
import LayoutPage from "./components/pages/Layout";
import PomConf from "./components/pages/Deploy/PomConf";
import { RequireAuth } from "react-auth-kit";
import LoggedInPage from "./components/pages/LoggedInPage";

import Aboutus from "./components/aboutus/Aboutus";
import TutorialsHome from "./components/tutorials/TutorialsHome";
import MainStructure from "./components/pages/Structure/MainStructure";
import Mission from "./components/home/Mission";
import ContactusForm from "./components/Contactus/ContactusForm";
import DataSucessfulySaved from "./components/pages/DataSucessfulySaved";
import Dashboard from "./components/Dashboard/Dashboard";
import Changepassword from "./components/pages/Changepassword";
import PageModel from "./components/pages/PageModel/PageModel";


import Services from "./components/Services/Services";


import GithubReadHome from "./components/GuruMainModules/GuruGithubread/GithubReadHome";
import GuruMaster from "./components/Global/GuruMaster/GuruMaster";
import GuruMasterHome from "./components/GuruMainModules/GuruMaster/GuruMasterHome";
import GuruDeployHome from "./components/GuruMainModules/GuruDeploy/GuruDeployHome";
import NotRunningApps from "./components/GuruMainModules/GuruMaster/NotRunningApps";
import SecondMenu from "./components/Navbar/SecondMenu";
import Logout from "./components/pages/Logout";
import Users from "./components/pages/users/Users";
import MailCow from "./components/tutorials/MailCow/MailCow";
import Utils from "./components/Global/Utils";
import PubUserRegistration from "./components/pages/users/PubUserRegistration";





function App() {
  const [category, setCategory] = useState(null)
  const categoryDetails = useMemo(() => ({ category, setCategory }), [category, setCategory])
  const [structureId, setStructureId] = useState(0)
  const [unitsByStructure, setUnitsByStructure] = useState([])


  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    document.body.style.backgroundColor = Utils.skinBg1();
    
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        // Scrolling down
        setShowNavbar(false);
        console.log('Scrolling down' )
      } else {
        // Scrolling up
        console.log('Scrolling up' )
        setShowNavbar(true);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);





  return (
    <>
      
      <SecondMenu  visible={showNavbar} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={
          <RequireAuth loginPath="/login">
            <Dashboard />
          </RequireAuth>} />
        <Route path="/structure" element={
          <StructureContext.Provider value={{ structureId, setStructureId }}>
            <RequireAuth loginPath="/login">
              <MainStructure />
            </RequireAuth>
          </StructureContext.Provider>
        } />
        <Route path="/unit" element={
          <StructureContext.Provider value={{ structureId, setStructureId }}>
            <RequireAuth loginPath="/login"> <Unit /></RequireAuth>
          </StructureContext.Provider>
        } />

        <Route path="/layout" element={<LayoutPage />} />
        <Route path="/tutorials" element={<TutorialsHome />} />

        <Route path="/tuple" element={
          <StructureContext.Provider value={{ structureId, setStructureId }}>
            <RequireAuth loginPath="/login">  <Tuple /></RequireAuth>
          </StructureContext.Provider>
        } />
        <Route path="/deploy" element={
          <StructureContext.Provider value={{ structureId, setStructureId, unitsByStructure, setUnitsByStructure }}>
            <RequireAuth loginPath="/login">  <Deploy /></RequireAuth>
          </StructureContext.Provider>} />
        <Route path="/login" element={<LoggedInPage />} />
        <Route path="/contactus" element={<ContactusForm />} />

        <Route path="/pomconf" element={<PomConf />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/codeguru-mission" element={<Mission />} />
        <Route path="/datasucessfulysaved" element={
          <DataSucessfulySaved />
        }
        />
        <Route path="/changepassword" element={<Changepassword />} />
        <Route path="/pagemodel" element={<PageModel />} />
        {/* <Route path="/runningapps" element={<GuruMasterHome />} /> */}

        <Route path="/services" element={<Services />} />
        <Route path="/guru-githubread" element={<GithubReadHome />} />
        <Route path="/code-master" element={<GuruMasterHome />} />
        <Route path="/code-deploy" element={<GuruDeployHome />} />
        <Route path="/not-runningapps" element={<NotRunningApps />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/users" element={<Users />} />
        <Route path="/mailcow" element={<MailCow/>  } />
        <Route path="/registration" element={<PubUserRegistration/>  } />

      </Routes>
      {/* SideBar */}

      </>
  );
}

export default App;
