import React, { useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import Lottie, { LottieRef } from 'lottie-react'
import { fileText as fin_report } from 'react-icons-kit/icomoon/fileText'
import { profile as customer_report } from 'react-icons-kit/icomoon/profile'
import { Icon } from 'react-icons-kit'
import corporate from '../imgz/home/guy_with_a_report-removebg.png'
import { Slide } from 'react-reveal'
import { useNavigate } from 'react-router-dom'
import Utils from '../Global/Utils'

function BizReports() {

    const [starting, setStarting] = useState(false)
    const mrgin = '20px'

    const icon_one_style = {
        color: 'rgb(6, 187, 242)',
        marginBottom: mrgin
    }
    const icon_two_style = {
        color: '#fff',
        marginBottom: mrgin
    }
    const cardTitle = {
        margin: '0px',
        color: '#000',
        fontSize: '25px',

        // textShadow: '1px 1px '

    }
    const navigate = useNavigate()
    const gotContactus = () => {
        navigate("/contactus");
    }
    return (
        <>
        
            <Container fluid className="container-fluid  biz_report " style={{ backgroundColor: Utils.skinBg1(), padding: '20px' }} >
                <Row style={{ backgroundColor: '#fff', paddingBottom: '70px', paddingTop: '70px' }} className='  '>
                   {/* -----------------------------------------  IMG IMAGE BACKGROUND  ----------------------------------------- */}
                   
                    <Col className='imgWrapper'   >
                        <Slide left>
                            <img src={corporate} className='img-fluid' />
                        </Slide>
                        
                    </Col>
                    <Col md={8} className="  text-center  contentPart " style={{backgroundColor:'transparent'}}  >
                        <h1    > Insightful reports   </h1>
                        <p     >
                            Our advanced reporting system is designed to enhance business decision-making by providing comprehensive and easy-to-understand reports that distill complex data into actionable insights. By leveraging cutting-edge analytics, the system identifies and highlights key trends, patterns, and anomalies, enabling you to make informed decisions swiftly. Whether you're assessing financial performance, customer behavior, or operational efficiency, our reports are tailored to meet the specific needs of your business </p>

                        <Row className='d-flex align-items-stretch justify-content-between '>
                            <Col md={6}>
                                <Card className='centeredcard' style={{ padding: '30px', border: `1px solid  ${Utils.skinBg1()} `, width: '20rem' }}>
                                    <Card.Body>
                                        <Icon style={icon_one_style} size={35} icon={fin_report} />
                                        <Card.Title className="cardTitle" style={cardTitle}>Financial report</Card.Title>

                                        <Card.Text className="cardDescription mt-4">
                                            <div className='spacer mb-4' style={{ borderTop: `3px solid ${Utils.border}` }}></div>
                                            Get your business comprehensive overview of a company's financial health, including revenue, expenses, profit margins, and cash flow.
                                        </Card.Text>
                                        {/* <Card.Link href="#">Card Link</Card.Link> */}

                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col md={6}  >
                                <Card className='centeredcard' style={{ padding: '30px', border: `1px solid  ${Utils.skinBg1()} `, width: '20rem' }}>
                                    <Card.Body>
                                        <Icon style={icon_one_style} size={35} icon={customer_report} />
                                        <Card.Title className="cardTitle" style={cardTitle}>Customer report</Card.Title>

                                        <Card.Text className="cardDescription mt-4">
                                        <div className='spacer mb-4' style={{ borderTop: `3px solid ${Utils.border}` }}></div>
                                            detailed analytics on customer behavior, preferences, and demographics. It helps businesses understand their customer base, identify market trends, and tailor their marketing strategies to better meet customer needs.
                                        </Card.Text>
                                        {/* <Card.Link href="#">Card Link</Card.Link> */}

                                    </Card.Body>
                                </Card>

                            </Col>
                            <Col md={12} >
                                <Button style={{ width: '100%' }} className='btn btn-block  my-4  blackButton' onClick={() => gotContactus()}>
                                    Contact us
                                </Button>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default BizReports