import axios from "axios"
import Conn from "../Conn"

class PostRequests {


    checkRunningAps(myjar) {
        let config = {
            params: {
                myjar:myjar
            },

        }
        return axios.get(Conn.wholePath.runningApps + "/running/myjar",config)
    }

}

export default new PostRequests()