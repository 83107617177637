import React, { useState } from 'react'

function Pagination({ itemsPerPage, totalitems, paginate, prevEvent, nextEvent ,activeButton, uiStart,uiLimit}) {
    const pageNumbers = []
    const [realdbTotalItems, setRealDbTotalitems] = useState(totalitems)
    const [paginationUiLimit, setPaginationUiLimit] = useState(10)//we dont want the pagination to be so loong when if we limit by the db total items it 
    for (let i = uiStart; i < uiLimit; i++) {
        pageNumbers.push(i)
    }
    // for (let i = 1; i < Math.ceil(totalitems / itemsPerPage) + 1; i++) {
    //     pageNumbers.push(i)
    // }

    return (
        <nav aria-label="Page navigation example mt-1">
            <ul className='pagination' >
                <li class="page-item">
                    <a class="page-link" onClick={(e) => prevEvent(e)} href={`#p`} aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>

                {pageNumbers.map(number => (
                    <li key={number} className='page-item' style={{border:'red !important'}}>
                        <a style={{border:'red', backgroundColor: activeButton==number?'blue !important':'#fff !important'  }}   onClick={(e) => paginate(number,e)} href={`#${number}`} className='page-link'>
                            {number}
                        </a>
                    </li>
                ))}
                <li class="page-item">
                    <a class="page-link"  href="#d" aria-label="Next">
                        <span aria-hidden="true">...<smal style={{fontWeight:"bold", fontSize:"13px"}}>{ (Math.ceil(totalitems / itemsPerPage) + 1)}</smal></span>
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link" onClick={(e) => nextEvent(e)} href={`#n`} disabled={!pageNumbers.hasNextPage} aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>

        </nav>
    )
}

export default Pagination