import React from 'react'
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import {
    FaBars
} from "react-icons/fa";
import { Switchbtn } from './MainSideBar';
function MenuLogo(props) {
    // const [isOpen, setIsOpen] = useState(false);
    // const toggle = () => setIsOpen(!isOpen);
    return (
        <>
            {/* <Row className='border bprder-light w-100' style={{ zIndex: '5',left:'9px', top: '0px', position: 'absolute' }}>
                <Switchbtn switchStructurePane={props.switchStructurePane} />
            </Row> */}
            <div className="top_section">
                <h1 style={{ display: props.isOpen ? "block" : "none" }} className="logo"></h1>
                <div style={{ marginLeft: props.isOpen ? "50px" : "0px" }} className="bars">
                    <FaBars onClick={props.toggle} />
                </div>
            </div>
        </>
    )
}

export default MenuLogo