import React, { Component } from 'react'
import axios from "axios"
import Conn from './Conn'

class Commons {
    static server = Conn.server.name + Conn.port.val
    static apiPath = Conn.basicPath.val




    RedirectToLogin() {
        localStorage.removeItem('token')
        localStorage.removeItem('catname')
        localStorage.removeItem('userid')
        localStorage.removeItem('username')
        localStorage.clear()
        if (localStorage.getItem('token') == '' && localStorage.getItem('catname') == '' && localStorage.getItem('userid') == '' && localStorage.getItem('username')) {
            window.location.replace('/login')
        } else {
            localStorage.removeItem('token')
            localStorage.removeItem('catname')
            localStorage.removeItem('userid')
            localStorage.removeItem('username')
            localStorage.clear()
            window.location.replace('/login')
        }


    }




    getProfileUrl() {
        return "http://" + Commons.server + Commons.apiPath + "/profile/"
    }
    getAccountUrl() {
        return "http://" + Commons.server + Commons.apiPath + "/account/"
    }
    getLogin(username, password) {
        return axios.get("http://" + Commons.server + Commons.apiPath + "/account/" + username + "/" + password)
    }
    getAccount_categoryUrl() {
        return "http://" + Commons.server + Commons.apiPath + "/account_category/"
    }
    getClientUrl() {
        return "http://" + Commons.server + Commons.apiPath + "/client/"
    }



    saveAccount(UsersDTO) {
        return axios.post(Conn.wholePath.name + "/account/nogender", UsersDTO)
    }
    UpdateAccount(id, profileId, catId, UsersDTO) {
        return axios.put(Conn.wholePath.name + "/account/" + id + "/" +  profileId + "/" + catId, UsersDTO)
    }
    updateStructure(structure, id) {
        return axios.put(Conn.wholePath.name + "/structure/" + id, structure)
    }

    saveStructure(structure) {
        return axios.post(Conn.wholePath.name + "/structure/", structure)
    }
    saveUnit(unit, structure_id) {
        return axios.post(Conn.wholePath.name + "/unit/" + structure_id, unit)
    }
    updateUnit(unit, id) {
        return axios.put(Conn.wholePath.name + "/unit/" + id, unit)
    }

    saveTuple(tuple, unit_id) {
        return axios.post(Conn.wholePath.name + "/tuple/" + unit_id, tuple)
    }
    updateTuple(tuple, id) {
        return axios.put(Conn.wholePath.name + "/tuple/" + id, tuple)
    }
    saveCategory(cat) {
        return axios.post(Conn.wholePath.name + "/account_category/", cat)
    }
    saveParentAndChild(ParentChildFields) {
        return axios.post(Conn.wholePath.name + "/layout/saveChildandParent", ParentChildFields)
    }

    saveContatus(Mdl_contactus) {
        return axios.post(Conn.wholePath.name + "/contactus/", Mdl_contactus)
    }
    saveDeployment(deployParams) {
        return axios.post(Conn.wholePath.guru_deploy + "/make/newproject", deployParams)
    }
    /*#region ---------------PUBLISH => COMMAND RUN*/


    findLocate(appName) {
        return axios.post(Conn.wholePath.name + "/command/runpath/" + appName)
    }
    findinstall(appName) {
        return axios.post(Conn.wholePath.name + "/command/runmvn/" + appName)
    }
    findrun(appName) {
        return axios.post(Conn.wholePath.name + "/command/runstart/" + appName)
    }

    /*#endregion */


}

export default new Commons()
