import React from 'react'
import { Col, Row } from 'react-bootstrap'

function ListingModel_Deploy({ repo_name, port, status, morevalues, moreActions , rerunHandler, resetApp }) {
    return (<li className='big my-3'><a href="#">{repo_name}</a>
        <ul>
            <li className='small'>
                <Row>
                    <Col md={1}> {port}</Col>
                    <Col md={1}> {status}</Col>
                    {morevalues}

                    <Col md={10}>
                        <Row className='d-flex justify-content-end'>
                            {moreActions}
                            <Col md={3} className='rerunCol'>
                                <a href='#' className='btn btnAppStatus p-2' title='This will make the mark the apps as closed, it wont instally redeploy'>
                                    Close
                                </a>
                                <a href='#' className='btn  mx-2 btnAppStatus p-2' onClick={rerunHandler} title='Re-clone' >
                                    Re-run
                                </a>
                                <a href='#' className='btn btnAppStatus p-2'       onClick={resetApp}     title='Reset Last Comit' >
                                    Reset
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </li>
        </ul>
    </li>

    )
}

export default ListingModel_Deploy