import React from 'react'
import { Container } from 'react-bootstrap'

function Cicd() {
    return (
        <>
            <Container>
                <h1>
                    Setting up GitHub Actions CI/CD automates the process of deploying your Spring Boot application
                </h1>
                <p>
                    CI/CD stands for Continuous Integration and Continuous Delivery (or Continuous Deployment). It's a set of
                     principles and practices aimed at automating and streamlining the process of software development and delivery.
                </p>
                <p>
                    Continuous Integration (CI) involves automatically building and testing code changes whenever developers 
                    integrate their code into a shared repository, typically multiple times a day. This ensures that any changes
                     made by developers do not break the existing codebase and helps identify and fix issues early in the development
                      cycle.
                </p>
                <p>
                    Continuous Delivery (CD) extends CI by automating the deployment of code changes to production or staging 
                    environments after they have been tested and verified. This allows teams to release software updates more 
                    frequently, reliably, and with less manual intervention. Continuous Deployment takes this a step further by 
                    automatically deploying code changes to production without human intervention, assuming all tests pass successfully.
                </p>
            </Container >


        </>
    )
}

export default Cicd