import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Staticpart.css'

import Icon from 'react-icons-kit'
import SearchPane from './SearchPane'
function PageModel({ leftPartContent, contentTitle, contentPart, rightPart, sizeModel }) {
    // The sizeModel is the one that helps to use this model in different PhotoSizeSelectActual, so it is called in one component 
    // the mouseover events will size the components in one way and if it is called in another component the mouseover sizes in different sizes,
    // there is one and two (the deploy component will call this as "one", in the unit it will be called as "two")

    const [firstFocus, setFirstFocus] = useState(2)
    const [secondFocus, setSecondFocus] = useState(8)
    const [thirdFocus, setThirdFocus] = useState(2)

    const firstFocusHover = () => {
        if (sizeModel == 'one') {
            setFirstFocus(3)
            setSecondFocus(5)
            setThirdFocus(4)
        } else if (sizeModel == 'two') {// when it(PageModel) is called on the second component
            setFirstFocus(3)
            setSecondFocus(5)
            setThirdFocus(4)
        }

    }
    const secondFocusHover = () => {
        if (sizeModel == 'one') {
            setFirstFocus(2)
            setSecondFocus(8)
            setThirdFocus(2)
        } else if (sizeModel == 'two') {// when it(PageModel) is called on the second component
            setFirstFocus(2)
            setSecondFocus(5)
            setThirdFocus(5)
        }

    }
    const thirdFocusFocusHover = () => {
        if (sizeModel == 'one') {
            setFirstFocus(2)
            setSecondFocus(5)
            setThirdFocus(5)
        } else if (sizeModel == 'two') {// when it(PageModel) is called on the second component
            setFirstFocus(2)
            setSecondFocus(4)
            setThirdFocus(6)
        }

    }
    return (
        <>
            <Container fluid className='mt-0 pageModelWrapper'>
                <Row className='staticPart'>
                    <Col onMouseOver={() => firstFocusHover()} md={firstFocus} className=' part fpart '  >
                        <ModelContent stickyTitle={<SearchPane />} content={leftPartContent} />
                    </Col>

                    <Col onMouseOver={() => secondFocusHover()} md={secondFocus} className='part spart styledVHScrollBar '>
                        <ModelContent stickyTitle={contentTitle} content={contentPart} />
                    </Col>

                    <Col onMouseOver={() => thirdFocusFocusHover()} md={thirdFocus} className=' part tpart styledVHScrollBar '>
                        <ModelContent stickyTitle="Download" content={rightPart} />
                    </Col>
                </Row>
            </Container>


        </>
    )
}

export default PageModel

export const ModelContent = ({ stickyTitle, content, }) => {
    return <>
        <StickyPart stickyTitle={stickyTitle} />
        <div className='scrollableContent   m-0 w-100 styledVHScrollBar  '>
            {content}
        </div>

    </>
}

export const StickyPart = ({ stickyTitle }) => {
    return (
        <Row className='stickyHeader' style={{ position: 'sticky', top: '0', backgroundColor: '#fff' }}>
            <Col className='col-12  ps-2 m-0' style={{ zIndex: '1' }}>
                <b> {stickyTitle}</b>
            </Col>
        </Row>
    )
}

