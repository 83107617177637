
import './footer.css'
import { Col, Container } from 'react-bootstrap';
import { ic_perm_phone_msg as phone } from 'react-icons-kit/md/ic_perm_phone_msg'
import { ic_facebook } from 'react-icons-kit/md/ic_facebook'
import { linkedin } from 'react-icons-kit/fa/linkedin'
import Icon from 'react-icons-kit';
import Utils from '../Utils';

function Footer() {

    const iconStyle = {
        color: "#2ccdf5",
        marginRight: Utils.SocialmediaiconSize

    };
    const yearElement = new Date().getFullYear();
    return (
        <Container fluid className="footer ">

            <Col md={2}>
                <a style={{fontWeight:'normal'}} href='/registration'>Create account</a>
            </Col>
            <Col md={8} className='d-flex justify-content-center'> &copy;
                <span id="year">{yearElement} </span>
                <span>
                    CODEGURU. All rights reserved.
                </span></Col>
            <Col md={2}>
                <a target="_blank" href="https://web.facebook.com/profile.php?id=100066522506361">
                    <Icon style={iconStyle} size={18} icon={ic_facebook} />
                </a>
                <a target="_blank" href="http://www.linkedin.com/in/sangwa-emmanuel-23295b97">
                    <Icon style={iconStyle} size={18} icon={linkedin} />
                </a>
            </Col>



        </Container>
    )
}

export default Footer
