import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Icon from 'react-icons-kit'
import { search } from 'react-icons-kit/icomoon/search'
function SearchPane() {
    const [startedSearching, setStartedSearching] = useState(false)
    const startedSearchingHandle = () => {
        setStartedSearching(true)
    }
    return (
        <Row className="d-flex justify-content-around p-0 m-0  search">
            <Col className='col-10   p-0 '>
                <div className='container-fluid   p-0' style={{overflow:'hidden'}}>
                    {startedSearching ?
                        <input className='fw-bold' style={{ width: '100%', margin: 'auto' }} type='text' placeholder='search' />
                        : 'List'}
                </div>
            </Col>
            <Col className='col-2 '>
                <Icon style={{ float: "right", color: '#ccc' }} onClick={() => setStartedSearching(!startedSearching)}
                    className='hand_cursor' size={16} icon={search} />
            </Col>
        </Row>
    )
}

export default SearchPane