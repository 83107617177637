import React from 'react'
import { useState } from 'react'
import Icon from 'react-icons-kit'
import { ic_done_outline as done } from 'react-icons-kit/md/ic_done_outline'
import { LoadSub } from '../../Global/Forms/InputRow'



export const  DeployShowProgress = (props) => {
    return <>
        <div className='row d-flex justify-content-center'   >
            <div className='col-6'>
                <LoadSub showmoreload={props.showmoreload} />
            </div>
        </div>


    </>
}


function Tick(props) {


    return (
        <>
            <div className='col-1 border d-flex justify-content-center'>
                <span className='number d-flex justify-content-center align-items-center fw-bold '>
                    {props.tickIcon ? <Icon size={41} style={{ color: '#fff' }} icon={done} /> : props.number}
                </span>
            </div >
        </>
    )
}

export default Tick