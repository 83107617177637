import React, { useContext } from 'react'
import { GlobalContext } from '../../Global/GlobalContext';

function TestDelete() {
    const { globalState, setGlobalState } = useContext(GlobalContext);
    return (<>
        <div>TestDelete</div>
        <p>My name is: {globalState.value}</p>
    </>
    )
}

export default TestDelete