import React, { useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { ClearBtnSaveStatus, ContainerRowBtwn, FormInnerRightPane } from '../../Global/ContainerRow'
import InputRow, { LoadSub, TwoColInput } from '../../Global/Forms/InputRow'
import PagesWapper from '../../Global/PagesWapper'
import { DeploySubMenu, MainSideBar } from '../menu/MainSideBar'
import { Route, Routes } from 'react-router-dom'



import { ic_done_outline_twotone as tick } from 'react-icons-kit/md/ic_done_outline_twotone'
import Icon from 'react-icons-kit'
import { ic_done_outline as done } from 'react-icons-kit/md/ic_done_outline'
import Tick, { DeployShowProgress } from './DeployComponents'

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { height } from "@mui/system";
import Commons from '../../../Services/Commons'

function PomConf({ height, showLoader, showAlert, savepomConf, clearBtn }) {

    const [db_name, setDb_name] = useState()
    const [springVersion, setSpringVersion] = useState('2.7.9-SNAPSHOT')
    const [group, setGroup] = useState()
    const [artifact, setArtifact] = useState()
    const [appVersion, setAppVersion] = useState('1.0-RELEASE')
    const [description, setDescription] = useState()
    const [java_version, setJava_version] = useState('11')

    const [checkactionDone, setCheckactionDone] = useState(false)
    const [process, setProcess] = useState(0)

    const sendPom = {
        springVersion: springVersion,
        group: group,
        artifact: artifact,
        appVersion: appVersion,
        description: description,
        java_version: java_version
    }

    const LocateHandle = (n) => {
        // sendCommand
        setCheckactionDone(true)
        //Go to backend
        //wait for the backend because there is a progress bar that waits

        
        if (n === 1) {
            Commons.findLocate()
        } else if (n === 2) {
            Commons.findinstall()
        } else { //n==3
            Commons.findrun()
        }

        setCheckactionDone(false)
        setProcess(n) // this shows which tick has to be checked


    }

    return (
        <PagesWapper>
            <MainSideBar >
                <Routes>
                    {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                    {/* <Route path="/about" element={<About />} /> */}
                </Routes>
            </MainSideBar>

            <div style={{ overflow: 'hidden', width: '97%', marginLeft: '50px' }}>
                <h2 className='deploy_title mb-3'>
                    Stock Management
                    <span style={{ marginLeft: '30px', color: '#0ce00fe8' }}>Deployment process</span>
                </h2>
                <div className='row commandsRun '>
                    <Tick tickIcon={process > 0} number={1} />
                    <div className='col-9 logs align-content-center'>
                        <DeployShowProgress showmoreload={true} />

                        <ul>
                            <li>Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                            </li>
                            <li>Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                            </li>
                            <li>Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                            </li>
                        </ul>
                    </div>
                    <div className='col-2 border'>
                        <a className='btn btn-info' onClick={() => LocateHandle(1)} href='#'>Locate</a>
                    </div>

                    {/* 2. Second command */}

                    <Tick tickIcon={process > 1} number={2} />
                    <div className='col-9 logs align-content-center'>
                        <DeployShowProgress showmoreload={checkactionDone} />
                        <ul>
                            <li>Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                            </li>
                            <li>Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                            </li>
                            <li>Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                            </li>
                        </ul>
                    </div>
                    <div className='col-2 border'>
                        <a className='btn btn-info' onClick={() => LocateHandle(2)} href='#'>Install</a>
                    </div>


                    {/* 3. Third command */}
                    <Tick tickIcon={process > 2} number={3} />
                    <div className='col-9 logs align-content-center'>
                        <DeployShowProgress showmoreload={checkactionDone} />
                        <ul>
                            <li>Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                            </li>
                            <li>Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                            </li>
                            <li>Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                                Logs are loaded Logs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loadedLogs are loaded loadedLogs are loadedLogs are loaded
                            </li>
                        </ul>
                    </div>
                    <div className='col-2 border'>
                        <a className='btn btn-info' onClick={() => LocateHandle(3)} href='#'>Run</a>
                    </div>


                    <div className='container'>
                        <div className='row m-5'>
                            <a href='#' className='btn btn-warning'>Visit Application</a>
                        </div>
                    </div>
                </div>
            </div>

        </PagesWapper>
    )
}

export default PomConf