
export default class Conn {
    static server = {
        name: '//codeguru-pro.com:',
        guruserviceval: '//guruservices.codeguru-pro.com:',
        // name: 'http://localhost:'
    };
    static port = {
        val: '8091/', //codeguru-pro,
        guruserviceval: '8096/',
        githubread: '8092/',
        runningApps: '8280',  /*guru_master*/
        guru_deploy:'8085'
    }
    static basicPath = {
        val: 'guru/api',
        guruserviceval: 'guruservices/contactus',
        githubread: '/query/api',
        runningApps: '/services',
        guru_deploy: ''
    }
    static wholePath = {
        name: Conn.server.name + Conn.port.val + Conn.basicPath.val,
        guruserviceval: Conn.server.guruserviceval + Conn.port.guruserviceval + Conn.basicPath.guruserviceval, /*  http://localhost:8089/guru/api  */
        githubread: Conn.server.name + Conn.port.githubread + Conn.basicPath.githubread,
        runningApps: Conn.server.name + Conn.port.runningApps + Conn.basicPath.runningApps,
        guru_deploy: Conn.server.name+ Conn.port.guru_deploy+Conn.basicPath.guru_deploy
    }

    static ReqContentType = 'application/json'
    static LoginToken = {
        'Content-Type': Conn.ReqContentType,
        'Authorization': 'Bearer '
    }
    static GetToken = {
        'Content-Type': Conn.ReqContentType,
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
}
