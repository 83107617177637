import React, { useState, useRef, useEffect, useContext } from 'react'
import PagesWapper from '../../Global/PagesWapper'
import { useReactToPrint } from "react-to-print"
import SessionTime from '../../../Services/SessionTime'
import axios from 'axios'
import Commons from '../../../Services/Commons'
import Repository from "../../../Services/Repository"
import AnimateHeight from 'react-animate-height'
import UpdatedComponent from '../../Global/HOCForm'
import { Link, Route, Routes, useParams } from 'react-router-dom';
import PrintCompanyInfo from '../../Global/PrintCompanyInfo'
import Loader, { DataListLoading } from '../../Global/Loader';
import SearchBox from '../../Global/SearchBox'
import { Icon } from 'react-icons-kit'
import ContainerRow, { ClearBtnSaveStatus, ContainerRowBtwb, ContainerRowBtwn, ContainerRowHalf, FormInnerRightPane, FormSidePane, SaveUpdateBtns } from '../../Global/ContainerRow'
import InputRow, { EmptyInputRow, InputRowTupleName, InputRowTupleNameLargerTxt, LargeTextInput, TwoColInput, TwoColPasswordAndInput } from '../../Global/Forms/InputRow'
import FormTools from '../../Global/Forms/PubFnx'
import ListToolBar, { SearchformAnimation } from '../../Global/ListToolBar'

import Utils from '../../Global/Utils'
import Delete from '../../../Services/Delete'
import { MainSideBar } from '../menu/MainSideBar'
import { Language } from '@mui/icons-material'
import PomConf from '../Deploy/PomConf'
import { packageIcon as app } from 'react-icons-kit/oct/packageIcon'


import './MainStructures.css'
import { Alert, Card, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap'
import StructureCards from './StructureCards'

import { shoppingBag as packages_app } from 'react-icons-kit/typicons/shoppingBag'
import FormDetails from '../../Global/FormDetails'
import { OptionPart } from '../../Global/ListTable'
import GuruMaster from '../../Global/GuruMaster/GuruMaster'
import GuruMenu from '../../Global/GuruMainMenu/GuruMenu'
import { ic_assignment_turned_in_outline as autofill } from 'react-icons-kit/md/ic_assignment_turned_in_outline'

function MainStructure({ pubId, handleGetId }) {
  const [id, setId] = useState(null)
  const [showAlertPop, setShowAlertPop] = useState(true);

 


  /*#region ---------- ENTITY FIELDS DECLARATIONS ---------------------------*/
  const [db_name, setDb_name] = useState()
  const [db_user, setDb_user] = useState()
  const [db_password, setDb_password] = useState('123')
  const [cash_total, setCash_total] = useState('300000')
  const [start_time, setStart_time] = useState(SessionTime.getCurrentTime)
  const [delivery_date, setDelivery_date] = useState()
  const [pgm_language, setPgm_language] = useState('JAVA')
  const [platform, setPlatform] = useState('Web')
  const [entry_date, setEntry_date] = useState(SessionTime.getCurrentTime)
  const [user, setUser] = useState('SANGWA')
  /*#endregion Listing data*/

  const [showLoader, setShowLoader] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [structures, setStructures] = useState([]) //Data List
  const [clearBtn, setClearBtn] = useState(false) //The cancel button

  const [dataLoad, setDataLoad] = useState(false)
  const [height, setHeight] = useState(0);
  const [searchHeight, setSearchHeight] = useState(0);


  // PomConf.xml

  const [springVersion, setSpringVersion] = useState('2.7.9-SNAPSHOT')
  const [appgroup, setAppGroup] = useState()
  const [artifact, setArtifact] = useState()
  const [appVersion, setAppVersion] = useState('1.0-RELEASE')
  const [description, setDescription] = useState()
  const [java_version, setJava_version] = useState('11')
  const [port, setPort] = useState('8080')


  //deployment Details
  const [deploy_repo_name, setDeploy_Repo_name] = useState()
  const [deploy_username, setDeploy_Username] = useState()
  const [deploy_token, setDeploy_Token] = useState()
  const [deploy_clone_location_win, setDeploy_clone_location_win] = useState()
  const [deploy_clone_location_linux, setDeploy_clone_location_linux] = useState()
  const [deploy_project_final_name, setDeploy_project_final_name] = useState()
  const [deploy_final_deploy_location_linux, setDeploy_final_deploy_location_linux] = useState()
  const [deploy_final_deploy_location_win, setDeploy_final_deploy_location_win] = useState()
  const [deploy_final_coppy_from_win, setDeploy_final_coppy_from_win] = useState(deploy_clone_location_win)
  const [deploy_coppy_from_linux, setDeploy_coppy_from_linux] = useState(deploy_clone_location_linux)
  const [deploy_files_to_coppy, setDeploy_files_to_coppy] = useState()
  const [deploy_port, setDeploy_port] = useState(port)
  const [deploy_commands_linux, setDeploy_commands_linux] = useState()
  const [deploy_commands_win, setDeploy_commands_win] = useState()
  const [deploy_status, setDeploy_status] = useState('open')
  const [deploy_Language, setDeploy_Language] = useState(pgm_language)


  const [saveStatus, setsaveStatus] = useState(true)
  const [saveStatusMessage, setsaveStatusMessage] = useState('')

  //   String token;
  //modal fullscreen
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const closeHandle = () => {
    setShow(false)
  }
  const scrollToTop = (pageWrapper) => {
    pageWrapper.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Adds smooth scrolling
    });
  }

  /*#region ---------- SAVING DATA TO DB--------------------------------------*/
  const onSubmitHandler = (e) => {
    e.preventDefault()
    if (db_name === '' || db_user === '' || appgroup === '' || artifact === '' || port === '' || description === '') {
      alert('You have to fill the all the fields')

    } else {
      setShowLoader(true)

      var Structure = {
        id: id, db_name: db_name, db_user: db_user, db_password: db_password, cash_total: cash_total, start_time: start_time, delivery_date: delivery_date, pgm_language: pgm_language, platform: platform, entry_date: entry_date, user: user,
        /*pom.xml*/
        springVersion: springVersion, appgroup: appgroup, artifact: artifact, appVersion: appVersion, description: description, java_version: java_version, port: port,
        /*Deployment*/
        repo_name: deploy_repo_name,
        username: deploy_username,
        token: deploy_token,
        clone_location_win: deploy_clone_location_win,
        clone_location_linux: deploy_clone_location_linux,
        project_final_name: deploy_project_final_name,
        final_deploy_location_linux: deploy_final_deploy_location_linux,
        final_deploy_location_win: deploy_final_deploy_location_win,
        coppy_from_win: deploy_clone_location_win,
        coppy_from_linux: deploy_clone_location_linux,
        files_to_coppy: deploy_files_to_coppy,
        commands_linux: deploy_commands_linux,
        commands_win: deploy_commands_win,

      }
      try {
        if (id) {
          Commons.updateStructure(Structure, id).then((res) => {
            resetAfterSave()
          })
        } else {
          
          Commons.saveStructure(Structure).then((res) => {
            console.log(res.data)
              resetAfterSave()
              setsaveStatus(true)
          }).catch((err) => {
            setsaveStatus(false)
            const pageWrapper = document.querySelector('.pageWrapper');
            setShowLoader(false)
            scrollToTop(pageWrapper)
            /* #region  Error catching */
            if (err.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              setsaveStatusMessage(Utils.errorLogic(err))
            } else if (err.request) {
              // The request was made but no response was received
              setsaveStatusMessage('No response back')
            }
            else {
              // Something happened in setting up the request that triggered an Error
             setsaveStatusMessage('Something happened in setting up the request that triggered an Error')
            }
            /* #endregion */
          })
        }
      } catch (err) {
        alert('An error occured')
      }
    }
  }
  /*#endregion Listing data*/

  /*#region ------------All Records, Deleting and By Id------------------------*/
  const getAllStructures = () => {
    Repository.findStrucuture().then((res) => {
      setStructures(res.data);
      setDataLoad(true)
    });
  }

  useEffect(() => {
    Utils.PageLoadeDefaults()
    getAllStructures()
    document.body.classList.add('darkBody')

  }, []);


  const getStrucById = (id) => {
    window.scrollTo(0, 0)

    Repository.findStrucById(id).then((res) => {

      setId(res.data.id)
      setDb_name(res.data.db_name)
      setDb_user(res.data.db_user)
      setDb_password(res.data.db_password)
      setCash_total(res.data.Cash_total)
      setStart_time(res.data.start_time)
      setDelivery_date(res.data.delivery_date)
      setPgm_language(res.data.pgm_language)
      setPlatform(res.data.platform)
      setEntry_date(res.data.entry_date)
      setUser(res.data.user)
      // PomConf.xml
      setSpringVersion(res.data.springVersion)
      setAppGroup(res.data.appgroup)
      setArtifact(res.data.artifact)
      setAppVersion(res.data.appVersion)
      setDescription(res.data.description)
      setJava_version(res.data.java_version)
      setPort(res.data.port)

      //deploy settings
      setDeploy_Repo_name(res.data.repo_name)
      setDeploy_clone_location_linux(res.data.clone_location_linux)
      setDeploy_clone_location_win(res.data.clone_location_win)
      setDeploy_project_final_name(res.data.project_final_name)
      setDeploy_final_deploy_location_linux(res.data.final_deploy_location_linux)
      setDeploy_final_deploy_location_win(res.data.final_deploy_location_win)
      setDeploy_commands_linux(res.data.commands_linux)
      setDeploy_commands_win(res.data.commands_win)

      setClearBtn(true)
      setHeight(height === 0 ? 'auto' : 0)
      showheight('auto')

    })
  }
  const delStructure = (id) => {
    Utils.Submit(() => {
      Delete.delStructureyId(id, () => { getAllStructures() })
    }, () => { })
  }
  /*#endregion Listing data*/

  /*#region ---------Show Height, reset all and clear Button   ------------*/
  function showheight(type) {
    setHeight(type)
  }
  const resetAfterSave = () => {
    document.getElementById("Form").reset();
    getAllStructures()
    setShowLoader(false)
    setShowAlert(true)
    setHeight(0)
    setDb_name("")
    setDescription('')
    setId(null)

    //deploy clear
    setDeploy_Repo_name('')
    setDeploy_Token('')
    setDeploy_Language('')
    setDeploy_Username('')
    setDeploy_clone_location_linux('')
    setDeploy_clone_location_win('')
    setDeploy_commands_linux('')
    setDeploy_commands_win('')
    setDeploy_coppy_from_linux('')
    setDeploy_files_to_coppy('')
    setDeploy_project_final_name('')
    setDeploy_final_coppy_from_win('')
    setDeploy_final_deploy_location_linux('')
    setDeploy_final_deploy_location_win('')
    setDeploy_port('')
  }
  const clearHandle = () => {
    setDb_name('')
    setDescription('')
    setId(null)
    setClearBtn(false)
    setHeight(0)
  }
  /*#endregion Listing data*/


  /*#region Printing */
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'emp-data'
  });
  /*#endregion Listing data*/


  const mrgin = '20px'
  const icon_one_style = {
    color: '#00b5fd',
    marginBottom: mrgin
  }
  const cardTitle = {
    margin: '0px',
    color: '#000',
    fontSize: '18px',
    // textShadow: '1px 1px '

  }

  // Initiate a structure to display in the modal

  const [initDbname, setInitDbName] = useState()
  const [initSpringVersion, setinitSpringVersion] = useState()
  const [initAppgroup, setinitAppgroup] = useState()
  const [initArtifact, setinitArtifact] = useState()
  const [initAppVersion, setinitAppVersion] = useState()
  const [initDescription, setinitDescription] = useState()
  const [initJava_version, setinitJava_version] = useState()

  const [initCashTotal, setinitCashTotal] = useState()
  const [initStartTime, setinitStartTime] = useState()
  const [initDeliveryDate, setinitDeliveryDate] = useState()
  const [initDbPassword, setinitDbPassword] = useState()
  const [initPgmLanguage, setinitPgmLanguage] = useState()
  const [initEntryDate, setinitEntryDate] = useState()


  const initiateStructure = (e, db_name, springVersion, appgroup, artifact, appVersion, description, java_version,
    cash_total, start_time, delivery_date, DBPassword, pgm_language, entry_date
  ) => {
    e.preventDefault()
    setInitDbName(db_name)
    setinitSpringVersion(springVersion)
    setinitAppgroup(appgroup)
    setinitArtifact(artifact)
    setinitAppVersion(appVersion)
    setinitDescription(description)
    setinitJava_version(java_version)

    setinitCashTotal(cash_total)
    setinitStartTime(start_time)
    setinitDeliveryDate(delivery_date)
    setinitDbPassword(db_password)
    setinitPgmLanguage(pgm_language)
    setinitEntryDate(entry_date)
    setShow(true)
  }

  return (
    <PagesWapper>
      <MainSideBar>
        <Routes>
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/about" element={<About />} /> */}
        </Routes>
      </MainSideBar>

      <GuruMenu />

      {/* the below is the modal */}
      <FormDetails switch={show} title={initDbname} closeHandle={() => closeHandle()} >
        <Container fluid className='formDetails'>

          <Row>
            <Col>
              <p>
                <h1>Database</h1>
                <h3> {initDbname} </h3>
              </p>
              <p>
                <h1>Spring Version</h1>
                <h3> {initSpringVersion} </h3>
              </p>
              <p>
                <h1>Group</h1>
                <h3> {initAppgroup} </h3>
              </p>
              <p>
                <h1>Artifact</h1>
                <h3> {initArtifact} </h3>
              </p>
            </Col>

            <Col>

              <p>
                <h1>Application Version</h1>
                <h3> {initAppVersion} </h3>
              </p>
              <p>
                <h1>Java Version</h1>
                <h3> {initJava_version} </h3>
              </p>
              <p>
                <h1>Cash</h1>
                <h3> {initCashTotal}</h3>
              </p>
              <p>
                <h1>Start Time</h1>
                <h3> {initStartTime} </h3>
              </p>
            </Col>

            <Col>

              <p>
                <h1> Delivery Date </h1>
                <h3> {initDeliveryDate} </h3>
              </p>
              <p>
                <h1>Database Password</h1>
                <h3> {initDbPassword} </h3>
              </p>
              <p>
                <h1>programming Language</h1>
                <h3> {initPgmLanguage}</h3>
              </p>
              <p>
                <h1>Entry Date</h1>
                <h3> {initEntryDate}</h3>
              </p>

            </Col>

            <Col className='formDetailsWrapper'>
              <p>
                <h1>  About the application </h1>
                <h3 className='notes'> {initDescription}</h3>
              </p>
            </Col>
          </Row>





        </Container>
      </FormDetails>

      <AnimateHeight id="animForm" duration={300} animateOpacity={true} height={height}>
        <ContainerRowBtwn clearBtn={clearBtn} form='Structure' showLoader={showLoader}  >
          {!saveStatus && <Container>
            <Row className='d-flex justify-content-center'>
              <Col md={11}>
                <Alert className="alert alert-danger p-3 my-5" onClose={() => setsaveStatus(true)} dismissible>{saveStatusMessage} </Alert>
              </Col>
            </Row>
          </Container>
          }

          <ClearBtnSaveStatus height={height} showLoader={showLoader} showAlert={showAlert} />
          <FormInnerRightPane onSubmitHandler={onSubmitHandler}>
            <TwoColInput label1='dbname' name1='App Name' handle1={(e) => setDb_name(e.target.value)} val1={db_name} name2='DB User' handle2={(e) => setDb_user(e.target.value)} label2='DBUser' val2={db_user} />
            <TwoColInput label1='price' name1='Price' handle1={(e) => setCash_total(e.target.value)} val1={cash_total} name2='Start Time' handle2={(e) => setStart_time(e.target.value)} label2='starttime' val2={start_time} />
            <TwoColInput label1='DeliveDate' name1='Delivery Date' handle1={(e) => setDelivery_date(e.target.value)} val1={delivery_date} name2='Language' handle2={(e) => setPgm_language(e.target.value)} label2='Language' val2={pgm_language} />
            <TwoColInput label1='Platform' name1='Platform' handle1={(e) => setPlatform(e.target.value)} val1={platform} name2='Entry Date' handle2={(e) => setEntry_date(e.target.value)} label2='entrydate' val2={entry_date} />
            <TwoColPasswordAndInput label1='DBPassword' name1='DB Password' handle1={(e) => setDb_password(e.target.value)} val1={db_password} name2='User' handle2={(e) => setUser(e.target.value)} label2='user' val2={user} />
            <br /> <h6 className='fw-bold'>Advanced settings </h6>
            <TwoColInput label1='Spring Version' name1='Spring version' handle1={(e) => setSpringVersion(e.target.value)} handle2={(e) => setAppVersion(e.target.value)} label2='appv' name2='App Version' val1={springVersion} val2={appVersion} />
            <TwoColInput label1='Group' name1='Group' label2='artifact' name2='Artifact' val1={appgroup} handle1={(e) => setAppGroup(e.target.value)} handle2={(e) => setArtifact(e.target.value)} title='This will be te db name in the pom.xml and the previx in endpoints' val2={artifact} />
            <TwoColInput label1='port' name1='Port' label2='jversion' name2='Java version' val1={port} handle1={(e) => setPort(e.target.value)} handle2={(e) => setJava_version(e.target.value)} val2={java_version} />
            {/*Deploy fields*/}
            <br /> <h6 className='fw-bold text-uppercase my-5'> Deployment Process</h6>
            <TwoColInput label1='deploy_repo_name' name1='Repository name' label2='deploy_username' name2='Repository Username' val1={deploy_repo_name} handle1={(e) => setDeploy_Repo_name(e.target.value)} handle2={(e) => setDeploy_Username(e.target.value)} val2={deploy_username} />
            <TwoColInput label1='deploy_token' name1='Github Token' label2='deploy_clone_location_win' name2='Clone Location windows' val1={deploy_token} handle1={(e) => setDeploy_Token(e.target.value)} handle2={(e) => setDeploy_clone_location_win(e.target.value)} val2={deploy_clone_location_win} placeholder2='C:\\Users\\HP\\\\Documents\\test\\clonepath\\' />
            <TwoColInput label1='deploy_clone_location_linux' name1='Clone Location Linux' label2='Deploy Project Final Name' name2='Project Final_name' val1={deploy_clone_location_linux} placeholder1='/home/iradukunda/tmp/' handle1={(e) => setDeploy_clone_location_linux(e.target.value)} handle2={(e) => setDeploy_project_final_name(e.target.value)} val2={deploy_project_final_name} placeholder2='final_name' />
            <TwoColInput label1='deploy_final_deploy_location_linux' label2='deploy_final_deploy_location_win' name1='Deploy Location Linux' name2='Deploy Location Win' val1={deploy_final_deploy_location_linux} placeholder1='/opt/app/' handle1={(e) => setDeploy_final_deploy_location_linux(e.target.value)} handle2={(e) => setDeploy_final_deploy_location_win(e.target.value)} val2={deploy_final_deploy_location_win} placeholder2='C:\\Users\\\\HP\\' />
            <TwoColInput label1='deploy_commands_linux' name1='Commands Linux' label2='deploy_commands_win' name2='Commands Windows' val1={deploy_commands_linux} placeholder1='mvn clean install' handle1={(e) => setDeploy_commands_linux(e.target.value)} handle2={(e) => setDeploy_commands_win(e.target.value)} val2={deploy_commands_win} placeholder2='mvn.cmd clean install' />
            <InputRowTupleNameLargerTxt name='Files to Copy' label='deploy_files_to_coppy' val={deploy_files_to_coppy} handle={(e) => setDeploy_files_to_coppy(e.target.value)} placeholder='pom.xml,src/main/resources/' />
            <LargeTextInput name='Description' val={description} handle={(e) => setDescription(e.target.value)} label='desc' />
            <SaveUpdateBtns clearBtn={clearBtn} clearHandle={clearHandle} saveOrUpdate={FormTools.BtnTxt(clearBtn)} />

          </FormInnerRightPane>

        </ContainerRowBtwn>
      </AnimateHeight>
      <ContainerRow className="noBoldedHeaders" mt='3'>
        <ListToolBar listTitle='Structure List' height={height} entity='Structure' changeFormHeightClick={() => setHeight(height === 0 ? 'auto' : 0)} changeSearchheight={() => setSearchHeight(searchHeight === 0 ? 'auto' : 0)} handlePrint={handlePrint} searchHeight={searchHeight} />
        <SearchformAnimation searchHeight={searchHeight}>
          <SearchBox />
        </SearchformAnimation>

        <div ref={componentRef} className="dataTableBox">
          <PrintCompanyInfo />


          <Row>

            {structures.map((struc) => (
              <Col>
                <Card className='centeredcard cards d-block mx-auto ' style={{ width: '16rem' }} >
                  <Card.Body >

                    <Row className="d-flex justify-content-center">
                      <Col md={12}>
                        <a href='#' onClick={(e) => initiateStructure(e, struc.db_name, struc.springVersion, struc.appgroup,
                          struc.artifact, struc.appVersion, struc.description, struc.java_version,
                          struc.cash_total, struc.start_time, struc.delivery_date, struc.db_password, struc.pgm_language, struc.platform, struc.entry_date,
                          struc.user
                        )} className='mx-auto d-block m-auto' >
                          <Icon className='bpmx-auto d-block ball' size={Utils.cardSize()} style={{ color: 'rgb(15, 179, 230)', marginRight: "10px" }} icon={app} />
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card.Title className='devCarTitle' style={cardTitle}>
                          <h3 title={struc.db_name} className='fw-bold mt-5 heading OneLine noBoldedHeaders'>
                            {struc.db_name} </h3>
                        </Card.Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card.Text className='desc fewLines'>
                          {struc.description}
                        </Card.Text>
                        <OptionPart getEntityById={() => getStrucById(struc.id)} delEntityById={() => delStructure(struc.id)} />
                      </Col>
                    </Row>

                    {/* <Card.Link className='btn generalButtons' href="#">Update</Card.Link> */}
                  </Card.Body>
                </Card>
              </Col>

            ))}
          </Row>

        </div>


      </ContainerRow>
      {!dataLoad && <DataListLoading />
      }

    </PagesWapper>


  )
}

export default UpdatedComponent(MainStructure)