import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
//  import aut1_rem from '../../images/home/aut1_rem.png'
import aut1_rem from '../imgz/home/aut1_rem.png'
import { Slide, Zoom } from 'react-reveal'
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();

    const gotoServices = () => {
        navigate('/services');
        
    }


    return (
        <Container fluid className='homeHeader boldedHeader ' >

            <Row className="text-white h-100   " >
                <Col lg={6} sm={12} md={12} className='d-lg-block d-none'>
                    <Zoom top duration="5000">
                        <img src={aut1_rem} className='d-none d-lg-block' />
                    </Zoom>
                </Col>
                <Col lg={6} sm={12} style={{position:'relative'}}>
                    <Row className='automationTitle  gap-4 d-flex align-content-center h-100 justify-content-center  '>

                        <h1>WHERE   TECHNOLOGY <span style={{ color: "orange" }}>&</span> BUSINESS OWNERS MEET </h1>
                        <h3>MEET FUTURE TECHNOLOGY</h3>
                        <p className="small_desc">Empower your business and development team with our IT tool that streamlines processes and boosts productivity effortlessly!
                        </p>
                        <p className='d-flex justify-content-start mt-5 buttonswrapper'  >
                            <Button variant="outline-light mx-2" onClick={() => console.log("Primary")}>
                                Developers
                            </Button>
                            <Button variant="outline-light mx-5" onClick={() => gotoServices()}>
                                Business
                            </Button>
                        </p>

                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Header